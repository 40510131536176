import axios from 'axios'
import Carousel from 'nuka-carousel'
import React, { Fragment } from 'react'
import L from 'leaflet'
import Moment from 'react-moment'

const { REACT_APP_TYPE } = process.env

/**
 * Add content into Timetable
 * @param component
 * @returns {Array}
 */
export const addTimetableContent = (component, print) => {
  const { timetableData } = component.state
  const currentLine = component.state.currentLine

  if (timetableDataIsEmpty(timetableData)) { // then returns a simple message instead of timetable
    const slides = []
    slides.push(<div>Pas de passage à l'arret {component.state.timetableStop} le <span className=''><Moment
      format='dddd DD MMMM YYYY'>{component.state.url.date}</Moment></span></div>)
    return slides
  }

  const possibleperiods = [
    'morning',
    'afternoon',
    'evening'
  ]

  let periods = [
    'morning',
    'afternoon',
    'evening'
  ]

  let hours = {
    morning: [
      4, 5, 6, 7, 8, 9, 10, 11
    ],
    afternoon: [
      12, 13, 14, 15, 16, 17, 18, 19
    ],
    evening: [
      20, 21, 22, 23, 0, 1, 2, 3
    ]
  }

  // Roadmap starts at 4am and ends at 3am        used to compare : 0am=24 1am=25 2am=26 3am=27
  let start = 99
  let end = 0

  const nightline = isNightLine(timetableData)
  for (const period of periods) {
    for (let time of timetableData[period]) {
      let timeHour = parseInt(time.stop_date_time.departure_date_time.substring(9, 11))

      if (timeHour < 4 && !nightline) { // For exemple, a nightline can work from 1am to 4am. The start must be 1am for this line.
        timeHour += 24
      }

      if (timeHour < start) {
        start = timeHour
      }

      if (timeHour > end) {
        end = timeHour
      }
    }
  }

  const slides = []
  if (nightline) { // redifines hours (hours will be something like [1,2,3,4])
    periods = ['morning']
    hours = {
      morning: []
    }

    for (let i = start; i <= end; i++) {
      if (i > 23) {
        hours.morning.push(i - 24)
      } else {
        hours.morning.push(i)
      }
    }
  }

  let otherdirections = [] // array of secondary destinations name
  for (const period of periods) {
    const table = []
    const tableHead = []

    for (const hour of hours[period]) {
      const colonne = []
      const newhour = (hour < 4 && !nightline) ? hour + 24 : hour
      let printHidden = false // bool : column needs to be hidden in print ?

      if (newhour < start || newhour > end) {
        printHidden = true
      }

      let timetableNameColumnHour = 'timetableColumn' + (printHidden ? ' printHide' : '')
      if (component.props.map.props.isMobile) {
        timetableNameColumnHour += ' mobile'
      }

      tableHead.push(<div key={hour} className={timetableNameColumnHour}><div className='timetableHour'> {hour}h</div></div>)
      if (nightline) { // needs to check for all periods (a nightline can have both morning and evening passages on the same slide/period)
        for (const possibleperiod of possibleperiods) {
          if (timetableData[possibleperiod]) {
            for (let timetableDataContent of timetableData[possibleperiod]) {
              processtimetableDataContent(timetableDataContent, hour, currentLine, otherdirections, colonne) // push hour into column, update otherdirections
            }
          }
        }
      } else {
        for (let timetableDataContent of timetableData[period]) {
          processtimetableDataContent(timetableDataContent, hour, currentLine, otherdirections, colonne)
        }
      }

      let timetableNameColumn = 'timetableColumn' + (printHidden ? ' printHide' : '')
      table.push(<div className={timetableNameColumn} key={'colonne' + hour}>{colonne}</div>)
    }

    const otherDirections = getDestinations(component.state.timetableData, component.state.currentLine.routes[currentLine.direction_id].name, true)
    slides.push(<div key={period} className='timetableSlide'>
      <div className={print ? 'timetableBody timetableBodyHours' : 'timetableBody timetableBodyHours timetableBodyHoursNoPrint'}>{tableHead}</div>
      <div className={print ? '' : 'scroll'}>
        <div className='timetableBody timetableBodyMinutes'>{table}</div>
        <div className={otherDirections ? 'otherDirections' : ''}>{otherDirections}</div>
      </div>
    </div>)
  }

  return slides
}

/**
 * Add resize event and return a function to remove it
 * @returns {function(): void}
 */
export const addResizeEvent = isMobile => {
  const resizeListener = () => {
    resize(isMobile)
  }

  window.addEventListener('resize', resizeListener)
  return () => window.removeEventListener('resize', resizeListener)
}

export const checkCoords = async (url, which, component) => {
  const newState = { error: '', warning: '' }
  let around = null

  switch (true) {
    case url.includes('stop_area'):
      const stop = await axios.get('/api/search?name=areas&value=' + url)
      newState[which + 'Place'] = stop.data
      component.setState(newState)
      if (component.props.url.pathname.includes('around')) {
        around = createCoords(stop.data.coord.lat, stop.data.coord.lon)
      } else {
        component.createMarker(createCoords(stop.data.coord.lat, stop.data.coord.lon), which)
      }
      if (around) {
        return around
      }
      break
    case url.includes('poi:osm'):
      const place = await axios.get('/api/search?name=places&value=' + url)
      newState[which + 'Place'] = place.data
      component.setState(newState)
      if (component.props.url.pathname.includes('around')) {
        around = createCoords(place.data.coord.lat, place.data.coord.lon)
      } else {
        component.createMarker(createCoords(place.data.coord.lat, place.data.coord.lon), which)
      }
      if (around) {
        return around
      }
      break
    default:
      url = url.replace(',', ';') // When the users comes from a POI, coords comes with ','
      if (isCoords(url)) {
        newState[which + 'Place'] = null
        component.setState(newState)
        if (component.props.url.pathname.includes('around')) {
          around = createCoords(url.split(';')[1], url.split(';')[0])
        } else {
          component.createMarker(createCoords(url.split(';')[1], url.split(';')[0]), which)
        }
        if (around) {
          return around
        }
      }
      break
  }
}

/**
 * Create a google coord
 * @param lat
 * @param lng
 */
export const createCoords = (lat, lng) => {
  return [lat, lng]
}

/**
 * Create a timetable
 * @param component
 * @returns {*}
 */
export const createTimetable = (component, print) => {
  const { slideIndex } = component.state
  let multipleSlides = !(isNightLine(component.state.timetableData) || timetableDataIsEmpty(component.state.timetableData))

  // Ugly slider initial height fix
  setTimeout(() => {
    const list = document.querySelector('.slider-list')
    const currentSlide = list && list.children[slideIndex]

    if (currentSlide) {
      list.style.height = currentSlide.offsetHeight + 'px'
    }
  })

  return <Fragment>
    {multipleSlides && (slideIndex === 1 ? <div className='timetableHead' style={{ justifyContent: 'space-between' }}>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex - 1 })}>&lt; 04h - 11h</span>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex + 1 })}>20h - 03h &gt;</span>
    </div> : slideIndex === 0 ? <div className='timetableHead' style={{ justifyContent: 'flex-end' }}>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex + 1 })}>12h - 03h &gt;</span>
    </div> : <div className='timetableHead' style={{ justifyContent: 'flex-start' }}>
      <span onClick={() => (!component.state.changingSlide) && component.setState({ changingSlide: true, slideIndex: slideIndex - 1 })}>&lt; 04h - 19h</span>
    </div>)}
    <Carousel
      slideIndex={slideIndex}
      heightMode={'current'}
      afterSlide={(slideIndex) => {
        component.setState({ changingSlide: false, slideIndex }, () => { resize(component.props.map.props.isMobile, Array.from(document.querySelectorAll('.scroll'))[slideIndex]) })
      }}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}>
      {addTimetableContent(component, print)}
    </Carousel>
  </Fragment>
}

/**
 * Convert date from calendar and time from inputs to navita format
 * @param date
 * @param hour
 * @param minutes
 * @returns {string|*}
 */
export const dateToNavita = (date, hour, minutes) => {
  return date.format('YYYYMMDD') + 'T' + hour + minutes
}

/**
 * Return an address from coord
 * @param position
 * @returns {Promise<*>}
 */
export const geocodPlace = async position => {
  let result = null
  await axios.get('/api/geocoding', {
    params: {
      lat: position[0],
      lng: position[1]
    }
  }).then(response => {
    result = response.data.shift()
  }).catch(e => {
    result = e.response.data
  })
  return result
}

/**
 *
 * @returns {Promise<string|coords|{lat, lon}|Coordinates|string>}
 */
export const geolocInput = async () => {
  try {
    const position = await getCurrentPosition({
      timeout: 3000,
      enableHighAccuracy: true
    })

    const { longitude, latitude } = position.coords
    return substringCoords({
      lon: longitude,
      lat: latitude
    })
  } catch (e) {
    throw e
  }
}

/**
 * Return lines in a selected town
 * @param town
 * @returns {Promise<void>}
 */
export const getLinesInTown = async (component, town) => {
  const response = await axios.get('/api/data-in-town?insee=' + town.insee + (REACT_APP_TYPE === 'tcl' ? '&navitia=true' : ''))
  const lines = response.data.shift()
  const groups = groupLinesByMode(unique(lines, 'id'), 'mode') // Make the array unique by the lines ID and order
  // them by group
  const openedGroups = Object.keys(groups).map((group, index) => ({
    group,
    opened: index === 0
  }))

  const places = response.data.shift()
  const openedPlacesGroups = Object.keys(places).map(place => ({
    place,
    opened: false
  }))

  component.setState({
    town,
    groups,
    openedGroups,
    places,
    openedPlacesGroups,
    inputAroundValue: town.name
  })
}

// returns closer stopId of a point from a StopsList
export const getCloserStopIdFromStopsList = (pin, stopsList, place = false) => {
  let currentStop = null

  for (const stop of stopsList) {
    stop.distance = getDistanceBetweenMarkers((place ? new L.LatLng(pin.lng, pin.lat) : pin), new L.LatLng(stop.coord.lat, stop.coord.lon))

    if (!currentStop || stop.distance < currentStop.distance) {
      currentStop = stop
    }
  }

  return currentStop.id
}

/**
 * Return position geolocated
 * @param options
 * @returns {Promise<any>}
 */
export const getCurrentPosition = (options = {}) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options)
  })
}

export const getDestinations = (timetableData, currentLineName, html) => { // returns secondary destinations from Data     if html = true, returns html
  let otherdirections = []
  let htmlarray = []
  let first = true

  if (timetableData) { // Check if morning, afternoon and evening are all empty
    for (const period in timetableData) {
      if (timetableData.hasOwnProperty(period) && timetableData[period]) {
        for (let timetableDataContent of timetableData[period]) {
          const direction = timetableDataContent.display_informations.direction
          if (direction.split('(')[0].trim().toLowerCase() !== currentLineName.split('(')[0].trim().toLowerCase()) { // If it is an other direction than the main one (without town name)
            if (otherdirections.indexOf(direction) === -1) {
              otherdirections.push(direction) // if this direction is not in otherdirections, add it
              first && htmlarray.push(<div key={direction}>Destination secondaire de la ligne : </div>)
              if (!first) {
                htmlarray[0] = <div className={'otherdirectionsContent'}>Destinations secondaires de la ligne : </div>
              }
              first = false
              htmlarray.push(<div key={direction + '_' + (Object.keys(otherdirections).length + 96)} className={'otherdirectionsContent'}><span
                className='otherdirectionsContentLetter'>{String.fromCharCode(
                  Object.keys(otherdirections).length + 96)}</span> {' : ' + direction} </div>)
            }
          }
        }
      }
    }
  }

  if (html) {
    return htmlarray.length === 0 ? null : htmlarray
  }

  return otherdirections
}

/**
 * Calcul distance between two points
 * @returns {number}
 * @param one
 * @param two
 */
export const getDistanceBetweenMarkers = (one, two) => {
  const R = 6371
  const distLat = ((one.lat - two.lat) * Math.PI) / 180
  const distLon = ((one.lng - two.lng) * Math.PI) / 180
  const a =
    Math.sin(distLat / 2) ** 2 +
    Math.cos((two.lat * Math.PI) / 180) *
    Math.cos((one.lat * Math.PI) / 180) *
    Math.sin(distLon / 2) *
    Math.sin(distLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return Math.round(R * c * 1000)
}

/**
 * Return the correct string for a given group name
 * @param group
 * @returns {string}
 */
export const getModeNameByGroup = group => {
  switch (group.toLowerCase()) {
    case 'rer':
      return 'Lignes de RER'
    case 'bus':
      return 'Lignes de bus'
    case 'tad':
      return 'Le transport à la demande'
    case 'métro':
      return 'Lignes de métro, funiculaire et tramway'
    case 'ligne majeure':
      return 'Lignes de bus au service renforcé'
    case 'junior direct':
      return 'Junior Direct'
    case 'pleine lune':
      return 'Lignes pleine lune'
    default:
      return ''
  }
}

/**
 * Group lines by mode, then sort them by property
 * @param lines
 * @param property
 */
export const groupLinesByMode = (lines, property) => {
  const groups = {}

  // Push each line on it's own group
  for (const line of lines) {
    (groups[line.mode] = groups[line.mode] || []).push(line)
  }

  // Sort all line in each group
  for (const group of Object.keys(groups)) {
    sortBy(groups[group], property)
  }

  if (REACT_APP_TYPE === 'tcl') {
    const main = groups['métro']

    for (const type of ['funiculaire', 'tramway']) {
      groups[type] && main.push(...groups[type])
      delete groups[type]
    }

    // JD
    delete groups['junior direct']
  }

  return groups
}

/**
 * Return if value is real coordinates
 * @param coord
 * @returns {boolean}
 */
export const isCoords = coord => {
  const lon = coord.split(';')[0]
  const lat = coord.split(';')[1]

  return !!(!isNaN(lon) && isBetween(lon, -180, 180) && !isNaN(lat) && isBetween(lon, -90, 90))
}

/**
 * Lighten / darken a color by a percentage
 * @param color
 * @param percent
 * @returns {string}
 export const lighten = (color, percent) => {
  if (color.length < 6) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2]
  }

  const num = parseInt(color, 16)
  const amt = Math.round(2.55 * percent)
  const R = (num >> 16) + amt
  const B = ((num >> 8) & 0x00ff) + amt
  const G = (num & 0x0000ff) + amt

  return (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255)).toString(16).slice(1)
} */

/**
 * returns hour:min from Navitia format
 * @param dateFormated
 * @returns {string}
 */
export const navitiaToHourMin = dateFormated => {
  const hourMin = dateFormated.split('T').pop()
  return hourMin.substring(0, 2) + ':' + hourMin.substring(2, 4)
}

/**
 * Pluralize string
 * @param string
 * @returns {string}
 */
export const pluralize = string => string + 's'

export const processtimetableDataContent = (timetableDataContent, hour, currentLine, otherdirections, colonne) => {
  const time = timetableDataContent.stop_date_time.departure_date_time
  const timeHour = time.substring(9, 11)
  let timeMinutes = time.substring(11, 13)
  let timetablesMinutes = [<span key={timeMinutes} className='timetableMinutesWithoutLetter'>{timeMinutes}</span>]
  if (parseInt(timeHour) === hour) {
    const direction = timetableDataContent.display_informations.direction
    if (direction.split('(')[0].trim().toLowerCase() !== currentLine.routes[currentLine.direction_id].name.split('(')[0].trim().toLowerCase()) { // If it is an other direction than the main one (without town name)
      let index = otherdirections.indexOf(direction)
      if (index === -1) { // first appearence of this direction : adding it to the tab
        index = Object.keys(otherdirections).length
        otherdirections[index] = direction
      } else {
        index = otherdirections.indexOf(direction)
      }
      timetablesMinutes.push(<span key={timeMinutes + '_' + String.fromCharCode(index + 97)} className='timetableMinutesLetter'>{' ' +
      String.fromCharCode(index + 97)}</span>)
    }
    colonne.push(<span key={time} className='timetableMinutes'>{timetablesMinutes}</span>)
  }
}

/**
 * Resize the fckin panel
 */
export const resize = (isMobile, div) => {
  let height = (window.innerHeight - 40) / (isMobile ? 1.15 : 1)
  const toBeResized = div || document.querySelector('.scroll')

  if (!toBeResized) {
    return
  }

  // Divs height to remove
  const header = document.querySelector('.header')
  const lines = document.querySelectorAll('.leftLines, .currentLine .leftLine')
  const routeCalculation = document.querySelector('.goToRoute')
  const stop = document.querySelector('.timetableStop')
  const error = document.querySelector('.error')
  const form = document.querySelector('.form')
  const roadmap = document.querySelector('.roadmap')
  const stops = document.querySelector('.stops')
  const tab = document.querySelector('.tab')
  const groupSelected = document.querySelector('.group.selected')
  const head = document.querySelector('.routeHead')
  const place = document.querySelector('[class*=laceSelected]') // NOT A TYPO : placeSelected OR hikingPlaceSelected
  const print = document.querySelector('.printLink')
  const calendar = document.querySelector('.click-outside')
  const timetableButtons = document.querySelector('.timetableHead')
  const timetableHour = document.querySelector('.timetableBodyHoursNoPrint')

  // For each div, substract it's height from the window's one
  for (const div of [header, ...lines, routeCalculation, stop, error, form, roadmap, stops, tab, groupSelected, head, place, print, calendar, timetableButtons, timetableHour]) {
    if (!div) {
      continue
    }

    const path = window.location.pathname

    if (REACT_APP_TYPE === 'tcl') {
      if (path.includes('route-calculation') && div.classList.contains('form')) {
        height += 15
        continue
      } else if (div.classList.contains('roadmap')) {
        height -= 55
        continue
      } else if (div.classList.contains('stops')) {
        height -= 35
        continue
      }
    } else {
      if (path.includes('route-calculation') && div.classList.contains('form')) {
        height += 10
        continue
      } else if (div.classList.contains('roadmap') || div.classList.contains('stops')) {
        height -= 20
        continue
      }
    }

    height -= div.offsetHeight
  }

  height += (isMobile && timetableHour) && 40

  toBeResized.style.maxHeight = height + 'px'
}

/**
 * Sort an array by a property
 * @param array
 * @param property
 * @returns Array
 */
export const sortBy = (array, property) => array.sort(
  (a, b) => +(a[property] > b[property]) || +(a[property] === b[property]) - 1)

/**
 * Sort an array by a porperty and alphabetic order
 * @param array
 * @param property
 */
export const sortAlphabetic = (array, property) => array.sort((a, b) => a[property].localeCompare(b[property]))

/**
 * Return coord with personalize length for url
 * @param latlng
 * @returns {string}
 */
export const substringCoords = (latlng) => {
  if (!(latlng instanceof L.LatLng)) {
    latlng = new L.LatLng(latlng.lat, latlng.lon)
  }

  return Number(latlng.lng).toFixed(4) + ';' + Number(latlng.lat).toFixed(4)
}

export const isNightLine = (timetableData) => {
  let periods = [
    'morning',
    'afternoon',
    'evening'
  ]
  for (const period of periods) {
    for (let time of timetableData[period]) {
      let timeHour = parseInt(time.stop_date_time.departure_date_time.substring(9, 11))
      if (timeHour > 5 && timeHour < 23) { // a nightline can only work from 11pm to 5am
        return false
      }
    }
  }
  return true
}

export const timetableDataIsEmpty = (timetableData) => {
  let timetableEmpty = true
  if (timetableData) { // Check if morning, afternoon and evening are all empty
    for (const period in timetableData) {
      if (timetableData.hasOwnProperty(period) && timetableData[period]) {
        if (timetableData[period].length > 0) {
          timetableEmpty = false
        }
      }
    }
  }
  return timetableEmpty
}

/**
 * Remove duplicates entries of an Array by a specifiq property
 * @param array
 * @param property
 * @returns Array
 */
export const unique = (array, property) => array.filter(
  (e, i) => array.findIndex(a => a[property] === e[property]) === i)

/**
 * Create an object from the url
 * @param url
 */
export const updateURLState = url => {
  const dataUrl = {}

  for (let data of url.search.substr(1).split('&')) {
    const value = data.split('=')
    dataUrl[value.shift()] = value.shift()
  }

  return dataUrl
}

/**
 * Retrieve an white version of an image
 * @param image
 * @returns {string}
 */
export const whitify = image => {
  const [filename, extension] = image.split('.')

  return filename + '-white.' + extension
}

// --------------------------- PRIVATE --------------------------- //

/**
 * check if x is between min and max
 * @param x
 * @param min
 * @param max
 * @returns {boolean}
 */
const isBetween = (x, min, max) => x >= min && x <= max
