import React, { Component } from 'react'
import {
  GoogleMap,
  withGoogleMap
} from 'react-google-maps'

const { REACT_APP_TYPE, REACT_APP_START_LAT, REACT_APP_START_LNG } = process.env

/* global google */
const Google = withGoogleMap(props => {
  const {
    center,
    circle,
    clusters,
    events,
    infoboxs,
    infoboxsTerminus,
    mapReference,
    markers,
    markersMap,
    markersPlaces,
    markersRER,
    pin,
    polygons,
    polylines,
    styles,
    zoom,
    isMobile
  } = props

  return <GoogleMap
    ref={mapReference}
    options={REACT_APP_TYPE === 'tcl' ? {
      styles,
      disableDefaultUI: true,
      gestureHandling: 'greedy',
      zoomControl: true
    } : {
      styles,
      fullscreenControl: false,
      gestureHandling: 'greedy',
      zoomControl: !isMobile,
      mapTypeControl: !isMobile,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.TOP_RIGHT,
        mapTypeIds: [
          'roadmap',
          'satellite'
        ]
      },
      maxZoom: 18
    }}
    zoom={zoom}
    center={center}
    {...events}>
    {polygons} {polylines}
    {circle} {pin}
    {markersMap}
    {clusters}
    {markers} {markersPlaces} {markersRER}
    {infoboxs} {infoboxsTerminus}
  </GoogleMap>
})

export class BaseMap extends Component {
  mapReference = React.createRef()

  state = {
    center: {
      lat: +REACT_APP_START_LAT,
      lng: +REACT_APP_START_LNG
    },
    circle: null,
    clusters: null,
    events: {},
    infoboxs: [],
    infoboxsTerminus: [],
    markers: [],
    markersMap: [],
    markersPlaces: [],
    markersRER: [],
    pin: null,
    polygons: [],
    polylines: [],
    terminus: false,
    zoom: 13
  }

  componentWillMount () {
    this.props.onRef(this)
  }

  componentWillUnmount () {
    this.props.onRef(undefined)
  }

  render () {
    return <Google
      loadingElement={<div className='loading' />}
      containerElement={<div className='mapContainer' />}
      mapElement={<div className='map' />}
      mapReference={this.mapReference}
      {...this.props}
      zoom={this.state.zoom}
      center={this.state.center}
      events={this.state.events}
      polylines={this.state.polylines}
      polygons={this.state.polygons}
      circle={this.state.circle}
      pin={this.state.pin}
      markersMap={this.state.markersMap}
      clusters={this.state.clusters}
      markers={this.state.markers}
      markersPlaces={this.state.markersPlaces}
      markersRER={this.state.markersRER}
      infoboxs={this.state.infoboxs}
      infoboxsTerminus={this.state.infoboxsTerminus}
    />
  }
}
