import axios from 'axios'
import { luminance } from 'luminance-js'
import moment from 'moment'
import 'moment/locale/fr'
import Snackbar from 'node-snackbar'
import React, { Component, Fragment } from 'react'
import { Collapse } from 'react-collapse'
import { Circle, Marker, Polygon, Polyline } from 'react-google-maps'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import Moment from 'react-moment'
import { DateCalendar } from 'react-picker-date-and-time'
import ReactToPrint from 'react-to-print'
import {
  addTimetableContent,
  createTimetable,
  geocodPlace,
  getDestinations,
  getModeNameByGroup,
  groupLinesByMode,
  navitiaToHourMin,
  resize,
  sortAlphabetic,
  sortBy,
  substringCoords,
  timetableDataIsEmpty,
  unique,
  updateURLState
} from './tools'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox')
const jsts = require('jsts')

console.log('map.js for Google')

const { REACT_APP_TYPE } = process.env
const favDev = {
  text: 'Favoris en cours de développement',
  actionText: 'Fermer',
  backgroundColor: '#e2001a',
  actionTextColor: '#333'
}

moment.updateLocale('fr', {
  relativeTime: {
    mm: '%d min',
    m: '1 min',
    ss: '1 min',
    s: '1 min'
  }
})

/* global google */

class TimeTable extends Component {
  render () {
    const component = this.props.component
    const currentLine = component.state.currentLine
    const date = component.state.url.date && component.state.url.date.substring(0, 4) + ' ' +
      component.state.url.date.substring(4, 6) + ' ' + component.state.url.date.substring(6, 8)

    return <div>
      <div className='timetable timetableSlide onlyPrint'>
        <div className='bold timetableTitle'> Fiche horaire du <Moment format='dddd DD MMMM YYYY'>{date}</Moment></div>
        <div className='timetableInfo'>
          {REACT_APP_TYPE === 'tcl' ? <div className='tclLine'>
            <img src={'assets/images/lines/' + currentLine.code + '.svg'} alt='bus icon' />
          </div> : <span className='lineCode' style={{
            background: '#' + currentLine.color,
            color: luminance(currentLine.color) > 0.5 ? '#333' : '#fff'
          }}>{currentLine.name}</span>}
          <div className='timetableLine'>{currentLine.mode.toLowerCase() !== 'rer'
            ? <span className='direction'>
              <div className='timetableDirection'>
                <span className='lightBold'>{currentLine.routes[currentLine.routes > 1 ? currentLine.direction_id : 0].name}</span>
              </div>
              <div className='timetableDirection'>
                <span className='lightBold'>{currentLine.direction_id &&
                  currentLine.routes[currentLine.routes > 1 ? currentLine.direction_id : 0].name}</span>
              </div>
            </span>
            : <span className='direction rer'>Ligne de RER</span>}
          </div>
        </div>
        <div className='timetableStopDirection'>
          <div className='timetableStop'>Arrêt : <span className='lowBold'>{component.state.timetableStop}</span></div>
          <div className='timetableDirection'>Direction : <span
            className='lowBold'>{currentLine.routes[currentLine.routes > 1 ? currentLine.direction_id : 0].name}</span></div>
        </div>
        <div className='timetableContent'>{!component.state.loadingTimetable && addTimetableContent(component, true)}</div>
        <div className='otherDirections'> <div>{component.state.timetableData && getDestinations(component.state.timetableData, component.state.currentLine.routes[component.state.currentLine.routes.length > 1 ? currentLine.direction_id : 0].name, true)}</div></div>
      </div>
      <div className='timetable printHide'>
        {component.state.loadingTimetable ? <img src='assets/images/loading.gif' width={30}
          alt='Loading' /> : createTimetable(component, false)}
      </div>
    </div>
  };
}

/**
 * Retrieve all transport information around a position
 * @param component
 * @param position
 */
export const around = async (component, position) => {
  const { map, url } = component.props

  try {
    const response = await axios({
      url: '/api/around',
      params: {
        lat: position.latLng.lat(),
        lng: position.latLng.lng(),
        navitia: REACT_APP_TYPE === 'tcl'
      }
    })

    // Remove current line
    removeLine(component)

    // Pin marker
    const pin = <Marker
      draggable
      onDragStart={component.removePinCircle}
      onDragEnd={position => {
        let newUrl = url.pathname
        newUrl += '?from=' + substringCoords(position.latLng)
        component.props.history.push(newUrl)
      }}
      options={{ zIndex: 999 }}
      icon={{
        url: 'assets/images/pin.svg',
        size: {
          width: 50,
          height: 50
        },
        scaledSize: {
          width: 50,
          height: 50
        },
        anchor: {
          x: 25,
          y: 25
        }
      }}
      position={position.latLng}
    />

    const circle = <Circle
      center={position.latLng}
      radius={750}
      options={{
        fillColor: 'rgba(0, 0, 0, 0.3)',
        strokeColor: 'transparent',
        clickable: false
      }}
    />

    // Manually retrieve bounds for the circle
    const ne = google.maps.geometry.spherical.computeOffset(position.latLng, 750 * Math.sqrt(2), 45)
    const sw = google.maps.geometry.spherical.computeOffset(position.latLng, 750 * Math.sqrt(2), 225)
    const bounds = new google.maps.LatLngBounds(sw, ne)

    const lines = response.data.shift()
    const groups = groupLinesByMode(unique(lines, 'id'), 'mode') // Make the array unique by the lines ID and order
    // them by group
    const openedGroups = Object.keys(groups).map((group, index) => ({
      group,
      opened: index === 0
    }))

    const dataPlaces = response.data.shift()
    const places = Object.keys(dataPlaces)
      .sort(function (a, b) {
        return a.localeCompare(b)
      })
      .reduce(function (sorted, key) {
        sorted[key] = dataPlaces[key]
        return sorted
      }, {})

    const openedPlacesGroups = Object.keys(places).map(place => ({
      place,
      opened: false
    }))

    component.setState({
      groups,
      openedGroups,
      places,
      openedPlacesGroups
    }, () => {
      const markers = map.state.markers
      const path = window.location.pathname

      const state = {
        pin: path.includes('around') ? pin : null,
        circle,
        markers,
        selectedInfobox: null,
        infoboxs: []
      }

      map.setState({ ...state }, async () => {
        fitBounds(map, null, bounds)
        resize(map.props.isMobile)

        if (path.includes('around')) {
          const data = component.state.inputAroundPlace || await geocodPlace(position)
          if (data.id !== 'no_places') {
            onSelectAutocompleteInput(data, component, 'inputAround')
          } else {
            component.setState({ groups: null, warning: 'Aucune adresse trouvée à cette position' })
          }
        }

        // Scroll to the top of the content if we are on mobile
        if (map.props.isMobile) {
          setTimeout(() => {
            const element = document.querySelector('.board')

            element && (element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop)
          }, 250)
        }
      })
    })
  } catch (e) {
    throw e
  }
}

/**
 * Display polylines for a line
 * @param line
 * @param map
 * @param args
 */
export const displayLinePath = async (line, map, ...args) => {
  const [isSection, main] = args
  const infos = isSection ? line.display_informations : null

  // TODO waiting sections
  if (isSection && line.type === 'waiting') {
    return
  }

  try {
    if (!isSection) {
      await axios.get(
        '/api/file?folder=routes&ext=geojson&name=' + line.code + '_' + line.network + '_' + line.direction_id)
        .then(result => {
          line.geojson = result.data
        }).catch(() => {
          line.geojson = null
        })
    }

    // Switch on location to change target if needed
    const path = window.location.pathname

    // Save others polylines not from the line selected
    let oldPolylines = path.includes('lines') ? map.state.polylines.filter(
      oldPolyline => line.code !== oldPolyline.key.split('_').shift()) : []
    const newPolylines = []
    const cloneOptions = {
      strokeColor: '#fff',
      strokeOpacity: 1,
      strokeWeight: 10,
      zIndex: 6,
      line: line.code + '_clone'
    }

    if (isSection && !main) {
      cloneOptions.strokeColor = '#aaa'
      cloneOptions.zIndex = 4
      cloneOptions.strokeWeight = 6
    }

    const lineOptions = {
      strokeColor: '#' + (infos ? infos.color : line.color),
      strokeOpacity: 1,
      strokeWeight: 6,
      zIndex: 7,
      line: line.code
    }

    // Street network, black dashed
    if (isSection && (line.type === 'street_network' || line.type === 'transfer')) {
      lineOptions.strokeColor = '#333'
      lineOptions.strokeOpacity = 0
      lineOptions.icons = [
        {
          icon: {
            path: 'M 0,-0.6 0,0.6',
            strokeOpacity: 0.9,
            scale: 4
          },
          offset: '0',
          repeat: '14px'
        }]
    }

    if (isSection && !main) {
      lineOptions.strokeColor = '#aaa'
      lineOptions.zIndex = 5
    }

    if (line.geojson) {
      if (!isSection) {
        let index = 0
        for (const feature of line.geojson.features) {
          let zoom = feature.properties.zoom
          const path = getCoordinates(feature).pop()

          // Push clone
          newPolylines.push(<Polyline
            key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '') + '_clone_' + index}
            path={path}
            options={cloneOptions}
            zoom={zoom}
          />)

          // Push line
          newPolylines.push(<Polyline
            key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '') + '_' + index}
            path={path}
            options={lineOptions}
            zoom={zoom}
          />)

          index++
        }
      } else {
        const path = getCoordinates(line.geojson).pop()

        // Push clone
        newPolylines.push(<Polyline
          key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '') + '_clone'}
          path={path}
          options={cloneOptions}
        />)

        // Push line
        newPolylines.push(<Polyline
          key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '')}
          path={path}
          options={lineOptions}
        />)
      }
    }

    if (isSection) {
      return newPolylines
    }

    // Concat selected lines with old lines
    oldPolylines = oldPolylines.concat(newPolylines)

    return oldPolylines
  } catch (error) {
    throw error
  }
}

/**
 * Display RER
 * @param component
 * @returns {Promise<void>}
 */
export const displayRER = async component => {
  const { map } = component.props

  const markersRER = []
  const response = await axios.get('/api/file?name=areas')
  const markers = response.data.filter(stop => {
    for (const line of stop.lines) {
      const info = getLine(component, line)

      if (info.mode === 'rer') {
        return stop
      }
    }
    return false
  })

  const polygon = new google.maps.Polygon({
    paths: map.state.polygons[0].props.path
  })

  for (const marker of markers) {
    // Display the marker only if is inside the polygon
    const inside = google.maps.geometry.poly.containsLocation(
      new google.maps.LatLng(+marker.coord.lat, +marker.coord.lon), polygon)
    const isEdge = google.maps.geometry.poly.isLocationOnEdge(
      new google.maps.LatLng(+marker.coord.lat, +marker.coord.lon), polygon, 0.003)

    if (inside || isEdge) {
      markersRER.push(
        renderMarker(component, marker, {
          icon: {
            url: 'assets/images/rer.svg',
            size: {
              width: 20,
              height: 20
            },
            scaledSize: {
              width: 20,
              height: 20
            },
            anchor: {
              x: 10,
              y: 10
            }
          },
          marker,
          zIndex: 30
        }))
    }
  }

  // Add RER polylines
  const c = {
    id: 'line:0:800:C',
    direction_id: 0
  }
  const d = {
    id: 'line:0:800:D',
    direction_id: 0
  }
  const polylines = []

  for (const rer of [c, d]) {
    const line = getLine(component, rer)

    if (!line.code) {
      return
    }

    const polyline = await displayLinePath(line, map)
    polylines.push(polyline)
  }

  map.setState({
    markersRER,
    polylines
  })
}

/**
 * Display places in toDisplay array in base map
 * @param component
 * @param toDisplay
 */
export const displayBaseMapPlaces = (component, toDisplay) => {
  const { map } = component.props
  const markers = []

  axios.get('/api/places-data?data=covoiturage').then(result => {
    for (const place of result.data) {
      // if (toDisplay.includes(place.cat)) {
      const code = place.code.split('_')[0]
      const marker = {
        id: place.id,
        coord: place.coord,
        name: place.name,
        code
      }
      const markerRendered = renderMarker(component, marker, {
        icon: {
          url: 'assets/images/places/' + code + '.svg',
          size: {
            width: 50,
            height: 50
          },
          scaledSize: {
            width: 50,
            height: 50
          },
          anchor: {
            x: 25,
            y: 50
          }
        },
        marker,
        zIndex: 30
      })
      markers.push(markerRendered)
    }
    // }

    map.setState({ markersMap: markers })
  })
}

export const displayTCLHeavyLines = async (component) => {
  const { map } = component.props

  const lines = ['301A', '302A', '303', '304', '326', 'T1A', 'T2', 'T3', 'T4A', 'T5']
  const polylines = []

  for (const line of lines) {
    const data = getLine(component, {
      id: 'line:tcl:' + line,
      direction_id: 0
    })

    if (data.code) {
      const polyline = await displayLinePath(data, map)
      polylines.push(...polyline)
    }
  }

  polylines.length > 0 && map.setState({ polylines }, () => fitBounds(map, polylines))
}

/**
 * Display outline territory
 * @param component
 */
export const displayTerritoryOutline = component => {
  const { map, theme } = component.props
  const color = theme.colors['primary']

  axios.get('/api/file?name=towns').then(response => {
    let unionPoly = null
    for (const town of response.data) {
      const polyTown = <Polygon path={invertCoords(town)} />
      const geometryFactory = new jsts.geom.GeometryFactory()
      const jstsPolygone = geometryFactory.createPolygon(
        geometryFactory.createLinearRing(googleMaps2JSTS(polyTown.props.path)))
      jstsPolygone.normalize()

      if (!unionPoly) {
        unionPoly = jstsPolygone
      } else {
        unionPoly = jstsPolygone.union(unionPoly)
      }
    }

    const polygons = [
      <Polygon
        options={{
          strokeWeight: 1.5,
          strokeColor: '#' + color,
          fillOpacity: 0,
          strokeOpacity: 0.7,
          clickable: false
        }}
        key={'outline-polygon'}
        path={jsts2googleMaps(unionPoly)}
      />
    ]

    if (window.location.pathname !== '/towns' && color) {
      map.setState({ polygons }, () => {
        // Avoid zoom on bounds under certain conditions
        if (!window.location.search) {
          fitBounds(map, polygons)

          if (window.location.pathname === '/') {
            // TODO up in tools factory
            const test = 'activate_RER_lines_options'
            if (test === true) {
              displayRER(component)
            }
          }
        }
      })
    }
  })
}

/**
 * Fit the bound of map with given objects (Polylines or Markers)
 * @param map
 * @param objects
 * @param bounds
 */
export const fitBounds = (map, objects, bounds = new google.maps.LatLngBounds()) => {
  if (objects) {
    for (const object of objects) {
      if (!object.props) {
        bounds.extend(object)
      } else if (object.props.path) { // Polylines or Polygon
        if (!object.props.zoom || object.props.zoom !== 0) {
          for (const path of object.props.path) {
            bounds.extend(path)
          }
        }
      } else if (object.props.position) { // Marker
        bounds.extend(object.props.position)
      } else if (object.props.center) { // Circle
        bounds.extend(object.props.center)
      } else if (object.props.defaultPosition) { // Infobox
        const position = object.props.defaultPosition
        bounds.extend({
          lat: position.lat(),
          lng: position.lng() - 0.001
        })
      } else {
        console.warn('You shall not pass')
        console.log(object)
        bounds.extend({
          lat: object[1],
          lng: object[0]
        })
      }
    }

    // Mobi'Val & maybe another shit ? No objects ? Zoom on map polygons ...
    if (objects.length === 0) {
      setTimeout(() => fitBounds(map, map.state.polygons))
    }
  }

  const padding = {
    left: 400,
    top: 50,
    bottom: 50
  }

  if (map.props.isMobile) {
    padding.top = 0
    padding.left = 0
    padding.bottom = document.querySelector('#app').scrollTop + 200
  }

  // Fit the map component with the calculated bounds
  // TODO try to optimize zoom level
  map.mapReference.current.fitBounds(bounds, padding)
}

/**
 *
 * @param feature
 * @returns {Array}
 */
export const getCoordinates = feature => {
  const paths = []

  let type = feature.type

  if (!type || type.includes('Feature')) {
    feature = feature.geometry
    type = feature.type
  }

  switch (type) {
    case 'LineString':
      const linePath = []
      for (const coord of feature.coordinates) {
        linePath.push(new google.maps.LatLng(coord[1], coord[0]))
      }
      paths.push(linePath)
      break

    case 'MultiLineString':
      for (const line of feature.coordinates) {
        const linePath = []
        for (const coord of line) {
          linePath.push(new google.maps.LatLng(coord[1], coord[0]))
        }
        paths.push(linePath)
      }
      break

    default:
      console.warn('Geometry type not found')
  }

  return paths
}

/**
 * Retrieve a line object from it's id
 * @returns Object
 * @param component
 * @param object
 */
export const getLine = (component, object) => {
  const { lines } = component.props

  return {
    ...lines.find(line => line.id === object.id),
    ...object
  }
}

export const goRouteCalculation = (component, object) => {
  const { map, history } = component.props
  const url = `/route-calculation?to=${object}`

  history.push(url)
  map.setState({
    markers: [],
    markersPlaces: [],
    infoboxs: []
  })
}

/**
 * Retrieve a stop object from it's id
 * @returns Object
 * @param component
 * @param object
 * @param line
 */
export const getStop = (component, object, line) => {
  const { areas, stops } = component.props

  let res = object.id
  if (object.id.includes('stop_area')) {
    res = areas.find(a => a.id === object.id).lines.find(l => l.id === line.id).stop_id
  }

  return {
    ...stops.find(s => s.id === res),
    ...object
  }
}

/**
 * Remove pin link to the input when typing
 * @param component
 * @param input
 */
export const hidePinWhileAutocomplete = (component, input) => {
  const { map } = component.props

  component.setState({
    [input + 'Data']: component.state[input + 'Data'].filter(
      address => address.id === 'geoloc' || address.id === 'favorite'
    )
  }, () => {
    if (map.state.pin) {
      map.setState({
        pin: null,
        circle: null
      })
    }
  })
}

/**
 * Invert coord
 * @param array
 * @returns {*}
 */
export const invertCoords = array => {
  return array.geometry.coordinates[0].map(coord => ({
    lat: coord[1],
    lng: coord[0]
  }))
}

/**
 * While user using an autocomplete input :
 * remove the pin
 * display chars in input
 * launch debounce function
 * @param e
 * @param component
 * @param input
 * @schedules boolean
 */
export const onChangeAutocompleteInput = (e, component, input, variant = false) => {
  const inputValue = e.target.value
  const map = component.props.map

  component.setState({
    [input + 'Value']: inputValue
  }, () => {
    if (input !== 'inputPlaces' && input !== 'inputTowns') {
      hidePinWhileAutocomplete(component, input, map)
    }

    debounceRequest(inputValue, component, input, variant)
  })
}

/**
 * Select the item selected by user in autocomplete and put pin on it
 * @param dataInputSelect
 * @param component
 * @param input
 */
export const onSelectAutocompleteInput = (dataInputSelect, component, input) => {
  try {
    // const type = dataInputSelect.embedded_type
    // coord.latLng = new google.maps.LatLng(dataInputSelect[type].coord.lat, dataInputSelect[type].coord.lon)
    component.setState({
      [input + 'Value']: dataInputSelect.label || dataInputSelect.name,
      [input + 'Data']: component.state[input + 'Data'].filter(
        address => address.id === 'geoloc' || address.id === 'favorite')
    })
  } catch (e) {
    console.warn(e.message)
    component.setState({
      [input + 'Value']: '',
      [input + 'Data']: []
    })
  }
}

/**
 * Handle zoom changed on map
 * @param component
 * @param path
 */
export const onZoomChanged = (component, path) => {
  const { map } = component.props
  const zoom = map.mapReference.current.getZoom()
  const markersMapLength = map.state.markersMap.length

  if (zoom > 15 && markersMapLength === 0) {
    displayBaseMapPlaces(component, ['Covoiturage'])
  } else if (zoom <= 15 && markersMapLength > 0) {
    map.setState({ markersMap: [] })
  }

  // Avoid render markers on certain conditions
  if (path && ((path.includes('places-interest') && !component.state.selectedPlace) ||
    (path.includes('towns') && !component.state.town))) {
    return
  }

  if (path) {
    map.setState({ zoom }, () => {
      const { status, zoom } = map.state

      if (zoom < 14 && status !== 'cluster') {
        map.setState({
          status: 'cluster',
          markers: [],
          selectedInfobox: null,
          infoboxs: []
        }, () => {
          if (path.includes('towns')) {
            setTimeout(() => {
              renderMarkers(component, true)
            }, 550)
          } else {
            renderMarkers(component, true)
          }
        })
      } else if (zoom >= 14 && zoom < 17 && status !== 'logical') {
        map.setState({
          status: 'logical',
          markers: [],
          selectedInfobox: null,
          infoboxs: []
        }, () => renderMarkers(component, true))
      } else if (zoom >= 17 && status !== 'physical') {
        map.setState({
          status: 'physical',
          markers: [],
          selectedInfobox: null,
          infoboxs: []
        }, () => renderMarkers(component))
      } else {
        renderMarkers(component)
      }
    })
  }
}

/**
 * Remove the current line from a given component
 * @param component
 * @param line
 * @param lineList
 */
export const removeLine = (component, line = null, lineList = false) => {
  const { map } = component.props

  map.setState({
    terminus: false,
    selectedInfobox: [],
    infoboxsTerminus: []
  })

  if (!line) {
    map.setState({
      selectedInfobox: null,
      infoboxs: [],
      polylines: []
    })

    component.setState({
      currentLine: null
    })
  } else {
    const { url } = component.state

    if (window.location.pathname !== '/lines') {
      component.props.history.push(
        component.props.url.pathname + component.props.url.search.replace('&line=' + url.line, '').split('&stop=')[0])
    } else {
      const { currentLine, selectedLines } = component.state

      if (url.current === line.id + '_' + line.direction_id) {
        if (url.selected) {
          const newSelectedLines = selectedLines.filter(selectLine => selectLine.id !== line.id)
          const newCurrent = newSelectedLines[0]

          const current = newCurrent.id + '_' + newCurrent.direction_id
          let selection = ''
          for (const stateLine of newSelectedLines) {
            if (stateLine.id !== newCurrent.id || lineList) {
              selection += stateLine.id + '_' + stateLine.direction_id + ','
            }
          }

          if (lineList) {
            component.props.history.push(component.props.url.pathname + '?selected=' + selection)
          } else {
            component.props.history.push(component.props.url.pathname + '?current=' + current + '&selected=' + selection
            )
          }
        } else {
          component.props.history.push(component.props.url.pathname)
        }
      } else {
        let current = null

        if (currentLine) {
          current = currentLine.id + '_' + currentLine.direction_id
        }

        let selection = current ? '&selected=' : '?selected='
        const linesSelectedToKeep = selectedLines.filter(selectLine => selectLine.id !== line.id)

        if ((linesSelectedToKeep.length === 1 && current) ||
          linesSelectedToKeep.length === 0) {
          selection = ''
        } else {
          for (const lineToKeep of linesSelectedToKeep) {
            if (current) {
              if (lineToKeep.id !== currentLine.id) {
                selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
              }
            } else {
              selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
            }
          }
        }

        if (!lineList && current) {
          component.props.history.push(component.props.url.pathname + '?current=' + current + selection)
        } else {
          component.props.history.push(component.props.url.pathname + selection)
        }
      }
    }
  }
}

/**
 * Remove map state event
 * @param map
 */
export const removeMapEvents = map => {
  if (!map) {
    return
  }

  map.setState({ events: {} })
}

/**
 * Render an infobox on a specific marker
 * If onLineSelected, the infobox will stay open onClick
 * @param marker
 * @param component   used in board
 * @param selected
 * @param board used for the click on map in the menu
 */
// TODO bug fitbounds
export const renderInfobox = (component, marker, selected, board) => {
  let { map } = component.props
  !map && (map = component)
  const markerId = marker.id ? marker.id : marker.key
  const markerCoord = marker.coord ? marker.coord : {
    lat: marker.props.position.lat.toString(),
    lng: marker.props.position.lng.toString()
  } // lat and lng must be strings
  const options = {
    closeBoxURL: '',
    enableEventPropagation: true,
    disableAutoPan: true,
    pixelOffset: {
      width: 10 + (marker.offset ? marker.offset.x : 0),
      height: -20 + (marker.offset ? marker.offset.y : 0)
    },
    zIndex: selected ? 20 : 21
  }

  // Get lines by stop_area
  const area = markerId.includes('stop_area') ? marker : getStop(component, { id: marker.id })

  const infoboxs = [
    <InfoBox
      key={markerId + '_infobox' + (selected && '_selected')}
      defaultPosition={new google.maps.LatLng(markerCoord.lat, markerCoord.lon)}
      options={options}>
      {//! board ?
        <div className='infobox' ref={ref => selected && ref &&
        avoidMapClick(ref)}>
          <div className='infoTitle'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              {marker.name}
              {marker.pmr && REACT_APP_TYPE !== 'tcl' && <div className='pmr' />}
            </div>
            {selected && !markerId.includes('TAD') &&
          (REACT_APP_TYPE === 'tcl'
            ? <span className='toolRouteCalculation toolSmall' onClick={() => {
              const coord = markerCoord.lon + ';' + markerCoord.lat
              marker.id.includes('stop') ? goRouteCalculation(component, marker.id) : goRouteCalculation(component, coord)
            }}><img src='assets/images/route-calculation.svg' alt='itinéraire' /></span>
            : <span className='toolRouteCalculation toolSmall' onClick={() => {
              const coord = markerCoord.lon + ';' + markerCoord.lat
              marker.id.includes('stop') ? goRouteCalculation(component, marker.id) : goRouteCalculation(component, coord)
            }} />)}
          </div>
          {area && <div className='infoContent'>{renderLinesLabels(component, area.lines, 'infobox', marker)}</div>}
        </div>
        // : <div className='infobox board' ref={ref => ref && avoidMapClick(ref)}>
        // <div className='infoTitle infoTitleBoard' onClick={() => { component.onClickInfobox(marker, 'around') }}>
        //   Autour de moi
        // </div>
        // <div className='infoTitle infoTitleBoard'
        //   onClick={() => { component.onClickInfobox(marker, 'route-calculation') }}>
        //   Itinéraire
        // </div>
      // </div>
      }
    </InfoBox>
  ]

  if (selected) {
    map.setState({
      selectedInfobox: infoboxs,
      infoboxs
    })
  } else {
    map.setState({
      infoboxs: infoboxs.concat(map.state.selectedInfobox)
    })
  }
}

/**
 * Render a line on a given coponent
 * @param component
 * @param line
 */
export const renderLine = (component, line) => {
  !line && (line = component.state.currentLine)

  const { stopsList } = component.state
  const { map, history } = component.props
  const onLineSelected = component.onLineSelected
  const { isMobile } = map.props
  const path = history.location.pathname

  if (line.cat === 'TAD-markers-only') {
    sortBy(stopsList, 'name')
  }

  const pickerStyle = {
    calendar: {
      bottom: map && map.props.isMobile ? '28%' : '',
      left: map && map.props.isMobile ? '0' : '',
      right: map && map.props.isMobile ? '0' : '',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)'
    },
    colon: {
      padding: '0 5px 0 0 !important'
    },
    control: {
      boxShadow: 'none',
      cursor: 'pointer'
    },
    first: '#005e86',
    menu: {
      marginLeft: -5,
      position: 'fixed',
      bottom: map && map.props.isMobile ? '25%' : '',
      top: ''
    },
    weekDays: {
      padding: '5px 0'
    },
    monthSelected: {
      fontWeight: 600
    },
    calendarButtonStyle: {
      fontSize: '1em',
      margin: 10
    },
    inputsHours: {
      fontSize: '1em'
    },
    today: {
      background: '#f4f4f4',
      color: '#333',
      fontWeight: '500'
    }
  }

  if (REACT_APP_TYPE === 'tcl') {
    pickerStyle.first = '#e2001a'
    pickerStyle.calendarButtonStyle = {
      ...pickerStyle.calendarButtonStyle,
      margin: 10,
      border: '2px solid rgba(0, 0, 0, 0.08)',
      borderRadius: 5,
      marginRight: 10
    }
    pickerStyle.calendar = {
      ...pickerStyle.calendar,
      padding: 15,
      background: '#f1f5f5'
    }
    pickerStyle.week = {
      ...pickerStyle.week,
      background: '#fff'
    }
  }

  return <div className={REACT_APP_TYPE === 'tcl' ? 'tcl-elevation' : ''}>
    {REACT_APP_TYPE !== 'tcl' && <Fragment>
      {path.includes('lines') && !component.state.timetable && <div className='leftLines'>
        {component.state.selectedLines.filter(line => line.id !== component.state.currentLine.id).map(line =>
          <div key={line.id}>
            <div className='leftLine' onClick={() => onLineSelected(line)}>
              <div className='lineLabel'>
                {line.mode.toLowerCase() !== 'tad' ? <span
                  className={'lineCode' + (line.name.length > 5 ? ' longLineCode' : '')}
                  style={line.mode === 'rer' ? {
                    backgroundColor: 'white',
                    color: '#' + line.color,
                    minWidth: 12,
                    border: '2px solid #' + line.color,
                    borderRadius: '50%',
                    fontWeight: 700,
                    flex: 0,
                    margin: '0 10px',
                    padding: '3px 5px'
                  } : {
                    background: '#' + line.color,
                    color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                  }}>{line.name}</span> : <div className='tad' />}
              </div>
              {line.mode.toLowerCase() !== 'rer' ? <span className='direction'>
                  Direction :<br />
                <span className='lightBold'>{line.routes[line.routes.length > 1 ? line.direction_id : 0].name}</span>
              </span> : <span className='direction rer'>Ligne de RER</span>}
              <div className='tools'>
                {line.routes.length > 1 && <span className='toolSwap' onClick={e => {
                  e.stopPropagation()
                  onLineSelected(onSwitchedDirection(line))
                }} />}
                <span className='toolRemove' onClick={e => {
                  e.stopPropagation()
                  removeLine(component, line)
                }} />
              </div>
            </div>
            {line.errorPath && <div className='error'>
              <img src='assets/images/error.svg' alt='Warning' />
              Impossible d'afficher le tracé de la ligne {line.name}
            </div>}
          </div>)}
      </div>}
    </Fragment>}
    <div className='currentLine'>
      <div className='leftLine' key={line.id}>
        {REACT_APP_TYPE === 'tcl' ? <div className='tclLine' style={{ padding: '10px' }}>
          <img src={'assets/images/lines/' + line.code + '.svg'} alt='bus icon' />
        </div> : <div className='lineLabel'>
          {line.mode.toLowerCase() !== 'tad'
            ? <span className={'lineCode' + (line.name.length > 5 ? ' longLineCode' : '')} style={line.mode === 'rer'
              ? {
                backgroundColor: 'white',
                color: '#' + line.color,
                minWidth: 12,
                border: '2px solid #' + line.color,
                borderRadius: '50%',
                fontWeight: 700,
                flex: 0,
                margin: '0 10px',
                padding: '3px 5px'
              } : {
                background: '#' + line.color,
                color: luminance(line.color) > 0.5 ? '#333' : '#fff'
              }}>{line.name}</span> : <div className='tad' />}
        </div>}
        {line.mode.toLowerCase() !== 'rer' ? <span className='direction'>
            Direction :<br />
          <span className='lightBold'>{line.routes[line.routes.length > 1 ? line.direction_id : 0].name}</span>
          {line.mode.toLowerCase() === 'tad' && (isMobile
            ? <a className='tadLink' href='tel:+33800691891'>Réserver votre trajet Mobi'Val</a>
            : <a className='tadLink' target='_blank' rel='noopener noreferrer'
              href='http://www.mobivaldessonne.com/index.php/joomla-fr/2013-12-12-18-55-39'>Réserver votre trajet
              Mobi'Val</a>)}
        </span> : <span className='direction rer'>Ligne de RER</span>}
        <div className='tools'>
          {line.routes.length > 1 &&
          <span className='toolSwap' onClick={() => onLineSelected(onSwitchedDirection(line))} />}
          {REACT_APP_TYPE !== 'tcl' ? <span className='toolRemove' onClick={() => removeLine(component,
            line)} /> : <span className='toolFavorite' onClick={() => {
            Snackbar.show(favDev)
          }} />}
        </div>
      </div>
      {line.errorPath && <div className='error'>
        <img src='assets/images/error.svg' alt='Warning' />
        Impossible d'afficher le tracé de la ligne {line.name}
      </div>}
      {component.state.timetable ? <Fragment>
        <div className='timetableStop'>
          <span>Arrêt : <span className='bold'>{component.state.timetableStop}</span></span>
          {!timetableDataIsEmpty(component.state.timetableData) && <ReactToPrint
            pageStyle={'@page { size: auto;  margin: 10mm 15mm; } @media print { body { -webkit-print-color-adjust: exact; } }'}
            trigger={() => <div className='print' />} content={() => component.componentRef} />}
        </div>
        <div>
          <Fragment>
            <DateCalendar
              lang={'fr'}
              systemUS={false}
              style={pickerStyle}
              todayTxt="Aujourd'hui"
              image={'assets/images/calendar.svg'}
              setDate={updateURLState(component.props.url).date}
              getSelectedDate={getSelectedDate => {
                const url = component.props.url
                const date = getSelectedDate.format('YYYYMMDD')

                if (!updateURLState(url).date || updateURLState(url).date !== date) {
                  component.props.history.push(url.pathname + url.search.split('&date=')[0] + '&date=' + date)
                }
              }}
            />
            <TimeTable ref={el => (component.componentRef = el)} component={component} /></Fragment>
        </div>
      </Fragment> : <div className='stops scroll'>
        {stopsList.map((stop, index) => <div key={stop.id} className='stop'>
          {line.cat !== 'TAD-markers-only' && <Fragment>
            <div className='border' style={{ borderLeftColor: '#' + line.color }} />
            <div style={{ border: '2px solid #' + line.color }}
              className={'point' + (stop.terminus ? ' pointTerminus' : '')} />
          </Fragment>}
          <div className={'stopName' + (stop.opened ? ' selected' : '')} onClick={() => {
            if (stop.opened) {
              component.props.history.push(component.props.url.pathname + component.props.url.search.split('&stop=')[0]) // deselect stop when clicking on a stop already opened
            // } else if (line.mode.toLowerCase() !== 'bus' && REACT_APP_TYPE !== 'tcl') {
            //   noSchedulesForType(component, stop, line, line.mode.toLowerCase())
            } else {
              schedules(component, stop, line)
            }
          }} onMouseOver={() => {
            /* console.log('stop', stop, map.state.markers,
              map.state.markers.find((marker) => marker.key.includes(stop.id)))
            const marker = map.state.markers.find((marker) => marker.key.includes(stop.id)) */
            renderInfobox(component, stop)
          }} onMouseOut={() => {
            onMarkerMouseOut(component)
          }}>
            <div className={stop.opened ? 'selectedStop' : ''}>
              <div className='flex'>
                <div className='lightBold'>{stop.name}</div>
                {stop.pmr && REACT_APP_TYPE !== 'tcl' && <div className='pmr' />}
              </div>
              {stop.opened && <div className='stopActions'>
                {REACT_APP_TYPE === 'tcl' ? <span className='toolFavorite' onClick={e => {
                  e.stopPropagation()
                  Snackbar.show(favDev)
                }} /> : !stop.tad && <span className='toolRouteCalculation' onClick={e => {
                  const coord = stop.coord.lon + ';' + stop.coord.lat
                  e.stopPropagation()
                  stop.stop_area ? goRouteCalculation(component, stop.stop_area) : goRouteCalculation(component, coord)
                }} />}
              </div>}
            </div>
            {REACT_APP_TYPE !== 'tcl' && stop.opened &&
            <div className='connections'>{retrieveConnections(component, stop, line)}</div>}
            {stop.schedules !== 'nothing_to_show_there' && <Collapse isOpened={!!stop.opened}>
              {stop.opened ? !stop.rer ? <div className='selectedContent'>
                {stop.schedules ? <div key={stop.id + '_schedules'} className='schedules'>
                  {REACT_APP_TYPE !== 'tcl' && <div className='nextSchedules'>Prochains passages :</div>}
                  {stop.schedules.length > 0 ? stop.schedules.map((schedule, index) => (
                    <div key={'schedule-' + index} className='schedule'>
                      {schedule.realtime ? <Fragment>
                        <Moment locale='fr' fromNow ago>
                          {schedule.time}
                        </Moment>
                        <img src='assets/images/realtime.gif' alt='realtime'
                          title='Horaire en temps réel' /></Fragment> : navitiaToHourMin(schedule.time)}
                    </div>
                  )) : <div>Aucun passage</div>}
                </div> : <img src='assets/images/loading.gif' width={30} alt='Loading' />}
                <div className='seeTimetable' onClick={e => {
                  e.stopPropagation()
                  const url = component.props.url
                  const date = new Date().toISOString().split('T')[0].replace(/-/g, '')
                  component.props.history.push(url.pathname + url.search + '&date=' + date)
                }}>
                  {REACT_APP_TYPE === 'tcl'
                    ? <img src='assets/images/timetable.svg' alt='calendrier' />
                    : <img src='assets/images/calendar.svg' alt='calendrier' />}Fiche
                  {REACT_APP_TYPE === 'tcl' && <br />} horaire
                </div>
              </div> : <div style={{ paddingLeft: 10 }}>Pour afficher les horaires, se référer au calcul
                d'itinéraire</div> : <div />}
            </Collapse>}
          </div>
        </div>)}
      </div>}
    </div>
  </div>
}

/**
 * Render lines passing by an area
 * @param component
 */
export const renderLinesByArea = (component) => {
  return <div className='group'>
    <div className='groupName'>
      <div className='mode'>Lignes passant par {component.state.linesListStop}</div>
    </div>
    <div className='groupOffsetBottom'>{renderLinesLabels(component, component.state.linesList, 'line')} </div>
  </div>
}

/**
 * Display lines sorted by group (collapsable)
 * @param component
 * @returns {any[]}
 */
export const renderLinesGroup = component => {
  if (Object.keys(component.state.groups).length === 0) {
    return <div className='empty'>Aucune ligne passante à cette position</div>
  }

  return Object.keys(component.state.groups).map((group, index) =>
    group !== 'carpool'
      ? <div key={group} className='group' onClick={() => collapseGroup(component, 'openedGroups', group)}>
        <div className='groupName'>
          <div className='mode' dangerouslySetInnerHTML={{ __html: getModeNameByGroup(group) }} />
          <div className='arrowGroup'>
            <img className={!component.state.openedGroups[index].opened ? 'closed' : ''} src='assets/images/v.svg'
              alt='arrow' />
          </div>
        </div>
        <Collapse className={component.state.openedGroups[index].opened ? 'groupOffsetBottom' : ''}
          isOpened={component.state.openedGroups[index].group === group && !!component.state.openedGroups[index].opened}>
          {renderLinesLabels(component, component.state.groups[group], group)}
        </Collapse>
      </div>
      : <div key={group} className='group' onClick={() => collapseGroup(component, 'openedGroups', group)}>
        <div className='groupName'>
          <div className='mode'>Le covoiturage</div>
          <div className='arrowGroup'>
            <img className={(!component.state.openedGroups[index].opened) ? 'closed' : ''} src='assets/images/v.svg'
              alt='arrow' />
          </div>
        </div>
        <Collapse className={component.state.openedGroups[index].opened ? 'groupOffsetBottom' : ''}
          isOpened={(component.state.openedGroups[index].group === group && !!component.state.openedGroups[index].opened)}>
          {renderCarpools(component, component.state.groups[group], group)}
        </Collapse>
      </div>
  )
}

export const renderPlacesGroup = component => {
  if (!component.state.places) {
    return
  }

  if (Object.keys(component.state.places).length === 0) {
    return <div className='empty'>Aucun lieu à cette position</div>
  }

  return Object.keys(component.state.places).map((place, index) =>
    <div key={place} className='group'>
      <div className='groupName' onClick={() => collapseGroup(component, 'openedPlacesGroups', place)}>
        <div className='groupHead'>
          <img src={`assets/images/places/${component.state.places[place][0].code.split('_')[0]}.svg`}
            alt='groupe' />
          <span>{place}</span>
        </div>
        <div className='arrowGroup'>
          <img className={!component.state.openedPlacesGroups[index].opened ? 'closed' : ''} src='assets/images/v.svg'
            alt='arrow' />
        </div>
      </div>
      <Collapse
        isOpened={component.state.openedPlacesGroups[index].place === place &&
        !!component.state.openedPlacesGroups[index].opened}>
        {component.state.places[place].map(item => (
          <div key={item.id} className='place' onMouseOver={() => {
            renderInfobox(component, item)
          }} onMouseOut={() => {
            onMarkerMouseOut(component)
          }}>
            {item.name}
          </div>
        ))}
      </Collapse>
    </div>
  )
}

/**
 * Render labels for given lines
 * @param component
 * @param lines
 * @param key
 * @param marker
 * @returns Array|HTMLElement
 */
export const renderLinesLabels = (component, lines, key, marker) => {
  const onLineSelected = component.onLineSelected

  const div = data => {
    let isTad
    if (key === 'infobox') {
      isTad = data.find(l => l.id === 'line:line:CCVE_TAD1-0' || l.id === 'line:line:CCVE_TAD1-1')
    }

    return <div key={key + Math.random()} className={key === 'infobox' ? isTad ? 'infoboxTads' : 'infoboxLines' : key === 'tad' ? 'lines tad-lines' : 'lines'}>
      {data.map(line => {
        // Retrieve the global line
        line = getLine(component, line)

        return REACT_APP_TYPE === 'tcl'
          ? <div className='tclLine' key={line.id} onClick={e => {
            e.stopPropagation()
            onLineSelected(line, marker)
          }}><img src={'assets/images/lines/' + line.code + '.svg'} alt='bus icon' /></div>
          : <div key={line.id} className={key === 'infobox' ? 'infoboxLine' : 'line'} onClick={e => {
            e.stopPropagation()
            onLineSelected(line, marker)
          }}>
            {line.mode.toLowerCase() !== 'tad'
              ? <div
                className={key === 'infobox'
                  ? 'infoboxLineCode' + (line.name.length > 5 ? ' longLineCode' : '')
                  : 'lineCode' + (line.name.length > 5 ? ' longLineCode' : '')}
                style={line.mode === 'rer' ? {
                  backgroundColor: 'white',
                  color: '#' + line.color,
                  minWidth: 12,
                  border: '2px solid #' + line.color,
                  borderRadius: '50%',
                  fontWeight: 700,
                  flex: 0,
                  margin: '0 10px',
                  padding: '3px 5px'
                } : {
                  background: '#' + line.color,
                  color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                }}>
                {line.name}
              </div> : <div className={'tad tad' + line.code.replace('Mobi\'Val', '')} />}
          </div>
      })}
    </div>
  }

  /* if (key === 'métro' && REACT_APP_TYPE === 'tcl') {
    const children = []

    for (const mode of ['métro', 'funiculaire', 'tramway']) {
      children.push(div(lines.filter(line => line.mode === mode)))
    }

    return <div className='tcl-mftrx'>{children}</div>
  } else */
  if (key === 'infobox' && REACT_APP_TYPE === 'tcl') {
    const children = []

    for (const mode of [['métro', 'funiculaire', 'tramway'], ['ligne majeure', 'bus', 'pleine lune']]) {
      const childs = lines.filter(line => {
        line = getLine(component, line)

        if (Array.isArray(mode)) {
          return mode.includes(line.mode)
        } else {
          return line.mode === mode
        }
      })

      if (childs.length > 0) {
        children.push(div(childs))
      }
    }

    return <div className='tcl-infobox'>{children}</div>
  } else {
    // WTF Sorting ...
    if (REACT_APP_TYPE === 'tcl') {
      // Sort only the bus
      if (lines[0] && lines[0].mode === 'bus') {
        sortAlphabetic(lines, 'code')
      }
    }

    if (!lines) {
      return
    }

    lines.sort((a, b) => {
      a = getLine(component, a)
      b = getLine(component, b)
      const codeA = parseInt(a.code.replace('C', ''), 10)
      const codeB = parseInt(b.code.replace('C', ''), 10)

      return codeA - codeB
    })

    return div(lines)
  }
}

export const renderCarpools = (component, carpools, key) => {
  return carpools.map(carpool => <div key={key + Math.random()} className='group groupCarpool' onClick={(e) => {
    e.stopPropagation()
    const { map } = component.props
    const markers = []
    const marker = {
      id: 'carpool-place-' + carpool.id,
      coord: carpool.coord,
      name: carpool.name,
      code: carpool.code.split('_')[0]
    }

    const markerRendered = renderMarker(component, marker, {
      icon: {
        url: 'assets/images/places/' + marker.code + '.svg',
        size: {
          width: 50,
          height: 50
        },
        scaledSize: {
          width: 50,
          height: 50
        },
        anchor: {
          x: 25,
          y: 50
        }
      },
      marker,
      zIndex: 30
    }, carpool)

    markers.push(markerRendered)

    map.setState({ markersPlaces: [...markers] }, () => {
      fitBounds(map, markers)
      renderInfobox(component, marker, true)
    })
  }}>
    <div className='groupName'>
      <div className='groupHead'>
        <img
          src={'assets/images/places/' +
          carpool.code.split('_')[0] + '.svg'}
          alt='groupe'
        />
        <span>{carpool.name}</span>
      </div>
    </div>
  </div>)
}

/**
 * Render a marker on the map
 * @param marker
 * @param options
 * @param component
 * @param place
 * @returns Marker
 */
export const renderMarker = (component, marker, options, place) => {
  return <Marker
    key={marker.id}
    name={marker.name}
    onMouseOver={() => onMarkerMouseOver(component, marker)}
    onMouseOut={() => onMarkerMouseOut(component)}
    onClick={() => onMarkerClick(component, marker, place)}
    position={{
      lat: +marker.coord.lat,
      lng: +marker.coord.lon
    }}
    {...options}
  />
}

export const renderMarkerRouteCalculation = (key, component, position) => {
  const { url, map } = component.props

  return <Marker
    key={key}
    draggable={!component.state.loading}
    onDragStart={component.onBackToParams}
    onDragEnd={async position => {
      let newUrl = url.pathname

      if (key === 'inputStart-pin') {
        if (map.state.inputStartPin && !map.state.inputEndPin) {
          newUrl += '?from=' + substringCoords(position.latLng)
        } else if (map.state.inputStartPin && map.state.inputEndPin) {
          newUrl += '?from=' + substringCoords(position.latLng) + '&to=' + component.state.url.to
        }
      } else {
        if (!map.state.inputStartPin && map.state.inputEndPin) {
          newUrl += '?to=' + substringCoords(position.latLng)
        } else if (map.state.inputStartPin && map.state.inputEndPin) {
          newUrl += '?from=' + component.state.url.from + '&to=' + substringCoords(position.latLng)
        }
      }

      component.props.history.push(newUrl)

      // Launch calculation
      if (map.state.markers.length === 2 && component.state.journeys !== undefined) {
        component.setState({ journey: null }, () => component.calcItineraries())
      }
    }}
    options={{ zIndex: 999 }}
    icon={{
      url: `assets/images/${key === 'inputStart-pin' ? 'flag-start' : 'flag-end'}.svg`,
      size: {
        width: 35,
        height: 35
      },
      scaledSize: {
        width: 35,
        height: 35
      },
      anchor: {
        x: 17,
        y: 17
      }
    }}
    position={position.latLng}
  />
}

/**
 * Render markers on the map
 * @returns {Promise<void>}
 */
// TODO RENAME
export const renderMarkers = async (component, isArea = false) => {
  const { map } = component.props
  const path = window.location.pathname

  if (!isArea) {
    isArea = map.state.zoom < 17
  }

  // Avoid Reload from API
  const response = await axios.get('/api/file?name=' + (isArea ? 'areas' : 'stops'))
  const data = response.data

  // Retrieve the current polygon
  const polygon = new google.maps.Polygon({
    paths: map.state.polygons[0].props.path
  })

  // const bounds = map.mapReference.current.getBounds();
  const markers = []
  for (const marker of data) {
    // Test if the current marker is visible on the map
    // const visible = bounds.contains({ lat: +marker.coord.lat, lng: +marker.coord.lon });

    // Retrieve the mode of the first line
    const mode = getLine(component, marker.lines[0]).mode

    // Display the marker only if is inside the polygon
    const inside = google.maps.geometry.poly.containsLocation(
      new google.maps.LatLng(+marker.coord.lat, +marker.coord.lon), polygon)

    if (inside || path.includes('towns')) {
      markers.push(
        renderMarker(component, marker, {
          icon: {
            url: mode === 'rer'
              ? 'assets/images/rer.svg'
              : 'assets/images/bus.svg',
            size: {
              width: 20,
              height: 20
            },
            scaledSize: {
              width: 20,
              height: 20
            },
            anchor: {
              x: 10,
              y: 10
            }
          },
          marker,
          zIndex: mode === 'tad' ? 18 : 20
        }))
    }
  }

  // Test if we have to display cluster or not
  if (map.state.status === 'cluster' || map.state.zoom < 14) {
    const clusters = <MarkerClusterer
      styles={[
        {
          url: 'assets/images/bus.svg',
          height: 30,
          width: 30,
          textColor: 'white'
        }]}
      gridSize={60}>
      {markers}
    </MarkerClusterer>

    map.setState({
      markers: [],
      clusters
    }, () => resize(map.props.isMobile))
  } else {
    map.setState({
      markers,
      clusters: null
    }, () => resize(map.props.isMobile))
  }
}

// TODO RENAME
export const renderPlaces = async component => {
  const { map } = component.props
  const places = component.state.places

  // TODO THROW ERROR
  if (!places) {
    console.warn('No places')
    return
  }

  const allPlaces = []

  for (const place of Object.keys(places)) {
    allPlaces.push(...places[place])
  }

  const markers = []
  for (const place of allPlaces) {
    markers.push(
      renderMarker(component, place, {
        icon: {
          url: 'assets/images/places/' + place.code.split('_')[0] + '.svg',
          size: {
            width: 50,
            height: 50
          },
          scaledSize: {
            width: 50,
            height: 50
          },
          anchor: {
            x: 25,
            y: 50
          }
        }
      })
    )
  }

  map.setState({
    clusters: null,
    status: null,
    markers
  }, () => resize(map.props.isMobile))
}

export const renderPolygon = (path, options, key, props) => {
  return <Polygon options={options} key={key} path={path} {...props} />
}

export const renderPolyline = (path, options, key) => {
  return <Polyline key={key} path={path} options={options} />
}

// Render town data
export const renderTown = (component) => {
  return <Tabs
    selectedIndex={component.state.tab || 0}
    onSelect={index => component.onTabSelected(index)}
    selectedTabClassName='active'
    selectedTabPanelClassName='active scroll'>
    <TabList className='tabList'>
      <Tab className='tab'>Transport<br /><span>à 10 minutes</span></Tab>
      <Tab className='tab'>Lieux d'intérêt<br /><span>à 10 minutes</span></Tab>
    </TabList>
    <TabPanel className='tabPanel'>
      {renderLinesGroup(component)}
    </TabPanel>
    <TabPanel className='tabPanel'>
      {renderPlacesGroup(component)}
    </TabPanel>
  </Tabs>
}

/**
 * Retrive the nearest stop
 * @param component
 * @param currentLine
 * @returns {*}
 export const retrieveNearestStop = (component, currentLine) => {
  const { map } = component.props
  const { circle } = map.state

  if (!circle) {
    return
  }

  const markers = map.state.markers.filter(marker => {
    for (const line of marker.props.marker.lines) {
      if (line.id === currentLine.id && line.direction_id === currentLine.direction_id) {
        return true
      }
    }
    return false
  })

  let nearest = null
  for (const marker of markers) {
    if (nearest) {
      const markerPosition = new google.maps.LatLng({
        lat: marker.props.position.lat,
        lng: marker.props.position.lng
      })

      const nearestPosition = new google.maps.LatLng({
        lat: nearest.props.position.lat,
        lng: nearest.props.position.lng
      })

      const toNearest = google.maps.geometry.spherical.computeDistanceBetween(circle.props.center, nearestPosition)
      const toMarker = google.maps.geometry.spherical.computeDistanceBetween(circle.props.center, markerPosition)

      if (toMarker <= toNearest) {
        nearest = marker
      }
    } else {
      nearest = marker
    }
  }

  return nearest
} */

/**
 * Retrieve schedules for a given stop in a given component
 * @param component
 * @param stop
 * @param line
 * @param date
 * @param fromMarkerClick
 */
export const schedules = (component, stop, line, date = '', fromMarkerClick = false) => {
  const { stopsList } = component.state

  // TODO DANS AROUND ARRET LE PLUS PROCHE PAS VISIBLE DU COUP...
  if (line.mode === 'tad' || line.mode === 'rer') {
    // return
  }

  // TODO avoid open schedules on last terminus

  // Avoid load schedules for a nonsense data
  let markerContainsLine = false
  for (const data of stop.lines) {
    if (data.id === line.id) {
      markerContainsLine = true
      break
    }
  }

  if (!markerContainsLine) {
    return
  }

  if (!component.props.url.search.includes('&stop=' + stop.id) &&
    !component.props.url.search.includes('&stop=' + stop.stop_area)) {
    component.props.history.push(
      component.props.url.pathname + component.props.url.search.split('&stop=')[0] + '&stop=' + stop.id)
  } else {
    for (const s of stopsList) {
      s.schedules = null
      s.opened = false

      if (stop.id.includes('stop_area')) {
        s.opened = s.stop_area === stop.id
      } else {
        s.opened = s.id === stop.id
      }
    }

    component.setState({
      timetable: false,
      stopsList
    }, async () => {
      // Retrieve element and scroll to it
      const selected = stop.id.includes('stop_area')
        ? stopsList.find(s => s.stop_area === stop.id)
        : stopsList.find(s => s.stop_area === stop.stop_area)

      // Select the stop
      setTimeout(() => displayStopOnScrollToIt(component, stop, line, selected, fromMarkerClick))

      const schedules = []
      // TODO FUCK CCVE
      if (line.mode === 'tad' || line.mode === 'rer') {
        selected.schedules = 'nothing_to_show_there'
        component.setState({ stopsList })
        return
      }

      axios.get('/api/schedules', {
        params: {
          stop: selected.id,
          line: line.id,
          date,
          idfm: true,
          count: REACT_APP_TYPE === 'tcl' ? 2 : 3
        }
      }).then(response => {
        // TODO Use data_freshness to retrieve realtime schedules
        for (const resp of response.data) {
          schedules.push({
            time: resp.stop_date_time.departure_date_time,
            realtime: resp.stop_date_time.data_freshness === 'realtime'
          })
        }

        selected.schedules = schedules
      }).catch(e => {
        selected.schedules = []

        // TODO Handle error message
        console.warn('Error : ', e.response.data.id)
      }).finally(() => {
        component.setState({ stopsList })
      })
    })
  }
}

/**
 *
 * @param component
 * @param stop
 * @param line
 * @param selected
 * @param fromMarkerClick
 */
const displayStopOnScrollToIt = (component, stop, line, selected, fromMarkerClick = false) => {
  const { stopsList } = component.state
  const { map, url } = component.props
  const { markers, clusters, status } = map.state
  const { isMobile } = map.props

  // Retrieve the correct marker on map for the current clicked stop
  const id = status !== 'physical' ? (stop.id.includes('stop_point')
    ? stop.stop_area
    : stop.id) : stop.id
  const array = status === 'cluster' && clusters
    ? clusters.props.children
    : markers
  let marker = array.find(marker => marker.key === id)

  if (url.pathname.includes('lines')) {
    marker = map.state.markers.find(marker => marker.key === line.code + '_' + stop.id)
  }

  if (!fromMarkerClick) {
    if (!marker) {
      // Ugly fix for waiting marker to display
      setTimeout(() => {
        marker = array.find(marker => marker.key === id)
        marker && marker.props.onClick()
      }, 500)
    } else {
      marker.props.onClick()
    }
  }

  // Set BIG marker

  // Disable scrolling to element
  if (REACT_APP_TYPE !== 'tcl' && selected) {
    // const index = stopsList.indexOf(selected);
    let index = 0
    for (const stop of stopsList) {
      if (stop.id === selected.id) {
        break
      }

      index++
    }

    const element = document.querySelector(`.stops > :nth-child(${index + 1})`)

    // Scroll board to the selected element
    setTimeout(() => {
      element && (element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop)

      // Bring the content to top
      document.querySelector('#app').scrollTop = window.innerHeight
    })
  }

  // Fit bounds on the marker + infobox (@see TODO in fitBounds())
  const offset = {
    x: 400,
    y: 0
  }

  if (isMobile) {
    const boundingClientRect = document.querySelector('.board').getBoundingClientRect()

    offset.x = -150
    offset.y = boundingClientRect.top + boundingClientRect.top / 2 + 30
  }

  if (!marker) {
    // Ugly fix for waiting marker to display
    setTimeout(() => marker && setMapBounds(map, [marker.props.position], offset), 500)
  } else {
    setMapBounds(map, [marker.props.position], offset)
  }
}

/**
 * Display line paths and info
 * @param component
 * @param line
 * @param linesTab
 * @param selectedLines
 */
export const selectLine = (component, line, linesTab = false, selectedLines = []) => {
  const { map, stops } = component.props

  if (!line.direction_id) {
    line.direction_id = line.routes.length > 1 ? 0 : line.routes[0].direction_id
  }

  return axios.get(`/api/file?folder=stops&name=${line.code}_${line.network}_${line.direction_id}`)
    .then(async response => {
      // TODO Fix retrive lines at generate
      for (const stop of response.data) {
        const infos = stops.find(s => s.id === stop.id)
        stop.lines = infos.lines

        // Define line as PMR only if ONE stop is PMR
        if (stop.pmr) {
          line.pmr = true
        }
      }

      line.stops = response.data

      if (line.cat !== 'TAD-markers-only') {
        try {
          const polylines = await displayLinePath(line, map)

          map.setState({
            polylines,
            infoboxsTerminus: [],
            terminus: false
          }, () => REACT_APP_TYPE === 'tcl' && displayLineTerminus(line, map))

          if (linesTab) {
            // avoid double
            !selectedLines.find(selectLine => selectLine.id === line.id) && selectedLines.push(line)

            return {
              currentLine: line,
              stopsList: response.data,
              selectedLines
            }
          } else {
            return {
              currentLine: line,
              stopsList: response.data
            }
          }
        } catch (e) {
          console.log(e)
          console.log('Impossible to get geojson for line ', line.name)
          line.errorPath = true

          return {
            currentLine: line,
            stopsList: response.data,
            selectedLines
          }
        } finally {
          // Resize the panel
          setTimeout(() => resize(map.props.isMobile))
        }
      } else {
        return {
          currentLine: line,
          stopsList: response.data,
          selectedLines
        }
      }
    })
    .catch(error => {
      console.warn('Unable to find stops/' + line.code + '_' + line.network + '_' + line.direction_id + '.json', error)
    })
}

/**
 * Update map state events
 * @param map
 * @param event
 * @param callback
 */
export const updateMapEvents = (map, event, callback) => {
  if (!map) {
    return
  }

  map.setState(state => ({
    events: {
      ...state.events,
      [event]: callback
    }
  }))
}

// --------------------------- PRIVATE --------------------------- //

/**
 * Avoid element to trigger Google maps click events
 * @param ref
 */
const avoidMapClick = ref => {
  google.maps.OverlayView.preventMapHitsFrom(ref)
}

/**
 *
 * @param component
 * @param groups
 * @param index
 */
const collapseGroup = (component, groups, index) => {
  const openedGroups = component.state[groups].map(g => {
    // TODO type (group or place)
    if (g.group === index || g.place === index) {
      g.opened = !g.opened
    } else {
      g.opened = false
    }

    return g
  })

  component.setState({
    [groups]: openedGroups
  })
}

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return function () {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, arguments)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(this, arguments)
    }
  }
}

/**
 * Launch api call if user don't use his keyboard for 500ms while user fill autocomplete input
 * @type {Function}
 */
const debounceRequest = debounce(async (inputValue, component, input, variant) => {
  const response = await axios({
    url: '/api/autocomplete',
    params: {
      type: variant || input,
      query: inputValue,
      data: component.props.data
    }
  })

  if (response.data) {
    const state = {}

    if (response.data.length === 1 && response.data[0].id === 'no_result') {
      response.data = []
    }

    if (input === 'inputTowns') {
      state[input + 'Data'] = response.data
    } else if (input === 'inputPlaces' || input === 'inputLines') {
      state[input + 'Data'] = component.state[input + 'Data'].filter(address => address.id === 'favorite')
        .concat(response.data)
    } else {
      state[input + 'Data'] = component.state[input + 'Data'].filter(
        address => address.id === 'geoloc' || address.id === 'favorite').concat(response.data)
    }
    component.setState(state)
  }
}, 500)

const displayLineTerminus = (line, map) => {
  const infoboxsTerminus = []

  if (!map.state.terminus) {
    for (const stop of line.stops) {
      if (!stop.terminus) {
        continue
      }

      const marker = {
        ...stop,
        id: 'terminus-' + stop.id,
        offset: {
          x: 0,
          y: 0
        }
      }

      const options = {
        closeBoxURL: '',
        enableEventPropagation: true,
        disableAutoPan: true,
        pixelOffset: {
          width: 10 + (marker.offset ? marker.offset.x : 0),
          height: -20 + (marker.offset ? marker.offset.y : 0)
        },
        zIndex: 1
      }

      infoboxsTerminus.push(
        <InfoBox key={marker.id + '_infobox_terminus'}
          defaultPosition={new google.maps.LatLng(marker.coord.lat, marker.coord.lon)} options={options}>
          <div className='infobox' style={{ background: '#' + line.color }}>
            <div className='infoTitle' style={{ padding: '5px 10px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                }}>
                {marker.name}
                {marker.pmr && REACT_APP_TYPE !== 'tcl' && <div className='pmr' />}
              </div>
            </div>
          </div>
        </InfoBox>
      )
    }
  }

  map.setState({
    terminus: !map.state.terminus,
    infoboxsTerminus
  }/*,
   () => {
   setTimeout(() => {
   const terminusText = document.querySelector(".terminus");
   terminusText && (terminusText.innerHTML = map.state.terminus
   ? "Cacher les terminus"
   : "Afficher les terminus");
   });
   }, */)
}

/**
 * Display a timetable for a stop and a specific line
 * @param component
 * @param stop
 * @param line
 * @param date
 */
export const displayTimeTable = (component, stop, line, date) => {
  // 0: morning, 1: afternoon, 2: evening selected by defaut with the hour if today
  const isToday = new Date().toISOString().split('T')[0].replace(/-/g, '') === date
  const nowHours = Math.floor(new Date().getHours())
  let slideIndex = isToday ? nowHours >= 4 && nowHours <= 11 ? 0 : nowHours >= 12 && nowHours <= 19 ? 1 : 2 : 0

  // get stop id of line if we have a stop_area
  if (stop.id.includes('stop_area')) {
    stop = stop.lines.find(l => l.id === line.id)
  }

  component.setState({
    timetable: true,
    loadingTimetable: true,
    timetableStop: stop.name
  }, async () => {
    const params = {
      stop: stop.stop_id || stop.id,
      line: line.id,
      idfm: true,
      timetable: true
    }

    const nightline = REACT_APP_TYPE === 'tcl' && component.state.currentLine.mode === 'pleine lune'
    if (date) {
      if (nightline) {
        params.date = date + 'T000000'
        slideIndex = 0 // There is only one slide for the night lines
      } else {
        params.date = date + 'T040000'
      }
    }

    const response = await axios({
      url: '/api/schedules',
      params
    })

    const timetableData = {
      morning: [],
      afternoon: [],
      evening: []
    }

    for (const time of response.data) {
      const departure = parseInt(time.stop_date_time.departure_date_time.substring(9, 11))

      if (departure >= 4 && departure <= 11) {
        timetableData.morning.push(time)
      } else if (departure > 11 && departure <= 19) {
        timetableData.afternoon.push(time)
      } else if (departure >= 20 || departure < 4) {
        timetableData.evening.push(time)
      }
    }

    if (timetableDataIsEmpty(timetableData)) { // fix crash
      slideIndex = 0
    }
    component.setState({
      slideIndex,
      timetableData,
      loadingTimetable: false
    }, () => {
      const divs = Array.from(document.querySelectorAll('.scroll'))
      resize(component.props.map.props.isMobile, divs[slideIndex])
    })
  })
}

/**
 * google maps points to jsts
 * @param coord
 * @returns {Array}
 */
const googleMaps2JSTS = coord => {
  const coordinates = []

  for (let i = 0; i < coord.length; i++) {
    coordinates.push(new jsts.geom.Coordinate(coord[i].lat, coord[i].lng))
  }

  return coordinates
}

/**
 * jsts to google map points
 * @param geometry
 * @returns {Array}
 */
const jsts2googleMaps = geometry => {
  const coordArray = geometry.getCoordinates()
  const coordinates = []

  for (let i = 0; i < coordArray.length; i++) {
    coordinates.push(new google.maps.LatLng(coordArray[i].x, coordArray[i].y))
  }

  return coordinates
}

export const noSchedulesForType = (component, stop, line, type) => {
  const { stopsList } = component.state

  if (line.mode === 'tad' || line.mode === 'rer') {
    return
  }

  // Retrieve element and scroll to it
  const selected = stop.id.includes('stop_area')
    ? stopsList.find(s => s.stop_area === stop.id)
    : stopsList.find(s => s.stop_area === stop.stop_area)

  // Select the stop
  setTimeout(() => displayStopOnScrollToIt(component, stop, line, selected))

  for (const s of stopsList) {
    s.opened = s.id === stop.id

    if (s.id === stop.id) {
      stop.opened = true
      stop[type] = true
    }
  }

  component.setState({ stopsList })
}

/**
 * Convert Navitia data to readable time
 * @param date
 * @returns {string|*}
 */
/* const navitiaToDate = date => {
 let time = date.split("T")[1];
 time = time.substring(0, 2) + "h" + time.substring(2, 4);
 return time;
 }; */

/**
 * Handle marker click
 * @param marker
 * @param place
 * @param component
 */
const onMarkerClick = (component, marker, place) => {
  const { history } = component.props

  if ((history || window).location.pathname.includes('route-calculation')) {
    return
  }

  if (place && component.state.selectedPlace && marker.id === `place-${component.state.selectedPlace.id}`) {
    return
  }

  if (place) {
    const path = history.location.pathname
    const search = history.location.search

    if (path.includes('places-interest')) {
      component.onPlaceMarkerClick(place)
    } else if (path.includes('hiking-routes')) {
      if (search.includes('route=')) {
        component.onRoutePlaceSelected(place)
      } else {
        component.onRouteSelected(place)
      }
    } else if (path.includes('lines')) {
      renderInfobox(component, marker, true)
    }

    return
  }

  // If we have already selected a line
  if (component.state.currentLine) {
    // Avoid double schedules at load
    if (!history.location.search.includes(marker.id)) {
      schedules(component, marker, component.state.currentLine, null, true)
    }
  }

  renderInfobox(component, marker, true)
}

/**
 * Handle marker mouse over
 * @param component
 * @param marker
 */
const onMarkerMouseOver = (component, marker) => {
  if (!marker.name) {
    return
  }

  renderInfobox(component, marker)
}

/**
 * Handle marker mouse out
 * @param component
 */
const onMarkerMouseOut = component => {
  let { map } = component.props

  if (!map) {
    map = component
  }

  map.setState({
    infoboxs: map.state.selectedInfobox ? [map.state.selectedInfobox] : []
  })
}

/**
 * Switch a line direction
 * @param line
 * @returns Object
 */
const onSwitchedDirection = line => {
  line.direction_id = parseInt(line.direction_id) === 0 ? 1 : 0
  return line
}

/**
 * Retrieve all connections for a stop
 * @param component
 * @param stop
 * @param selectedLine
 * @returns {any[]}
 */
const retrieveConnections = (component, stop, selectedLine) => {
  return stop.lines.filter(line => line.id !== selectedLine.id).map(line => {
    line = getLine(component, line)

    return <div key={line.id} className='connection'
      style={{ background: '#' + line.color, color: luminance(line.color) > 0.5 ? '#333' : '#fff' }}>{line.name}</div>
  })
}

// ZOOM FIT BOUNDS v2
const TILE_SIZE = {
  height: 256,
  width: 256
} // google World tile size, as of v3.22
const ZOOM_MAX = 16 // max google maps zoom level, as of v3.22
const mapDimensions = {}
const updateMapDimensions = () => {
  mapDimensions.height = document.querySelector('.map').offsetHeight
  mapDimensions.width = document.querySelector('.map').offsetWidth
}
const getBoundsZoomLevel = (bounds, dimensions) => {
  const latRadian = lat => {
    let sin = Math.sin((lat * Math.PI) / 180)
    let radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
  }
  const zoom = (mapPx, worldPx, fraction) => {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
  }
  const ne = bounds.getNorthEast()
  const sw = bounds.getSouthWest()
  const latFraction = (latRadian(ne.lat()) - latRadian(sw.lat())) / Math.PI
  const lngDiff = ne.lng() - sw.lng()
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360
  const latZoom = zoom(dimensions.height, TILE_SIZE.height, latFraction)
  const lngZoom = zoom(dimensions.width, TILE_SIZE.width, lngFraction)
  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}
const getBounds = locations => {
  let northeastLat
  let northeastLong
  let southwestLat
  let southwestLong
  locations.forEach(function (location) {
    if (!northeastLat) {
      northeastLat = southwestLat = location.lat
      southwestLong = northeastLong = location.lng
      return
    }
    if (location.lat > northeastLat) {
      northeastLat = location.lat
    } else if (location.lat < southwestLat) {
      southwestLat = location.lat
    }
    if (location.lng < northeastLong) {
      northeastLong = location.lng
    } else if (location.lng > southwestLong) {
      southwestLong = location.lng
    }
  })
  const northeast = new google.maps.LatLng(northeastLat, northeastLong)
  const southwest = new google.maps.LatLng(southwestLat, southwestLong)
  const bounds = new google.maps.LatLngBounds()
  bounds.extend(northeast)
  bounds.extend(southwest)
  return bounds
}
const setMapBounds = (map, locations, offset) => {
  updateMapDimensions()
  const bounds = getBounds(locations)
  const dimensions = {
    width: mapDimensions.width,
    height: mapDimensions.height
  }
  const zoomLevel = getBoundsZoomLevel(bounds, dimensions)
  // map.setZoom(zoomLevel);
  map.setState({
    zoom: zoomLevel
  })
  setOffsetCenter(map, bounds.getCenter(), offset)
}
const offsetLatLng = (map, latlng, offset) => {
  const scale = Math.pow(2, map.mapReference.current.getZoom())
  const point = map.mapReference.current.getProjection().fromLatLngToPoint(latlng)
  const pixelOffset = new google.maps.Point(offset.x / 2 / scale, offset.y / scale)
  const newPoint = new google.maps.Point(point.x - pixelOffset.x, point.y + pixelOffset.y)
  return map.mapReference.current.getProjection().fromPointToLatLng(newPoint)
}
const setOffsetCenter = (map, latlng, offset) => {
  const newCenterLatLng = offsetLatLng(map, latlng, offset)
  map.mapReference.current.panTo(newCenterLatLng)
}
