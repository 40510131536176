import React, { Component } from 'react'

const { REACT_APP_MAP_TYPE } = process.env

const { BaseMap } = REACT_APP_MAP_TYPE === 'google' ? require('./Map/Google') : require('./Map/Leaflet')

class Map extends Component {
  render () {
    return <BaseMap {...this.props} />
  }
}

export default Map
