import axios from 'axios'
import { luminance } from 'luminance-js'
import moment from 'moment'
import 'moment/locale/fr'
import Snackbar from 'node-snackbar'
import React, { Component, Fragment } from 'react'
import { Collapse } from 'react-collapse'
import { Circle, Marker, Polygon, Polyline, Popup, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import Moment from 'react-moment'
import { DateCalendar } from 'react-picker-date-and-time'
import ReactToPrint from 'react-to-print'
import PolylineDecorator from './PolylineDecorator'
import {
  addTimetableContent,
  createTimetable,
  geocodPlace,
  getModeNameByGroup,
  groupLinesByMode,
  navitiaToHourMin,
  resize,
  sortAlphabetic,
  sortBy,
  substringCoords,
  timetableDataIsEmpty,
  unique,
  updateURLState
} from './tools'
import { getDestinations } from '../google/tools'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox')
const jsts = require('jsts')

console.log('map.js for Leaflet')

const { REACT_APP_TYPE } = process.env
const favDev = {
  text: 'Favoris en cours de développement',
  actionText: 'Fermer',
  backgroundColor: '#e2001a',
  actionTextColor: '#333'
}

moment.updateLocale('fr', {
  relativeTime: {
    mm: '%d min',
    m: '1 min',
    ss: '1 min',
    s: '1 min'
  }
})

class TimeTable extends Component {
  render () {
    const component = this.props.component
    const currentLine = component.state.currentLine
    const date = component.state.url.date && component.state.url.date.substring(0, 4) + ' ' +
      component.state.url.date.substring(4, 6) + ' ' + component.state.url.date.substring(6, 8)

    return <div>
      <div className='timetable timetableSlide onlyPrint'>
        <div className='bold timetableTitle'> Fiche horaire du <Moment format='dddd DD MMMM YYYY'>{date}</Moment></div>
        <div className='timetableInfo'>
          {REACT_APP_TYPE === 'tcl' ? <div className='tclLine'>
            <img src={'assets/images/lines/' + currentLine.code + '.svg'} alt='bus icon' />
          </div> : <span className='lineCode' style={{
            background: '#' + currentLine.color,
            color: luminance(currentLine.color) > 0.5 ? '#333' : '#fff'
          }}>{currentLine.name}</span>}
          <div className='timetableLine'>{currentLine.mode.toLowerCase() !== 'rer'
            ? <span className='direction'>
              <div className='timetableDirection'><span
                className='lightBold'>{currentLine.routes[currentLine.direction_id === '0' ? 1 : 0] &&
              currentLine.routes[currentLine.direction_id === '0' ? 1 : 0].name}</span></div>
              <div className='timetableDirection'><span
                className='lightBold'>{currentLine.routes[currentLine.direction_id] &&
              currentLine.routes[currentLine.direction_id].name}</span></div>
            </span> : <span className='direction rer'>Ligne de RER</span>}
          </div>
        </div>
        <div className='timetableStopDirection'>
          <div className='timetableStop'>Arrêt : <span className='lowBold'>{component.state.timetableStop}</span></div>
          <div className='timetableDirection'>Direction : <span
            className='lowBold'>{currentLine.routes[currentLine.direction_id].name}</span></div>
        </div>
        <div className='timetableContent'>{!component.state.loadingTimetable &&
        addTimetableContent(component, true)}</div>
        <div className='otherDirections'>
          <div>{getDestinations(component.state.timetableData,
            component.state.currentLine.routes[currentLine.direction_id].name, true)}</div>
        </div>
      </div>
      <div className='timetable printHide'>
        {component.state.loadingTimetable ? <img src='assets/images/loading.gif' width={30}
          alt='Loading' /> : createTimetable(component, false)}
      </div>
    </div>
  };
}

/**
 * Retrieve all transport information around a position
 * @param component
 * @param position
 */
export const around = async (component, position) => {
  const { map, url } = component.props
  let refCircle

  try {
    const response = await axios({
      url: '/api/around',
      params: {
        lat: position[0],
        lng: position[1],
        navitia: REACT_APP_TYPE === 'tcl'
      }
    })

    // Remove current line
    removeLine(component)

    // Pin marker
    const pin = <Marker
      draggable
      onDragstart={component.removePinCircle}
      onDragend={event => {
        let newUrl = url.pathname
        newUrl += '?from=' + substringCoords(event.target.getLatLng())
        component.props.history.push(newUrl)
      }}
      options={{ zIndex: 999 }}
      icon={L.icon({
        iconUrl: 'assets/images/pin.svg',
        iconSize: [50, 50],
        iconAnchor: [25, 25]
      })}
      position={position}
    />

    const circle = <Circle
      center={position}
      ref={r => (refCircle = r)}
      radius={750}
      fillColor='rgba(0, 0, 0)'
      fillOpacity='0.15'
      color='transparent'
    />

    const lines = response.data.shift()
    const groups = groupLinesByMode(unique(lines, 'id'), 'mode') // Make the array unique by the lines ID and order
    // them by group
    const openedGroups = Object.keys(groups).map((group, index) => ({
      group,
      opened: index === 0
    }))

    const dataPlaces = response.data.shift()
    const places = Object.keys(dataPlaces)
      .sort(function (a, b) {
        return a.localeCompare(b)
      })
      .reduce(function (sorted, key) {
        sorted[key] = dataPlaces[key]
        return sorted
      }, {})

    const openedPlacesGroups = Object.keys(places).map(place => ({
      place,
      opened: false
    }))

    component.setState({
      groups,
      openedGroups,
      places,
      openedPlacesGroups
    }, () => {
      const markers = map.state.markers
      const path = window.location.pathname

      const state = {
        pin: path.includes('around') ? pin : null,
        circle,
        markers: [...markers],
        selectedInfobox: null,
        infoboxs: []
      }

      map.setState({ ...state }, async () => {
        refCircle && fitBounds(map, null, refCircle.leafletElement.getBounds())
        resize(map.props.isMobile)

        if (path.includes('around')) {
          const data = component.state.inputAroundPlace || await geocodPlace(position)
          if (data.id !== 'no_places') {
            onSelectAutocompleteInput(data, component, 'inputAround')
          } else {
            component.setState({ groups: null, warning: 'Aucune adresse trouvée à cette position' })
          }
        }

        // Scroll to the top of the content if we are on mobile
        if (map.props.isMobile) {
          setTimeout(() => {
            const element = document.querySelector('.board')

            element && (element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop)
          }, 250)
        }
      })
    })
  } catch (e) {
    throw e
  }
}

export const displayLineDecorator = (map, polyline, section) => {
  return <PolylineDecorator key={section.id + '_decorator'} section={section} path={polyline.props.positions} />
}

/**
 * Display polylines for a line
 * @param line
 * @param map
 * @param args
 */
export const displayLinePath = async (line, map, ...args) => {
  const [isSection, main, index, component] = args
  const infos = isSection ? line.display_informations : null

  // TODO waiting sections
  if (isSection && line.type === 'waiting') {
    return
  }

  try {
    if (!isSection) {
      await axios.get(
        '/api/file?folder=routes&ext=geojson&name=' + line.code + '_' + line.network + '_' + line.direction_id)
        .then(result => {
          line.geojson = result.data
        }).catch(() => {
          line.geojson = null
        })
    }

    // Switch on location to change target if needed
    const path = window.location.pathname

    // Save others polylines not from the line selected
    let oldPolylines = path.includes('lines') ? map.state.polylines.filter(
      oldPolyline => line.code !== oldPolyline.key.split('_').shift()) : []
    const newPolylines = []
    const cloneOptions = {
      color: '#fff',
      opacity: 1,
      weight: 8,
      zIndex: 6,
      main
    }

    /* if (!main) {
      cloneOptions.color = '#aaa'
      cloneOptions.zIndex = 4
      cloneOptions.weight = 6
    } */

    const lineOptions = {
      color: '#' + (infos ? infos.color : line.color),
      opacity: 1,
      weight: 6,
      zIndex: 7,
      main
    }

    // Street network or transfer, black dashed
    if (isSection && (line.type === 'street_network' || line.type === 'transfer')) {
      lineOptions.color = '#333'
      lineOptions.opacity = 1
      lineOptions.weight = 4
      lineOptions.dashArray = '5 12'
    }

    if (isSection && !main) {
      lineOptions.color = '#32B8C9'
      lineOptions.opacity = 0.6
      lineOptions.zIndex = 5
      lineOptions.weight = 4
    }

    let polyline, clone
    if (line.geojson) {
      if (!isSection) {
        let index = 0
        for (const feature of line.geojson.features) {
          let zoom = feature.properties.zoom
          const length = feature.geometry.coordinates.length
          const path = length === 1 ? getCoordinates(feature).pop() : getCoordinates(feature)

          if (REACT_APP_TYPE !== 'tcl') {
            // Push clone
            clone = <Polyline
              key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '') + '_clone_' + index}
              positions={path}
              zoom={zoom}
              {...cloneOptions}
            />
            newPolylines.push(clone)
          }

          // Push line
          polyline = <Polyline
            key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '') + '_' + index}
            positions={path}
            zoom={zoom}
            {...lineOptions}
          />
          newPolylines.push(polyline)

          index++
        }
      } else {
        const path = getCoordinates(line.geojson).pop()

        if (REACT_APP_TYPE !== 'tcl') {
          // Push clone
          clone = <Polyline
            key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '') + '_clone'}
            positions={path}
            {...cloneOptions}
          />
          newPolylines.push(clone)
        }
        // Push line
        polyline = <Polyline
          ref={poly => !main && poly && poly.leafletElement.bringToBack()}
          key={(isSection ? line.id : line.code) + (infos ? '_' + infos.code : '')}
          positions={path}
          {...lineOptions}
          onMouseOver={() => { component.displayJourneys(component.state.journeys, map, index) }}
          onClick={() => {
            component.props.history.push(
              component.props.url.pathname + component.props.url.search + '&journey=' + index)
          }}
        />

        newPolylines.push(polyline)
      }
    }

    if (isSection) {
      return newPolylines
    }

    // Concat selected lines with old lines
    oldPolylines = oldPolylines.concat(newPolylines)

    return oldPolylines
  } catch (error) {
    throw error
  }
}

/**
 * Display RER
 * @param component
 * @returns {Promise<void>}
 */
export const displayRER = async component => {
  const { map } = component.props

  const markersRER = []
  const response = await axios.get('/api/file?name=areas')
  const markers = response.data.filter(stop => {
    for (const line of stop.lines) {
      const info = getLine(component, line)

      if (info.mode === 'rer') {
        return stop
      }
    }
    return false
  })

  // Display the marker only if is inside the polygon
  for (const marker of markers) {
    // TODO LEAFLET OPTIMIZE EDGE
    const geometryFactory = new jsts.geom.GeometryFactory()
    const jstsPolygone = geometryFactory.createPolygon(
      geometryFactory.createLinearRing(leaflet2JSTS(map.state.polygons[0].props.positions)))
    jstsPolygone.normalize()
    const reader = new jsts.io.WKTReader()

    const inside = containsLocation(marker, jstsPolygone, reader)
    const isEdge = edgeLocation(reader.read('POINT(' + marker.coord.lat + ' ' + marker.coord.lon + ')'), jstsPolygone,
      inside, reader)
    if (inside || isEdge) {
      markersRER.push(
        renderMarker(component, marker, {
          icon: L.icon({
            iconUrl: 'assets/images/rer.svg',
            iconSize: [20, 20],
            iconAnchor: [10, 10]
          }),
          marker,
          zIndex: 30
        }))
    }
  }

  // Add RER polylines
  const c = {
    id: 'line:0:800:C',
    direction_id: 0
  }
  const d = {
    id: 'line:0:800:D',
    direction_id: 0
  }
  const polylines = []

  for (const rer of [c, d]) {
    const line = getLine(component, rer)

    if (!line.code) {
      return
    }

    const polyline = await displayLinePath(line, map)
    polylines.push(polyline)
  }

  map.setState({
    markersRER,
    polylines
  })
}

/**
 * Display places in toDisplay array in base map
 * @param component
 * @param toDisplay
 */
export const displayBaseMapPlaces = (component, toDisplay) => {
  const { map } = component.props
  const markers = []

  axios.get(REACT_APP_TYPE === 'tcl' ? '/api/file?name=places' : '/api/places-data?data=places').then(result => {
    for (const place of result.data) {
      if (toDisplay.includes(place.cat)) {
        const code = place.code.split('_')[0]
        const marker = {
          id: place.id,
          coord: place.coord,
          name: place.name,
          code
        }
        const markerRendered = renderMarker(component, marker, {
          icon: L.icon({
            iconUrl: 'assets/images/places/' + code + '.svg',
            iconSize: [50, 50],
            iconAnchor: [25, 18]
          }),
          marker,
          zIndex: 30
        })
        markers.push(markerRendered)
      }
    }

    map.setState({ markersMap: markers })
  })
}

export const displayTCLHeavyLines = async (component) => {
  const { map } = component.props

  const lines = ['326', 'T1A', 'T2', 'T3', 'T4A', 'T5', '301A', '302A', '303', '304']
  const polylines = []

  for (const line of lines) {
    const data = getLine(component, {
      id: 'line:tcl:' + line,
      direction_id: 0
    })

    if (data.code) {
      const polyline = await displayLinePath(data, map)
      polylines.push(...polyline)
    }
  }

  polylines.length > 0 && map.setState({ polylines }, () => fitBounds(map, polylines))
}

/**
 * Display outline territory
 * @param component
 */
export const displayTerritoryOutline = component => {
  const { map, theme } = component.props
  const color = theme.colors['primary']

  axios.get('/api/file?name=towns').then(response => {
    let unionPoly = null
    for (const town of response.data) {
      const geometryFactory = new jsts.geom.GeometryFactory()
      const jstsPolygone = geometryFactory.createPolygon(
        geometryFactory.createLinearRing(leaflet2JSTS(invertCoords(town))))
      jstsPolygone.normalize()

      if (!unionPoly) {
        unionPoly = jstsPolygone
      } else {
        unionPoly = jstsPolygone.union(unionPoly)
      }
    }

    // TODO merge polygons
    const polygons = [
      <Polygon
        weight={1.5}
        color={'#' + color}
        fillOpacity={0}
        strokeOpacity={0.7}
        key={'outline-polygon'}
        positions={jsts2Leaflet(unionPoly)}
      />
    ]

    if (window.location.pathname !== '/towns' && color) {
      map.setState({ polygons }, () => {
        // Avoid zoom on bounds under certain conditions
        if (!window.location.search) {
          // TODO LEAFLET FIT BOUNDS
          // fitBounds(map, polygons)

          if (window.location.pathname === '/') {
            // TODO up in tools factory
            const test = 'activate_RER_lines_options'
            if (test === true) {
              displayRER(component)
            }
          }
        }
      })
    }
  })
}

/**
 * Fit the bound of map with given objects (Polylines or Markers)
 * @param map
 * @param objects
 * @param bounds
 */
export const fitBounds = (map, objects, bounds = L.latLngBounds()) => {
  if (objects) {
    for (const object of objects) {
      if (object.type) {
        switch (object.type.render.displayName) {
          case 'Leaflet(Polyline)':
            if (object.props.zoom === 0) {
              break
            } else {
              bounds.extend(object.props.positions)
              break
            }
          case 'Leaflet(Polygon)':
            // TODO Use the Polyline.getPath()
            for (const path of object.props.positions) {
              bounds.extend(path)
            }
            break
          case 'Leaflet(Marker)':
            bounds.extend(object.props.position)
            break
          case 'Leaflet(Circle)':
            bounds.extend(object.props.center)
            break
          case 'InfoBox':
            // TODO ? Add a fuckin offset to infobox padding, or else the map won't fitBounds correctly if there is
            // only one object
            const position = object.props.defaultPosition
            bounds.extend({
              lat: position.lat(),
              lng: position.lng() - 0.001
            })
            break
          default:
            console.warn('You shall not pass')
            break
        }
      } else {
        bounds.extend(object)
      }
    }

    // Mobi'Val & maybe another shit ? No objects ? Zoom on map polygons ...
    if (objects.length === 0) {
      setTimeout(() => fitBounds(map, map.state.polygons))
    }
  }

  let topLeft = L.point(460, 50)
  let bottomRight = L.point(0, 50)

  if (map.props.isMobile) {
    const popup = document.querySelector('.leaflet-popup')
    topLeft = L.point(popup ? -popup.offsetWidth : 0, popup ? -popup.offsetHeight / 2 : 0)
    bottomRight = L.point(0, document.querySelector('#app').scrollTop + 200)
  }

  // Fit the map component with the calculated bounds
  if (bounds.isValid()) {
    map.setState({
      bounds,
      boundsOptions: {
        paddingTopLeft: topLeft,
        paddingBottomRight: bottomRight
      }
    })
  }
}

/**
 *
 * @param feature
 * @returns {Array}
 */
export const getCoordinates = feature => {
  const paths = []

  let type = feature.type

  if (!type || type.includes('Feature')) {
    feature = feature.geometry
    type = feature.type
  }

  switch (type) {
    case 'LineString':
      const linePath = []
      for (const coord of feature.coordinates) {
        linePath.push([coord[1], coord[0]])
      }
      paths.push(linePath)
      break

    case 'MultiLineString':
      for (const line of feature.coordinates) {
        const linePath = []
        for (const coord of line) {
          linePath.push([coord[1], coord[0]])
        }
        paths.push(linePath)
      }
      break

    default:
      console.warn('Geometry type not found')
  }

  return paths
}

/**
 * Retrieve a line object from it's id
 * @returns Object
 * @param component
 * @param object
 */
export const getLine = (component, object) => {
  const { lines } = component.props

  return {
    ...lines.find(line => line.id === object.id),
    ...object
  }
}

export const goRouteCalculation = (component, object) => {
  const { map, history } = component.props
  const url = `/route-calculation?to=${object}`

  history.push(url)
  map.setState({
    markers: [],
    markersPlaces: [],
    infoboxs: []
  })
}

/**
 * Retrieve a stop object from it's id
 * @returns Object
 * @param component
 * @param object
 * @param line
 */
export const getStop = (component, object, line) => {
  const { areas, stops } = component.props

  let res = object.id
  if (object.id.includes('stop_area')) {
    res = areas.find(a => a.id === object.id).lines.find(l => l.id === line.id).stop_id
  }

  return {
    ...stops.find(s => s.id === res),
    ...object
  }
}

/**
 * Remove pin link to the input when typing
 * @param component
 * @param input
 */
export const hidePinWhileAutocomplete = (component, input) => {
  const { map } = component.props

  component.setState({
    [input + 'Data']: component.state[input + 'Data'].filter(
      address => address.id === 'geoloc' || address.id === 'favorite'
    )
  }, () => {
    if (map.state.pin) {
      map.setState({
        pin: null,
        circle: null
      })
    }
  })
}

/**
 * Invert coord
 * @param array
 * @returns {*}
 */
export const invertCoords = array => {
  return array.geometry.coordinates[0].map(coord => [coord[1], coord[0]])
}

export const isNightLine = (timetableData) => {
  let periods = [
    'morning',
    'afternoon',
    'evening'
  ]
  for (const period of periods) {
    for (let time of timetableData[period]) {
      let timeHour = parseInt(time.stop_date_time.departure_date_time.substring(9, 11))
      if (timeHour > 5 && timeHour < 24) { // a nightline can only work from midnight to 5am
        return false
      }
    }
  }
  return true
}

/**
 * While user using an autocomplete input :
 * remove the pin
 * display chars in input
 * launch debounce function
 * @param e
 * @param component
 * @param input
 * @param variant
 */
export const onChangeAutocompleteInput = (e, component, input, variant = null) => {
  const inputValue = e.target.value
  const map = component.props.map

  component.setState({
    [input + 'Value']: inputValue
  }, () => {
    if (input !== 'inputPlaces' && input !== 'inputTowns') {
      hidePinWhileAutocomplete(component, input, map)
    }

    debounceRequest(inputValue, component, input, variant)
  })
}

/**
 * Select the item selected by user in autocomplete and put pin on it
 * @param dataInputSelect
 * @param component
 * @param input
 */
export const onSelectAutocompleteInput = (dataInputSelect, component, input) => {
  // let coord = {}

  try {
    // const type = dataInputSelect.embedded_type
    // coord.latLng = new L.LatLng(dataInputSelect[type].coord.lat, dataInputSelect[type].coord.lon)
    component.setState({
      [input + 'Value']: dataInputSelect.label || dataInputSelect.name,
      [input + 'Data']: component.state[input + 'Data'].filter(
        address => address.id === 'geoloc' || address.id === 'favorite')
    })
  } catch (e) {
    console.warn(e.message)
    component.setState({
      [input + 'Value']: '',
      [input + 'Data']: []
    })
  }
}

/**
 * Handle zoom changed on map
 * @param component
 * @param path
 */
export const onZoomChanged = (component, path) => {
  const { map } = component.props
  const zoom = map.mapReference.current.leafletElement.getZoom()
  const markersMapLength = map.state.markersMap.length
  if (zoom > 15 && markersMapLength === 0) {
    displayBaseMapPlaces(component, ['Covoiturage'])
  } else if (zoom <= 15 && markersMapLength > 0) {
    map.setState({ markersMap: [] })
  }

  // Avoid render markers on certain conditions
  if (path && ((path.includes('places-interest') && !component.state.selectedPlace) ||
    (path.includes('towns') && !component.state.town))) {
    return
  }

  if (path) {
    map.setState({ zoom }, () => {
      const { status, zoom } = map.state

      // TODO Leaflet cluster
      if (zoom < 14 && status !== 'cluster') {
        map.setState({
          status: 'cluster',
          markers: [],
          selectedInfobox: null,
          infoboxs: []
        }, () => {
          if (path.includes('towns')) {
            setTimeout(() => {
              renderMarkers(component, true)
            }, 550)
          } else {
            renderMarkers(component, true)
          }
        })
      } else if (zoom >= 14 && zoom < 17 && status !== 'logical') {
        map.setState({
          status: 'logical',
          markers: [],
          selectedInfobox: null,
          infoboxs: []
        }, () => renderMarkers(component, true))
      } else if (zoom >= 17 && status !== 'physical') {
        map.setState({
          status: 'physical',
          markers: [],
          selectedInfobox: null,
          infoboxs: []
        }, () => renderMarkers(component))
      }
    })
  }
}

/**
 * Remove the current line from a given component
 * @param component
 * @param line
 * @param lineList
 */
export const removeLine = (component, line = null, lineList = false) => {
  const { map } = component.props

  map.setState({
    terminus: false,
    infoboxsTerminus: [],
    polylineDecorators: []
  })

  if (!line) {
    map.setState({
      selectedInfobox: null,
      infoboxs: [],
      polylines: []
    })

    component.setState({
      currentLine: null
    })
  } else {
    const { url } = component.state

    if (window.location.pathname !== '/lines') {
      component.props.history.push(
        component.props.url.pathname + component.props.url.search.replace('&line=' + url.line, ''))
    } else {
      const { currentLine, selectedLines } = component.state

      if (url.current === line.id + '_' + line.direction_id) {
        if (url.selected) {
          const newSelectedLines = selectedLines.filter(selectLine => selectLine.id !== line.id)
          const newCurrent = newSelectedLines[0]

          const current = newCurrent.id + '_' + newCurrent.direction_id
          let selection = ''
          for (const stateLine of newSelectedLines) {
            if (stateLine.id !== newCurrent.id || lineList) {
              selection += stateLine.id + '_' + stateLine.direction_id + ','
            }
          }

          if (lineList) {
            component.props.history.push(component.props.url.pathname + '?selected=' + selection)
          } else {
            component.props.history.push(component.props.url.pathname + '?current=' + current + '&selected=' + selection
            )
          }
        } else {
          component.props.history.push(component.props.url.pathname)
        }
      } else {
        let current = null

        if (currentLine) {
          current = currentLine.id + '_' + currentLine.direction_id
        }

        let selection = current ? '&selected=' : '?selected='
        const linesSelectedToKeep = selectedLines.filter(selectLine => selectLine.id !== line.id)

        if ((linesSelectedToKeep.length === 1 && current) ||
          linesSelectedToKeep.length === 0) {
          selection = ''
        } else {
          for (const lineToKeep of linesSelectedToKeep) {
            if (current) {
              if (lineToKeep.id !== currentLine.id) {
                selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
              }
            } else {
              selection += lineToKeep.id + '_' + lineToKeep.direction_id + ','
            }
          }
        }

        if (!lineList && current) {
          component.props.history.push(component.props.url.pathname + '?current=' + current + selection)
        } else {
          component.props.history.push(component.props.url.pathname + selection)
        }
      }
    }
  }
}

/**
 * Remove map state event
 * @param map
 */
export const removeMapEvents = map => {
  if (!map) {
    return
  }

  map.setState({ events: {} })
}

/**
 * Render an infobox on a specific marker
 * If onLineSelected, the infobox will stay open onClick
 * @param component   used in board
 * @param stop
 * @param selected
 */
// TODO bug fitbounds
export const renderInfobox = (component, stop, selected) => {
  // Get lines by stop_area
  const area = stop.id.includes('stop_area') ? stop : getStop(component, { id: stop.id })

  // Display tooltip
  if (selected) {
    stop.ref && (stop.ref.clicked = true)

    /* if (component.state.markerRef && component.state.markerRef !== stop.ref) {
      component.state.markerRef.leafletElement.setTooltipContent(component.state.selectedMarker.name)
    } */

    component.setState({
      selectedMarker: stop
    })
  }

  return <div className='infobox'>
    <div className='infoTitle'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        {stop.name}
        {stop.pmr && REACT_APP_TYPE !== 'tcl' && <div className='pmr' />}
      </div>
      {selected && !stop.id.includes('TAD') &&
      (REACT_APP_TYPE === 'tcl'
        ? <span className='toolRouteCalculation toolSmall' onClick={() => {
          const coord = stop.coord.lon + ';' + stop.coord.lat
          stop.id.includes('stop') ? goRouteCalculation(component, stop.id) : goRouteCalculation(component, coord)
        }}><img src='assets/images/route-calculation.svg' alt='itinéraire' /></span>
        : <span className='toolRouteCalculation toolSmall' onClick={() => {
          const coord = stop.coord.lon + ';' + stop.coord.lat
          stop.id.includes('stop') ? goRouteCalculation(component, stop.id) : goRouteCalculation(component, coord)
        }} />)}
    </div>
    {area &&
    <div className='infoContent'>{renderLinesLabels(component, area.lines, 'infobox', stop)}</div>}
  </div>
}

/**
 * Render a line on a given coponent
 * @param component
 * @param line
 */
export const renderLine = (component, line) => {
  !line && (line = component.state.currentLine)

  const { stopsList } = component.state
  const { map, history } = component.props
  const onLineSelected = component.onLineSelected
  const { isMobile } = map.props
  const path = history.location.pathname

  if (line.cat === 'TAD-markers-only') {
    sortBy(stopsList, 'name')
  }

  const pickerStyle = {
    calendar: {
      bottom: map && map.props.isMobile ? '28%' : '',
      left: map && map.props.isMobile ? '0' : '',
      right: map && map.props.isMobile ? '0' : '',
      boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)'
    },
    colon: {
      padding: '0 5px 0 0 !important'
    },
    control: {
      boxShadow: 'none',
      cursor: 'pointer'
    },
    first: '#005e86',
    menu: {
      marginLeft: -5,
      position: 'fixed',
      bottom: map && map.props.isMobile ? '25%' : '',
      top: ''
    },
    weekDays: {
      padding: '5px 0'
    },
    monthSelected: {
      fontWeight: 600
    },
    calendarButtonStyle: {
      fontSize: '1em'
    },
    inputsHours: {
      fontSize: '1em'
    },
    today: {
      background: '#f4f4f4',
      color: '#333',
      fontWeight: '500'
    }
  }

  if (REACT_APP_TYPE === 'tcl') {
    pickerStyle.first = '#e2001a'
    pickerStyle.calendarButtonStyle = {
      ...pickerStyle.calendarButtonStyle,
      margin: 10,
      border: '2px solid rgba(0, 0, 0, 0.08)',
      borderRadius: 5,
      marginRight: 10
    }
    pickerStyle.calendar = {
      ...pickerStyle.calendar,
      padding: 15,
      background: '#f1f5f5'
    }
    pickerStyle.week = {
      ...pickerStyle.week,
      background: '#fff'
    }
  }

  // Color terminus tooltip
  // TODO find a better way :-)
  document.querySelectorAll('.tooltip-leaflet-terminus').forEach(div => {
    div.style.backgroundColor = '#' + line.color
    div.style.borderColor = '#' + line.color
    div.style.color = luminance(line.color) > 0.5 ? '#333' : '#fff'
  })

  return <div className={REACT_APP_TYPE === 'tcl' ? 'tcl-elevation' : ''}>
    {REACT_APP_TYPE !== 'tcl' && <Fragment>
      {path.includes('lines') && !component.state.timetable && <div className='leftLines'>
        {component.state.selectedLines.filter(line => line.id !== component.state.currentLine.id).map(line =>
          <div key={line.id}>
            <div className='leftLine' onClick={() => onLineSelected(line)}>
              <div className='lineLabel'>
                {line.mode.toLowerCase() !== 'tad' ? <span
                  className={'lineCode' + (line.name.length > 5 ? ' longLineCode' : '')}
                  style={line.mode === 'rer' ? {
                    backgroundColor: 'white',
                    color: '#' + line.color,
                    minWidth: 12,
                    border: '2px solid #' + line.color,
                    borderRadius: '50%',
                    fontWeight: 700,
                    flex: 0,
                    margin: '0 10px',
                    padding: '3px 5px'
                  } : {
                    background: '#' + line.color,
                    color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                  }}>{line.name}</span> : <div className='tad' />}
              </div>
              {line.mode.toLowerCase() !== 'rer' ? <span className='direction'>
                  Direction :<br />
                <span className='lightBold'>{line.routes[line.direction_id].name}</span>
              </span> : <span className='direction rer'>Ligne de RER</span>}
              <div className='tools'>
                {!component.state.timetable && <Fragment>
                  {line.routes.length > 1 &&
                  <span className='toolSwap' onClick={() => onLineSelected(onSwitchedDirection(line))} />}
                  <span className='toolTimetable' onClick={() => collapseTimetableOptions(component)} />
                </Fragment>}
                <span className='toolRemove' onClick={e => {
                  e.stopPropagation()
                  removeLine(component, line)
                }} />
              </div>
            </div>
            {line.errorPath && <div className='error'>
              <img src='assets/images/error.svg' alt='Warning' />
              Impossible d'afficher le tracé de la ligne {line.name}
            </div>}
          </div>)}
      </div>}
    </Fragment>}
    <div className='currentLine'>
      <div className='leftLine' key={line.id}>
        {REACT_APP_TYPE === 'tcl' ? <div className='tclLine' style={{ padding: '10px' }}>
          <img src={'assets/images/lines/' + line.code + '.svg'} alt='bus icon' />
        </div> : <div className='lineLabel'>
          {line.mode.toLowerCase() !== 'tad'
            ? <span className={'lineCode' + (line.name.length > 5 ? ' longLineCode' : '')} style={line.mode === 'rer'
              ? {
                backgroundColor: 'white',
                color: '#' + line.color,
                minWidth: 12,
                border: '2px solid #' + line.color,
                borderRadius: '50%',
                fontWeight: 700,
                flex: 0,
                margin: '0 10px',
                padding: '3px 5px'
              } : {
                background: '#' + line.color,
                color: luminance(line.color) > 0.5 ? '#333' : '#fff'
              }}>{line.name}</span> : <div className='tad' />}
        </div>}
        {line.mode.toLowerCase() !== 'rer' ? <span className='direction'>
            Direction :<br />
          <span className='lightBold'>{line.routes[line.direction_id].name}</span>
          {line.mode.toLowerCase() === 'tad' && (isMobile
            ? <a className='tadLink' href='tel:+33800691891'>Réserver votre trajet Mobi'Val</a>
            : <a className='tadLink' target='_blank' rel='noopener noreferrer'
              href='http://www.mobivaldessonne.com/index.php/joomla-fr/2013-12-12-18-55-39'>Réserver votre trajet
              Mobi'Val</a>)}
        </span> : <span className='direction rer'>Ligne de RER</span>}
        <div className='tools'>
          {!component.state.timetable && <Fragment>
            {line.routes.length > 1 &&
            <span className='toolSwap' onClick={() => onLineSelected(onSwitchedDirection(line))} />}
            <span className='toolTimetable' onClick={() => collapseTimetableOptions(component)} />
          </Fragment>}
          {REACT_APP_TYPE !== 'tcl' ? <span className='toolRemove' onClick={() => removeLine(component,
            line)} /> : <span className='toolFavorite' onClick={() => {
            Snackbar.show(favDev)
          }} />}
        </div>
        <Collapse className={'timetableOptions'} isOpened={!!component.state.timetableOptions}>
          {<div>
            <div className={'timetableOptionsItem'} onClick={() => downloadTimetable(line.name)}> Fiche Horaire du 1er
              janvier au 30 juin
            </div>
            <div className={'timetableOptionsItem'} onClick={() => downloadTimetable(line.name)}> Fiche Horaire du 1er
              juillet au 31 aout
            </div>
            <div className={'timetableOptionsItem'} onClick={() => downloadTimetable(line.name)}> Fiche Horaire du 1
              septembre au 31 décembre
            </div>
          </div>}
        </Collapse>
      </div>
      {line.errorPath && <div className='error'>
        <img src='assets/images/error.svg' alt='Warning' />
        Impossible d'afficher le tracé de la ligne {line.name}
      </div>}
      {component.state.timetable ? <Fragment>
        <div className='timetableStop'>
          <span>Arrêt : <span className='bold'>{component.state.timetableStop}</span></span>
          {!timetableDataIsEmpty(component.state.timetableData) && <ReactToPrint
            pageStyle={'@page { size: auto;  margin: 10mm 15mm; } @media print { body { -webkit-print-color-adjust: exact; } }'}
            trigger={() => <div className='print' />} content={() => component.componentRef} />}
        </div>
        <div>
          <Fragment>
            <DateCalendar
              lang={'fr'}
              systemUS={false}
              style={pickerStyle}
              todayTxt="Aujourd'hui"
              image={'assets/images/calendar.svg'}
              setDate={updateURLState(component.props.url).date}
              getSelectedDate={getSelectedDate => {
                const url = component.props.url
                const date = getSelectedDate.format('YYYYMMDD')

                if (!updateURLState(url).date || updateURLState(url).date !== date) {
                  component.props.history.push(url.pathname + url.search.split('&date=')[0] + '&date=' + date)
                }
              }}
            />
            <TimeTable ref={el => (component.componentRef = el)} component={component} /></Fragment>
        </div>
      </Fragment> : <div className='stops scroll'>
        {stopsList.map((stop, index) => <div key={stop.id} className='stop'>
          {line.cat !== 'TAD-markers-only' && <Fragment>
            <div className='border' style={{ borderLeftColor: '#' + line.color }} />
            <div style={{ border: '2px solid #' + line.color }}
              className={'point' + (stop.terminus ? ' pointTerminus' : '')} />
          </Fragment>}
          <div className={'stopName' + (stop.opened ? ' selected' : '')} onClick={() => {
            if (stop.opened) {
              component.props.history.push(component.props.url.pathname + component.props.url.search.split('&stop=')[0]) // deselect stop when clicking on a stop already opened
            } else if (line.mode.toLowerCase() !== 'bus' && REACT_APP_TYPE !== 'tcl') {
              noSchedulesForType(component, stop, line, line.mode.toLowerCase())
            } else {
              schedules(component, stop, line)
            }
          }} onMouseEnter={() => {
            onMarkerMouseOver(component, stop)
          }} onMouseLeave={() => {
            onMarkerMouseOut(component, stop)
          }}>
            <div className={stop.opened ? 'selectedStop' : ''}>
              <div className='flex'>
                <div className='lightBold'>{stop.name}</div>
                {stop.pmr && REACT_APP_TYPE !== 'tcl' && <div className='pmr' />}
              </div>
              {stop.opened && <div className='stopActions'>
                {REACT_APP_TYPE === 'tcl' ? <span className='toolFavorite' onClick={e => {
                  e.stopPropagation()
                  Snackbar.show(favDev)
                }} /> : !stop.tad && <span className='toolRouteCalculation' onClick={e => {
                  const coord = stop.coord.lon + ';' + stop.coord.lat
                  e.stopPropagation()
                  stop.stop_area ? goRouteCalculation(component, stop.stop_area) : goRouteCalculation(component, coord)
                }} />}
              </div>}
            </div>
            {REACT_APP_TYPE !== 'tcl' && stop.opened &&
            <div className='connections'>{retrieveConnections(component, stop, line)}</div>}
            {!stop.tad && <Collapse isOpened={!!stop.opened}>
              {stop.opened ? !stop.rer ? <div className='selectedContent'>
                {stop.schedules ? <div key={stop.id + '_schedules'} className='schedules'>
                  {REACT_APP_TYPE !== 'tcl' && <div className='nextSchedules'>Prochains passages :</div>}
                  {stop.schedules.length > 0 ? stop.schedules.map((schedule, index) => (
                    <div key={'schedule-' + index} className='schedule'>
                      {schedule.realtime ? <Fragment>
                        <Moment locale='fr' fromNow ago>
                          {schedule.time}
                        </Moment>
                        <img src='assets/images/realtime.gif' alt='realtime'
                          title='Horaire en temps réel' /></Fragment> : navitiaToHourMin(schedule.time)}
                    </div>
                  )) : <div>Aucun passage</div>}
                </div> : <img src='assets/images/loading.gif' width={30} alt='Loading' />}
                <div className='seeTimetable' onClick={e => {
                  e.stopPropagation()
                  const url = component.props.url
                  const date = new Date().toISOString().split('T')[0].replace(/-/g, '')
                  component.props.history.push(url.pathname + url.search + '&date=' + date)
                }}>
                  {REACT_APP_TYPE === 'tcl'
                    ? <img src='assets/images/timetable.svg' alt='calendrier' />
                    : <img src='assets/images/calendar.svg' alt='calendrier' />}Fiche
                  {REACT_APP_TYPE === 'tcl' && <br />} horaire
                </div>
              </div> : <div style={{ paddingLeft: 10 }}>Pour afficher les horaires, se référer au calcul
                d'itinéraire</div> : <div />}
            </Collapse>}
          </div>
        </div>)}
      </div>}
    </div>
  </div>
}

/**
 * Render lines passing by an area
 * @param component
 */
export const renderLinesByArea = (component) => {
  return <div className='group'>
    <div className='groupName'>
      <div className='mode'>Lignes passant par {component.state.linesListStop}</div>
    </div>
    <div className='groupOffsetBottom'>{renderLinesLabels(component, component.state.linesList, 'line')} </div>
  </div>
}

/**
 * Display lines sorted by group (collapsable)
 * @param component
 * @returns {any[]}
 */
export const renderLinesGroup = component => {
  if (Object.keys(component.state.groups).length === 0) {
    return <div className='empty'>Aucune ligne passante à cette position</div>
  }

  return Object.keys(component.state.groups).map((group, index) =>
    <div key={group} className='group' onClick={() => collapseGroup(component, 'openedGroups', group)}>
      <div className='groupName'>
        <div className='mode' dangerouslySetInnerHTML={{ __html: getModeNameByGroup(group) }} />
        <div className='arrowGroup'>
          <img className={!component.state.openedGroups[index].opened ? 'closed' : ''} src='assets/images/v.svg'
            alt='arrow' />
        </div>
      </div>
      <Collapse className={component.state.openedGroups[index].opened ? 'groupOffsetBottom' : ''}
        isOpened={component.state.openedGroups[index].group === group && !!component.state.openedGroups[index].opened}>
        {renderLinesLabels(component, component.state.groups[group], group)}
      </Collapse>
    </div>
  )
}

export const renderPlacesGroup = component => {
  if (!component.state.places) {
    return
  }

  if (Object.keys(component.state.places).length === 0) {
    return <div className='empty'>Aucun lieu à cette position</div>
  }

  return Object.keys(component.state.places).map((place, index) =>
    <div key={place} className='group'>
      <div className='groupName' onClick={() => collapseGroup(component, 'openedPlacesGroups', place)}>
        <div className='groupHead'>
          <img
            // src={'assets/images/places/' + component.state.places[place][0].code.split('_')[0] + '.svg'}
            src={'assets/images/autocomplete/poi.svg'}
            alt='groupe' />
          <span>{place}</span>
        </div>
        <div className='arrowGroup'>
          <img className={!component.state.openedPlacesGroups[index].opened ? 'closed' : ''} src='assets/images/v.svg'
            alt='arrow' />
        </div>
      </div>
      <Collapse
        isOpened={component.state.openedPlacesGroups[index].place === place &&
        !!component.state.openedPlacesGroups[index].opened}>
        {component.state.places[place].map(item => (
          <div key={item.id} className='place'>
            {item.name}
          </div>
        ))}
      </Collapse>
    </div>
  )
}

/**
 * Render labels for given lines
 * @param component
 * @param lines
 * @param key
 * @param marker
 */
export const renderLinesLabels = (component, lines, key, marker) => {
  const onLineSelected = component.onLineSelected

  // Avoid undefined lines...
  if (!lines) {
    return
  }

  const div = data => <div key={key + Math.random()} className={key === 'infobox' ? 'infoboxLines' : 'lines'}>
    {data.map(line => {
      // Retrieve the global line
      line = getLine(component, line)

      return REACT_APP_TYPE === 'tcl'
        ? <div className='tclLine' key={line.id} onClick={e => {
          e.stopPropagation()
          onLineSelected(line, marker)
        }}><img src={'assets/images/lines/' + line.code + '.svg'} alt='bus icon' /></div>
        : <div key={line.id} className={key === 'infobox' ? 'infoboxLine' : 'line'} onClick={e => {
          e.stopPropagation()
          onLineSelected(line, marker)
        }}>
          {line.mode.toLowerCase() !== 'tad'
            ? <div
              className={key === 'infobox'
                ? 'infoboxLineCode' + (line.name.length > 5 ? ' longLineCode' : '')
                : 'lineCode' + (line.name.length > 5 ? ' longLineCode' : '')}
              style={line.mode === 'rer' ? {
                backgroundColor: 'white',
                color: '#' + line.color,
                minWidth: 12,
                border: '2px solid #' + line.color,
                borderRadius: '50%',
                fontWeight: 700,
                flex: 0,
                margin: '0 10px',
                padding: '3px 5px'
              } : {
                background: '#' + line.color,
                color: luminance(line.color) > 0.5 ? '#333' : '#fff'
              }}>
              {line.name}
            </div> : <div className='tad' />}
        </div>
    })}
  </div>

  /* if (key === 'métro' && REACT_APP_TYPE === 'tcl') {
    const children = []

    for (const mode of ['métro', 'funiculaire', 'tramway']) {
      children.push(div(lines.filter(line => line.mode === mode)))
    }

    return <div className='tcl-mftrx'>{children}</div>
  } else */
  if (key === 'infobox' && REACT_APP_TYPE === 'tcl') {
    const children = []

    for (const mode of [['métro', 'funiculaire', 'tramway'], ['ligne majeure', 'bus', 'pleine lune']]) {
      const childs = lines.filter(line => {
        line = getLine(component, line)

        if (Array.isArray(mode)) {
          return mode.includes(line.mode)
        } else {
          return line.mode === mode
        }
      })

      if (childs.length > 0) {
        children.push(div(childs))
      }
    }

    return <div className='tcl-infobox'>{children}</div>
  } else {
    // WTF Sorting ...
    if (REACT_APP_TYPE === 'tcl') {
      // Sort only the bus
      if (lines[0] && lines[0].mode === 'bus') {
        sortAlphabetic(lines, 'code')
      }
    }

    if (!lines) {
      return
    }

    lines.sort((a, b) => {
      a = getLine(component, a)
      b = getLine(component, b)
      const codeA = parseInt(a.code.replace('C', ''), 10)
      const codeB = parseInt(b.code.replace('C', ''), 10)

      return codeA - codeB
    })

    return div(lines)
  }
}

/**
 * Render a marker on the map
 * @param stop
 * @param options
 * @param component
 * @param place
 * @returns Marker
 */
export const renderMarker = (component, stop, options, place) => {
  return <Marker
    key={stop.id}
    ref={r => { stop.ref = r }}
    name={stop.name}
    // TODO LEAFLET INFOBOX
    onMouseOver={() => onMarkerMouseOver(component, stop)}
    onMouseOut={() => onMarkerMouseOut(component, stop)}
    onClick={() => onMarkerClick(component, stop, place)}
    position={[+stop.coord.lat, +stop.coord.lon]}
    icon={L.icon({
      iconUrl: 'assets/images/pin.svg',
      iconSize: [50, 50],
      iconAnchor: [25, 25]
    })}
    {...options}
  >
    {
      stop.terminus &&
      <Tooltip key={'terminus_' + stop.id} direction={'right'} className={'tooltip-leaflet-terminus'} opacity={1}
        permanent>{stop.name}</Tooltip>
    }
    <Popup className={'tooltip-leaflet'} closeButton={false} autoClose={false} autoPan={false}>{renderInfobox(component,
      stop, null)}</Popup>
  </Marker>
}

export const renderMarkerRouteCalculation = (key, component, position) => {
  const { url, map } = component.props

  return <Marker
    key={key}
    draggable={!component.state.loading}
    onDragStart={component.onBackToParams}
    onDragEnd={event => {
      let newUrl = url.pathname
      const latlng = event.target.getLatLng()

      if (key === 'inputStart-pin') {
        if (map.state.inputStartPin && !map.state.inputEndPin) {
          newUrl += '?from=' + substringCoords(latlng)
        } else if (map.state.inputStartPin && map.state.inputEndPin) {
          newUrl += '?from=' + substringCoords(latlng) + '&to=' + component.state.url.to
        }
      } else {
        if (!map.state.inputStartPin && map.state.inputEndPin) {
          newUrl += '?to=' + substringCoords(latlng)
        } else if (map.state.inputStartPin && map.state.inputEndPin) {
          newUrl += '?from=' + component.state.url.from + '&to=' + substringCoords(latlng)
        }
      }

      component.props.history.push(newUrl)

      // Launch calculation
      if (map.state.markers.length === 2 && component.state.journeys !== undefined) {
        component.setState({ journey: null }, () => component.calcItineraries())
      }
    }}
    zIndexOffset={999999}
    icon={L.icon({
      iconUrl: `assets/images/${key === 'inputStart-pin' ? 'flag-start' : 'flag-end'}.svg`,
      iconSize: key === 'inputStart-pin' ? [35, 35] : [40, 40],
      iconAnchor: key === 'inputStart-pin' ? [17.5, 17.5] : [20, 20]
    })}
    position={position}
  />
}

/**
 * Render markers on the map
 * @returns {Promise<void>}
 */
// TODO RENAME
export const renderMarkers = async (component, isArea = false) => {
  const { map } = component.props
  const path = window.location.pathname

  if (!isArea) {
    isArea = map.state.zoom < 17
  }

  // Avoid Reload from API
  const response = await axios.get('/api/file?name=' + (isArea ? 'areas' : 'stops'))
  const data = response.data
  // TODO LEAFLET BOUNDS
  // const bounds = map.mapReference.current.getBounds();
  const markers = map.state.markers

  let reader, jstsPolygone
  if (map.state.polygons.length) {
    // Display the marker only if is inside the polygon
    const geometryFactory = new jsts.geom.GeometryFactory()
    jstsPolygone = geometryFactory.createPolygon(
      geometryFactory.createLinearRing(leaflet2JSTS(map.state.polygons[0].props.positions)))
    jstsPolygone.normalize()
    reader = new jsts.io.WKTReader()
  }

  for (const marker of data) {
    // Test if the current marker is visible on the map
    // const visible = bounds.contains({ lat: +marker.coord.lat, lng: +marker.coord.lon });

    // Retrieve the mode of the first line
    const mode = getLine(component, marker.lines[0]).mode

    if (path.includes('towns') || (reader && containsLocation(marker, jstsPolygone, reader))) {
      markers.push(
        renderMarker(component, marker, {
          icon: L.icon({
            iconUrl: mode === 'rer'
              ? 'assets/images/rer.svg'
              : 'assets/images/bus.svg',
            iconSize: [20, 20],
            iconAnchor: [10, 10]
          }),
          marker,
          zIndex: mode === 'tad' ? 18 : 20
        }))
    }
  }

  // Test if we have to display cluster or not
  if (map.state.status === 'cluster' || map.state.zoom < 14) {
    const clusters = <MarkerClusterer
      styles={[
        {
          url: 'assets/images/bus.svg',
          height: 30,
          width: 30,
          textColor: 'white'
        }]}
      gridSize={60}>
      {markers}
    </MarkerClusterer>

    map.setState({
      markers: [],
      clusters
    }, () => resize(map.props.isMobile))
  } else {
    map.setState({
      markers,
      clusters: null
    }, () => resize(map.props.isMobile))
  }
}

// TODO RENAME
export const renderPlaces = async component => {
  const { map } = component.props
  const places = component.state.places

  // TODO THROW ERROR
  if (!places) {
    console.warn('No places')
    return
  }

  const allPlaces = []

  for (const place of Object.keys(places)) {
    allPlaces.push(...places[place])
  }

  const markers = []
  for (const place of allPlaces) {
    markers.push(
      renderMarker(component, place, {
        icon: L.icon({
          // TODO NEED MARKER
          // iconUrl: 'assets/images/places/' + place.code.split('_')[0] + '.svg',
          iconUrl: 'assets/images/autocomplete/poi.svg',
          iconSize: [50, 50],
          iconAnchor: [25, 18]
        })
      })
    )
  }

  map.setState({
    clusters: null,
    status: null,
    markers
  }, () => resize(map.props.isMobile))
}

export const renderPolygon = (path, options, key, props) => {
  return <Polygon options={options} key={key} positions={path} {...props} />
}

export const renderPolyline = (path, options, key) => {
  return <Polyline key={key} positions={path} options={options} />
}

// Render town data
export const renderTown = (component) => {
  return <Tabs
    selectedIndex={component.state.tab || 0}
    onSelect={index => component.onTabSelected(index)}
    selectedTabClassName='active'
    selectedTabPanelClassName='active scroll'>
    <TabList className='tabList'>
      <Tab className='tab'>Transport<br /><span>à 10 minutes</span></Tab>
      <Tab className='tab'>Lieux d'intérêt<br /><span>à 10 minutes</span></Tab>
    </TabList>
    <TabPanel className='tabPanel'>
      {renderLinesGroup(component)}
    </TabPanel>
    <TabPanel className='tabPanel'>
      {renderPlacesGroup(component)}
    </TabPanel>
  </Tabs>
}

/**
 * Retrive the nearest stop
 * @param component
 * @param currentLine
 * @returns {*}
 export const retrieveNearestStop = (component, currentLine) => {
  const { map } = component.props
  const { circle } = map.state

  if (!circle) {
    return
  }

  const markers = map.state.markers.filter(marker => {
    for (const line of marker.props.marker.lines) {
      if (line.id === currentLine.id && line.direction_id === currentLine.direction_id) {
        return true
      }
    }
    return false
  })

  let nearest = null
  for (const marker of markers) {
    if (nearest) {
      const markerPosition = new L.LatLng({
        lat: marker.props.position.lat,
        lng: marker.props.position.lng
      })

      const nearestPosition = new L.LatLng({
        lat: nearest.props.position.lat,
        lng: nearest.props.position.lng
      })

      const toNearest = google.maps.geometry.spherical.computeDistanceBetween(circle.props.center, nearestPosition)
      const toMarker = google.maps.geometry.spherical.computeDistanceBetween(circle.props.center, markerPosition)

      if (toMarker <= toNearest) {
        nearest = marker
      }
    } else {
      nearest = marker
    }
  }

  return nearest
} */

/**
 * Retrieve schedules for a given stop in a given component
 * @param component
 * @param stop
 * @param line
 * @param date
 * @param fromMarkerClick
 */
export const schedules = (component, stop, line, date = '', fromMarkerClick = false) => {
  const { stopsList } = component.state
  const isTerminus = stop.terminus && stop.id === stopsList[stopsList.length - 1].id // TCL style

  // TODO DANS AROUND ARRET LE PLUS PROCHE PAS VISIBLE DU COUP...
  if (line.mode === 'tad' || line.mode === 'rer') {
    return
  }

  // Avoid load schedules for a nonsense data
  let markerContainsLine = false
  for (const data of stop.lines) {
    if (data.id === line.id) {
      markerContainsLine = true
      break
    }
  }

  if (!markerContainsLine) {
    return
  }

  if (!component.props.url.search.includes('&stop=' + stop.id) &&
    !component.props.url.search.includes('&stop=' + stop.stop_area)) {
    component.props.history.push(
      component.props.url.pathname + component.props.url.search.split('&stop=')[0] + '&stop=' + stop.id)
  } else {
    for (const s of stopsList) {
      s.schedules = null
      s.opened = false

      if (stop.id.includes('stop_area')) {
        s.opened = s.stop_area === stop.id && !isTerminus
      } else {
        s.opened = s.id === stop.id && !isTerminus
      }
    }

    component.setState({
      timetable: false,
      stopsList
    }, async () => {
      // Retrieve element and scroll to it
      const selected = stop.id.includes('stop_area')
        ? stopsList.find(s => s.stop_area === stop.id)
        : stopsList.find(s => s.stop_area === stop.stop_area)

      // Select the stop
      setTimeout(() => displayStopOnScrollToIt(component, stop, line, selected, fromMarkerClick), 150)

      if (!isTerminus) {
        const schedules = []
        axios.get('/api/schedules', {
          params: {
            stop: selected.id,
            line: line.id,
            date,
            count: REACT_APP_TYPE === 'tcl' ? 2 : 3
          }
        }).then(response => {
          // TODO Use data_freshness to retrieve realtime schedules
          for (const resp of response.data) {
            schedules.push({
              time: resp.stop_date_time.departure_date_time,
              realtime: resp.stop_date_time.data_freshness === 'realtime'
            })
          }

          selected.schedules = schedules
        }).catch(e => {
          selected.schedules = []

          // TODO Handle error message
          console.warn('Error : ', e.response.data.id)
        }).finally(() => {
          component.setState({ stopsList })
        })
      }
    })
  }
}

/**
 *
 * @param component
 * @param stop
 * @param line
 * @param selected
 * @param fromMarkerClick
 */
const displayStopOnScrollToIt = (component, stop, line, selected, fromMarkerClick = false) => {
  const { stopsList } = component.state
  const { map, url } = component.props
  const { markers, clusters, status } = map.state

  // Retrieve the correct marker on map for the current clicked stop
  const id = status !== 'physical' ? (stop.id.includes('stop_point')
    ? stop.stop_area
    : stop.id) : stop.id
  const array = status === 'cluster' && clusters
    ? clusters.props.children
    : markers
  let marker = array.find(marker => marker.key === id)

  if (url.pathname.includes('lines')) {
    marker = map.state.markers.find(marker => marker.key === line.code + '_' + stop.id)
  }

  if (!fromMarkerClick) {
    if (!marker) {
      // Ugly fix for waiting marker to display
      setTimeout(() => {
        marker = array.find(marker => marker.key === id)
        marker && marker.props.onClick()
      }, 500)
    } else {
      marker.props.onClick()
    }
  }

  // Set BIG marker

  // The Elder Scrooooooooll
  let index = 0
  for (const s of stopsList) {
    if (s.id === selected.id) {
      break
    }

    index++
  }

  const element = document.querySelector(`.stops > :nth-child(${index + 1})`)

  // Scroll board to the selected element
  setTimeout(() => {
    element && (element.parentNode.scrollTop = element.offsetTop - element.parentNode.offsetTop)

    // Bring the content to top
    document.querySelector('#app').scrollTop = window.innerHeight
  })
}

/**
 * Display line paths and info
 * @param component
 * @param line
 * @param linesTab
 * @param selectedLines
 */
export const selectLine = (component, line, linesTab = false, selectedLines = []) => {
  const { map, stops } = component.props

  if (!line.direction_id) {
    line.direction_id = 0
  }

  return axios.get(`/api/file?folder=stops&name=${line.code}_${line.network}_${line.direction_id}`)
    .then(async response => {
      // TODO Fix retrive lines at generate
      for (const stop of response.data) {
        const infos = stops.find(s => s.id === stop.id)
        stop.lines = infos.lines

        // Define line as PMR only if ONE stop is PMR
        if (stop.pmr) {
          line.pmr = true
        }
      }

      line.stops = response.data

      if (line.cat !== 'TAD-markers-only') {
        try {
          const polylines = await displayLinePath(line, map)

          map.setState({
            polylines,
            infoboxsTerminus: [],
            terminus: false
          }, () => REACT_APP_TYPE === 'tcl' && displayLineTerminus(line, map))

          if (linesTab) {
            // avoid double
            !selectedLines.find(selectLine => selectLine.id === line.id) && selectedLines.push(line)

            return {
              currentLine: line,
              stopsList: response.data,
              selectedLines
            }
          } else {
            return {
              currentLine: line,
              stopsList: response.data
            }
          }
        } catch (e) {
          console.log(e)
          console.log('Impossible to get geojson for line ', line.name)
          line.errorPath = true

          return {
            currentLine: line,
            stopsList: response.data,
            selectedLines
          }
        } finally {
          // Resize the panel
          setTimeout(() => resize(map.props.isMobile))
        }
      } else {
        return {
          currentLine: line,
          stopsList: response.data,
          selectedLines
        }
      }
    })
    .catch(error => {
      console.warn('Unable to find stops/' + line.code + '_' + line.network + '_' + line.direction_id + '.json', error)
    })
}

/**
 * Update map state events
 * @param map
 * @param event
 * @param callback
 */
export const updateMapEvents = (map, event, callback) => {
  if (!map) {
    return
  }

  map.setState(state => ({
    events: {
      ...state.events,
      [event]: callback
    }
  }))
}

// --------------------------- PRIVATE --------------------------- //

/**
 *
 * @param component
 * @param groups
 * @param index
 */
const collapseGroup = (component, groups, index) => {
  const openedGroups = component.state[groups].map(g => {
    // TODO type (group or place)
    if (g.group === index || g.place === index) {
      g.opened = !g.opened
    } else {
      g.opened = false
    }

    return g
  })

  component.setState({
    [groups]: openedGroups
  })
}

const collapseTimetableOptions = component => {
  component.setState({
    timetableOptions: !component.state.timetableOptions
  }, () => {
    if (component.state.timetableOptions) {
      const element = document.querySelector('.timetableOptions')
      element.style.top = document.querySelector('.currentLine .leftLine').offsetHeight + 'px'
    }
  })
}

/**
 * Check if a polygon contains a maker
 * @param marker
 * @param jstsPolygon
 * @returns {*}
 */
const containsLocation = (marker, jstsPolygon, reader) => {
  return reader.read('POINT(' + marker.coord.lat + ' ' + marker.coord.lon + ')').within(jstsPolygon)
}

/**
 * Check if marker is close to outline
 * @param marker
 * @param jstsPolygone
 * @param inside
 * @param reader
 * @returns {boolean}
 */
const edgeLocation = (marker, jstsPolygone, inside, reader) => {
  let find = false
  if (!inside) {
    for (const polyPoint of jstsPolygone.getCoordinates()) {
      const point = reader.read('POINT(' + polyPoint.x + ' ' + polyPoint.y + ')')
      if (marker.distance(point) < 0.009) {
        find = true
      }
    }
  }
  return find
}

/**
 * Limit api call
 * @param func
 * @param wait
 * @param immediate
 * @returns {Function}
 */
export const debounce = (func, wait, immediate) => {
  let timeout

  return function () {
    const later = () => {
      timeout = null

      if (!immediate) {
        func.apply(this, arguments)
      }
    }

    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) {
      func.apply(this, arguments)
    }
  }
}

/**
 * Launch api call if user don't use his keyboard for 500ms while user fill autocomplete input
 * @type {Function}
 */
const debounceRequest = debounce(async (inputValue, component, input, variant) => {
  const response = await axios({
    url: '/api/autocomplete',
    params: {
      type: variant || input,
      query: inputValue,
      data: component.props.data
    }
  })

  if (response.data) {
    const state = {}

    if (input === 'inputTowns') {
      state[input + 'Data'] = response.data
    } else if (input === 'inputPlaces' || input === 'inputLines') {
      state[input + 'Data'] = component.state[input + 'Data'].filter(address => address.id === 'favorite')
        .concat(response.data)
    } else {
      state[input + 'Data'] = component.state[input + 'Data'].filter(
        address => address.id === 'geoloc' || address.id === 'favorite').concat(response.data)
    }
    component.setState(state)
  }
}, 500)

const displayLineTerminus = (line, map) => {
  const infoboxsTerminus = []

  if (!map.state.terminus) {
    for (const stop of line.stops) {
      if (!stop.terminus) {
        continue
      }

      const marker = {
        ...stop,
        id: 'terminus-' + stop.id,
        offset: {
          x: 0,
          y: 0
        }
      }

      const options = {
        closeBoxURL: '',
        enableEventPropagation: true,
        disableAutoPan: true,
        pixelOffset: {
          width: 10 + (marker.offset ? marker.offset.x : 0),
          height: -20 + (marker.offset ? marker.offset.y : 0)
        },
        zIndex: 1
      }

      infoboxsTerminus.push(
        <InfoBox key={marker.id + '_infobox_terminus'}
          defaultPosition={new L.LatLng(marker.coord.lat, marker.coord.lon)} options={options}>
          <div className='infobox' style={{ background: '#' + line.color }}>
            <div className='infoTitle' style={{ padding: '5px 10px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                }}>
                {marker.name}
                {marker.pmr && REACT_APP_TYPE !== 'tcl' && <div className='pmr' />}
              </div>
            </div>
          </div>
        </InfoBox>
      )
    }
  }

  map.setState({
    terminus: !map.state.terminus,
    infoboxsTerminus
  }/*,
   () => {
   setTimeout(() => {
   const terminusText = document.querySelector(".terminus");
   terminusText && (terminusText.innerHTML = map.state.terminus
   ? "Cacher les terminus"
   : "Afficher les terminus");
   });
   }, */)
}

/**
 * Display a timetable for a stop and a specific line
 * @param component
 * @param stop
 * @param line
 * @param date
 */
export const displayTimeTable = (component, stop, line, date) => {
  // 0: morning, 1: afternoon, 2: evening selected by defaut with the hour if today
  const isToday = new Date().toISOString().split('T')[0].replace(/-/g, '') === date
  const nowHours = Math.floor(new Date().getHours())
  let slideIndex = isToday ? nowHours >= 4 && nowHours <= 11 ? 0 : nowHours >= 12 && nowHours <= 19 ? 1 : 2 : 0

  // get stop id of line if we have a stop_area
  if (stop.id.includes('stop_area')) {
    stop = stop.lines.find(l => l.id === line.id)
  }

  component.setState({
    timetable: true,
    loadingTimetable: true,
    timetableStop: stop.name
  }, async () => {
    const params = {
      stop: stop.stop_id || stop.id,
      line: line.id,
      timetable: true
    }

    const nightline = REACT_APP_TYPE === 'tcl' && component.state.currentLine.mode === 'pleine lune'
    if (date) {
      if (nightline) {
        params.date = date + 'T000000'
        slideIndex = 0 // There is only one slide for the night lines
      } else {
        params.date = date + 'T040000'
      }
    }

    const response = await axios({
      url: '/api/schedules',
      params
    })

    const timetableData = {
      morning: [],
      afternoon: [],
      evening: []
    }

    for (const time of response.data) {
      const departure = parseInt(time.stop_date_time.departure_date_time.substring(9, 11))

      if (departure >= 4 && departure <= 11) {
        timetableData.morning.push(time)
      } else if (departure > 11 && departure <= 19) {
        timetableData.afternoon.push(time)
      } else if (departure >= 20 || departure < 4) {
        timetableData.evening.push(time)
      }
    }

    if (timetableDataIsEmpty(timetableData)) { // fix crash
      slideIndex = 0
    }

    component.setState({
      slideIndex,
      timetableData,
      loadingTimetable: false
    }, () => {
      const divs = Array.from(document.querySelectorAll('.scroll'))
      resize(component.props.map.props.isMobile, divs[component.state.slideIndex])
    })
  })
}

const downloadTimetable = () => {
  window.open('/assets/fiche_T2.pdf')
}

/**
 * leaflet points to jsts
 * @param coord
 * @returns {Array}
 */
const leaflet2JSTS = coord => {
  const coordinates = []

  for (let i = 0; i < coord.length; i++) {
    coordinates.push(new jsts.geom.Coordinate(coord[i][0], coord[i][1]))
  }

  return coordinates
}

/**
 * jsts to leaflet points
 * @param geometry
 * @returns {Array}
 */
const jsts2Leaflet = geometry => {
  const coordArray = geometry.getCoordinates()
  const coordinates = []

  for (let i = 0; i < coordArray.length; i++) {
    coordinates.push([coordArray[i].x, coordArray[i].y])
  }

  return coordinates
}

export const noSchedulesForType = (component, stop, line, type) => {
  const { stopsList } = component.state

  if (line.mode === 'tad' || line.mode === 'rer') {
    return
  }

  // Retrieve element and scroll to it
  const selected = stop.id.includes('stop_area')
    ? stopsList.find(s => s.stop_area === stop.id)
    : stopsList.find(s => s.stop_area === stop.stop_area)

  // Select the stop
  setTimeout(() => displayStopOnScrollToIt(component, stop, line, selected))

  for (const s of stopsList) {
    s.opened = s.id === stop.id

    if (s.id === stop.id) {
      stop.opened = true
      stop[type] = true
    }
  }

  component.setState({ stopsList })
}

/**
 * Convert Navitia data to readable time
 * @param date
 * @returns {string|*}
 */
/* const navitiaToDate = date => {
 let time = date.split("T")[1];
 time = time.substring(0, 2) + "h" + time.substring(2, 4);
 return time;
 }; */

/**
 * Handle marker click
 * @param stop
 * @param place
 * @param component
 */
const onMarkerClick = (component, stop, place) => {
  const { history, map } = component.props

  if ((history || window).location.pathname.includes('route-calculation')) {
    return
  }

  if (component.state.selectedMarker) {
    const ref = component.state.selectedMarker.ref

    ref && ref.leafletElement && ref.leafletElement.closePopup()
  }

  // TODO Safari bug, have to click twice ! Jira TCL-89
  if (stop.ref && stop.ref.leafletElement) {
    stop.ref.leafletElement.openPopup()
  }

  setTimeout(() => {
    if (stop.ref && stop.ref.leafletElement) {
      // Fit Bounds & resize popup position
      fitBounds(map, null, L.latLngBounds([stop.ref.leafletElement.getLatLng()]))

      const popup = stop.ref.leafletElement.getPopup()

      if (popup) {
        const element = popup.getElement()
        popup.options.offset = new L.Point(element.offsetWidth / 2 + 5, element.offsetHeight - 5)
        popup.update()
      }
    }
  }, 200)

  if (place) {
    const path = history.location.pathname
    const search = history.location.search

    if (path.includes('places-interest')) {
      component.onPlaceMarkerClick(place)
    } else if (path.includes('hiking-routes')) {
      if (search.includes('route=')) {
        component.onRoutePlaceSelected(place)
      } else {
        component.onRouteSelected(place)
      }
    }

    return
  }

  // If we have already selected a line
  if (component.state.currentLine) {
    // Avoid double schedules at load
    if (!history.location.search.includes(stop.id)) {
      schedules(component, stop, component.state.currentLine, null, true)
    }
  }

  renderInfobox(component, stop, true)
}

/**
 * Handle marker mouse over
 * @param component
 * @param stop
 */
const onMarkerMouseOver = (component, stop) => {
  if (!stop.name) {
    return
  }

  if (component.state.selectedMarker === stop && (stop.ref && !stop.ref.clicked)) {
    stop.ref.clicked = true
  }

  if (stop.ref) {
    stop.ref.leafletElement.openPopup()
  }

  renderInfobox(component, stop)

  setTimeout(() => {
    if (stop.ref) {
      const popup = stop.ref.leafletElement.getPopup()
      const element = popup.getElement()
      popup.options.offset = new L.Point(element.offsetWidth / 2 + 5, element.offsetHeight - 5)
      popup.update()
    }
  })
}

/**
 * Handle marker mouse out
 * @param component
 * @param stop
 */
const onMarkerMouseOut = (component, stop) => {
  let { map } = component.props

  if (stop.ref) {
    if (stop.ref.clicked) {
      stop.ref.leafletElement.openPopup()
      stop.ref.clicked = false
    } else {
      stop.ref.leafletElement.closePopup()
    }
  }

  if (!map) {
    map = component
  }

  map.setState({
    infoboxs: map.state.selectedInfobox ? [map.state.selectedInfobox] : []
  })
}

/**
 * Switch a line direction
 * @param line
 * @returns Object
 */
const onSwitchedDirection = line => {
  line.direction_id = parseInt(line.direction_id) === 0 ? 1 : 0
  return line
}

/**
 * Retrieve all connections for a stop
 * @param component
 * @param stop
 * @param selectedLine
 * @returns {any[]}
 */
const retrieveConnections = (component, stop, selectedLine) => {
  return stop.lines.filter(line => line.id !== selectedLine.id).map(line => {
    line = getLine(component, line)

    return <div key={line.id} className='connection'
      style={{ background: '#' + line.color, color: luminance(line.color) > 0.5 ? '#333' : '#fff' }}>{line.name}</div>
  })
}
