import axios from 'axios'
import { luminance } from 'luminance-js'
import React, { Component, Fragment } from 'react'
import Autocomplete from 'react-autocomplete'
import { withRouter } from 'react-router-dom'
import L from 'leaflet'

const { REACT_APP_TYPE, REACT_APP_MAP_TYPE } = process.env

const {
  displayTCLHeavyLines,
  displayTimeTable,
  fitBounds,
  getLine,
  getStop,
  noSchedulesForType,
  onChangeAutocompleteInput,
  removeLine,
  removeMapEvents,
  renderLine,
  renderLinesByArea,
  renderLinesGroup,
  renderMarker,
  schedules,
  selectLine,
  updateMapEvents
} = require(`../../utils/${REACT_APP_MAP_TYPE}/map`)
const {
  addResizeEvent,
  getDistanceBetweenMarkers,
  groupLinesByMode,
  resize,
  unique,
  updateURLState
} = require(`../../utils/${REACT_APP_MAP_TYPE}/tools`)

/* global google */
class Lines extends Component {
  state = {
    currentLine: null,
    groups: {},
    inputLinesData: [],
    inputLinesValue: [],
    selectedLines: [],
    stopsList: [],
    url: null
  }

  /**
   * Load data powered by the url
   * @returns {Promise<void>}
   */
  loadData = async () => {
    const { map } = this.props
    const { url } = this.state

    if (map) {
      map.setState({
        polylines: [],
        polylineDecorators: [],
        markers: [],
        infoboxs: url.stop ? [map.state.selectedInfobox] : []
      })
    }

    let currentLine
    if (url.current || url.selected || url.stop_area) {
      let current = null
      let selectedLines = []
      if (url.current) {
        current = url.current
        currentLine = getLine(this, { id: current.substring(0, current.lastIndexOf('_')) })
        currentLine.direction_id = current.slice(-1)
      }

      if (url.selected && REACT_APP_TYPE !== 'tcl') {
        for (let line of url.selected.split(',')) {
          if (line.length) {
            const selected = line
            const foundLine = getLine(this, { id: selected.substring(0, selected.lastIndexOf('_')) })
            foundLine.direction_id = selected.slice(-1)
            selectedLines.push(foundLine)
          }
        }

        if (currentLine) {
          selectedLines.push(currentLine)
        }
      }

      for (let otherLine of selectedLines) {
        await selectLine(this, otherLine, true, selectedLines)
      }

      let newState = null
      if (currentLine) {
        newState = await selectLine(this, currentLine, true, selectedLines)
        const selectedPolylines = map.state.polylines.filter(
          polyline => newState.currentLine.code === polyline.key.split('_').shift())
        const newMarkers = REACT_APP_TYPE === 'tcl' ? [] : map.state.markers

        for (let i = 0; i < newState.stopsList.length; i++) {
          const stop = newState.stopsList[i]

          newMarkers.push(
            renderMarker(this, stop, {
              key: currentLine.code + '_' + stop.id,
              icon: REACT_APP_MAP_TYPE === 'leaflet' ? new L.DivIcon({
                className: 'leaflet-bus-icon',
                iconSize: stop.terminus ? [10, 10] : [6, 6],
                iconAnchor: stop.terminus ? [6, 6] : [3, 3],
                tooltipAnchor: new L.Point(5, 0),
                html: `<span style="border: ${stop.terminus ? 3 : 2}px solid #${currentLine.color}" />`
              }) : {
                path: 'M8.5,12a3.5,3.5 0 1,0 7,0a3.5,3.5 0 1,0 -7,0',
                strokeColor: '#' + currentLine.color,
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: 'white',
                fillOpacity: 1,
                scale: 1.2,
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(12, 12),
                zIndex: 10
              }
            }))
        }

        map.setState({ markers: [...newMarkers] })

        if (!newState.currentLine.errorPath && !url.stop) {
          fitBounds(this.props.map, selectedPolylines)
        }
      } else {
        if (url.stop_area) {
          axios.get('http://localhost:8400/api/search?name=areas&value=' + url.stop_area).then(response => {
            this.setState({
              linesList: response.data.lines,
              linesListStop: response.data.name
            })
          })
        }
        newState = {
          currentLine: null,
          selectedLines
        }

        // Remove terminus
        map.setState({
          terminus: false,
          infoboxsTerminus: []
        })
        if (!url.stop_area && this.state.linesListStop) { // Back de area + selected vers selected
          this.setState({ linesListStop: null })
        }
        displayTCLHeavyLines(this)
      }

      newState.inputLinesData = []
      newState.inputLinesValue = []

      if (newState) {
        this.setState(newState, () => {
          if (url.stop) {
            const line = this.state.currentLine
            const stop = getStop(this, { id: url.stop }, line)

            if (url.date) {
              displayTimeTable(this, stop, line, url.date)

              // Display marker infobox if page is reloaded
              if (!map.state.selectedInfobox) {
                const marker = map.state.markers.find(marker => marker.key.split(/_(.*)/)[1] === stop.id)
                marker.props.onClick()
              }
            } else {
              schedules(this, stop, line)
            }
          }
        })
      }
    } else {
      displayTCLHeavyLines(this)

      this.setState({
        currentLine: null,
        selectedLines: [],
        inputLinesData: [],
        inputLinesValue: [],
        linesListStop: null
      })
    }

    setTimeout(() => resize(map.props.isMobile))
  }

  /**
   * onSelectLine add the line in the seletedLines table, set it to currentLine,
   * add the polylines and markers to the map
   * @param line
   * @param marker
   */
  onLineSelected = async (line, marker) => {
    const { url } = this.props
    const urlState = this.state.url
    const current = line.id + '_' + (line.direction_id ? line.direction_id : line.routes[0].direction_id)
    // Scroll to top
    setTimeout(() => {
      const stops = document.querySelector('.stops')
      stops && (stops.scrollTop = 0)
    })

    this.setState({ timetable: false })

    let selectedLines = this.state.selectedLines
    selectedLines = selectedLines.filter(selectedLine => selectedLine.id !== line.id)

    if (marker) {
      if (line.mode.toLowerCase() !== 'bus' && REACT_APP_TYPE !== 'tcl') {
        noSchedulesForType(this, marker, line, line.mode.toLowerCase())
      } else {
        schedules(this, marker, line)
      }
    }

    let selection = ''

    if (REACT_APP_TYPE !== 'tcl') {
      selection += '&selected='
      if (selectedLines.length >= 1 && selectedLines.length < 3) {
        for (let selectedLine of selectedLines) {
          selection += selectedLine.id + '_' + (selectedLine.direction_id ? selectedLine.direction_id : 0) + ','
        }
      } else {
        for (let selectedLine of selectedLines.slice(-2)) {
          selection += selectedLine.id + '_' + (selectedLine.direction_id ? selectedLine.direction_id : 0) + ','
        }
      }
    }

    let findDataStop = null
    if (marker) {
      findDataStop = marker.lines.find(l => l.id === line.id)
    }

    if (urlState.stop_area) {
      findDataStop = this.state.linesList.find(linesListItem => linesListItem.id === line.id)
    }

    this.props.history.push(url.pathname + '?' + (urlState.stop_area ? 'stop_area=' + urlState.stop_area + '&' : '') + 'current=' + current + (selectedLines.length >= 1 ? selection : '') +
      (findDataStop ? '&stop=' + findDataStop.stop_id : ''))

    if (marker) {
      if (line.mode.toLowerCase() !== 'bus' && REACT_APP_TYPE !== 'tcl') {
        noSchedulesForType(this, marker, line, line.mode.toLowerCase())
      } else {
        schedules(this, marker, line)
      }
    }
  }

  onStopSelected = (stop) => {
    const { url } = this.props
    if (stop.lines.length === 1) {
      this.props.history.push(url.pathname + '?current=' + stop.lines[0].id + '_' + stop.lines[0].direction_id + '&stop=' + stop.id)
    } else {
      this.props.history.push(url.pathname + '?stop_area=' + stop.id)
    }

    // Scroll to top
    setTimeout(() => {
      const stops = document.querySelector('.stops')
      stops && (stops.scrollTop = 0)
    })

    this.setState({ timetable: false })
  }

  /**
   * Initialize the component
   */
  componentDidMount () {
    const { map } = this.props

    if (map) {
      axios.get('/api/file?name=lines').then(response => {
        const groups = groupLinesByMode(response.data)

        axios.get('/api/places-data?data=covoiturage').then(response => {
          groups['carpool'] = response.data

          const openedGroups = Object.keys(groups).map((group, index) => ({
            group,
            opened: index === 0
          }))

          this.setState({
            groups,
            openedGroups
          })
        })
      })

      updateMapEvents(map, 'onClick', e => {
        map.setState({
          selectedInfobox: null,
          infoboxs: []
        })

        // Detect nearest marker and open it (only mobile)
        if (map.props.isMobile) {
          for (const marker of map.state.markers) {
            const position = REACT_APP_MAP_TYPE === 'leaflet' ? new L.LatLng(marker.props.position[0], marker.props.position[1]) : new google.maps.LatLng(marker.props.position[0], marker.props.position[1])
            const distance = getDistanceBetweenMarkers(position, REACT_APP_MAP_TYPE === 'leaflet' ? e.latlng : e.latLng)

            if (distance < 15) {
              marker.props.onClick()
              return
            }
          }
        }
      })

      this.removeEventListener = addResizeEvent(map.props.isMobile)
      this.loadData()
    }

    this.timetableOptionsListener = e => {
      if (this.state.timetableOptions) {
        if (e.target.classList.contains('toolTimetable')) {
          e.stopPropagation()
        }

        this.setState({ timetableOptions: false })
      }
    }

    document.querySelector('#app').addEventListener('click', this.timetableOptionsListener)
  }

  /**
   * Detect url changes
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    if (this.props.location.search !== prevProps.location.search ||
      (prevProps.url.key !== this.props.url.key && !this.state.currentLine)) {
      const url = updateURLState(this.props.location)

      this.setState({ url }, () => {
        this.loadData()
      })
    }
  }

  /**
   * Define url
   */
  componentWillMount () {
    this.setState({ url: updateURLState(this.props.url) })
  }

  /**
   * Clear the component, remove all state & events from the map
   */
  componentWillUnmount () {
    const { map } = this.props

    if (map) {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        selectedInfobox: null,
        terminus: false,
        infoboxsTerminus: []
      })

      removeMapEvents(map)
      this.removeEventListener()
    }

    document.querySelector('#app').removeEventListener('click', this.timetableOptionsListener)
  }

  render () {
    return this.state.currentLine ? renderLine(this) : <Fragment>
      <div className='form'>
        <div className='inputs'>
          <Autocomplete
            inputProps={{ id: 'input-lines' }}
            wrapperStyle={{
              display: 'flex',
              flex: 1,
              position: 'relative'
            }}
            value={this.state.inputLinesValue}
            items={REACT_APP_TYPE === 'tcl'
              ? this.state.inputLinesData.filter(item => item.mode !== 'junior direct')
              : this.state.inputLinesData}
            getItemValue={item => item.name}
            onSelect={(valueInputSelect, dataInputSelect) => dataInputSelect.id.includes('stop_area') ? this.onStopSelected(dataInputSelect) : this.onLineSelected(dataInputSelect)}
            onChange={event => onChangeAutocompleteInput(event, this, 'inputLines', REACT_APP_TYPE === 'tcl' ? 'inputSchedules' : null)}
            renderMenu={children => <div className='autocomplete'>{children}</div>}
            renderInput={props => <input className='input' {...props} placeholder={'Numéro de ligne...'} />}
            renderItem={(item, isHighlighted) => (
              item.id.includes('stop_area')
                ? <div className='item' key={item.id}><img
                  width={30}
                  src={'assets/images/autocomplete/stop_area.svg'}
                  alt='stop'
                />{item.name}</div>
                : <div className={'line' + (isHighlighted ? ' highlight' : '')} key={item.id} style={{ padding: 10 }}>
                  {REACT_APP_TYPE === 'tcl' ? <div className='tclLine'>
                    <img src={'assets/images/lines/' + item.code + '.svg'} alt='bus icon' />
                  </div> : item.mode.toLowerCase() !== 'tad' ? <div
                    key={item.id}
                    className='lineCode'
                    style={item.mode === 'rer' ? {
                      backgroundColor: 'white',
                      color: '#' + item.color,
                      minWidth: 12,
                      border: '2px solid #' + item.color,
                      borderRadius: '50%',
                      fontWeight: 700,
                      flex: 0,
                      margin: '0 28px 0 18px',
                      padding: '3px 5px'
                    } : {
                      marginRight: 10,
                      background: '#' + item.color,
                      color: luminance(item.color) > 0.5 ? '#333' : '#fff'
                    }}>
                    {item.name}
                  </div> : <div className={'tad'} />}
                  <div
                    style={{
                      flex: 5,
                      paddingLeft: 15
                    }}>
                    {unique(item.routes, 'name').map(route => (
                      <div className='autocompleteDirection' key={route.name}>{route.name}</div>
                    ))}
                  </div>
                </div>
            )}
          />
        </div>
      </div>
      <div className='scroll'>
        {REACT_APP_TYPE !== 'tcl' && this.state.selectedLines.length > 0 && <div className='selectedLines'>
          <div className='groupName mode'>Lignes sélectionnées</div>
          {this.state.selectedLines.map(line =>
            <div key={line.id + '_sup'} onClick={() => this.onLineSelected(line)}>
              <div className='leftLine' style={{ padding: '5px 0' }}>
                {line.mode.toLowerCase() !== 'tad' ? <span className='lineCode' style={line.mode === 'rer' ? {
                  backgroundColor: 'white',
                  color: '#' + line.color,
                  minWidth: 12,
                  border: '2px solid #' + line.color,
                  borderRadius: '50%',
                  fontWeight: 700,
                  flex: 0,
                  margin: '0 10px',
                  padding: '3px 5px'
                } : {
                  background: '#' + line.color,
                  color: luminance(line.color) > 0.5 ? '#333' : '#fff'
                }}>{line.name}</span> : <div className='tad' />}
                {line.mode.toLowerCase() !== 'rer' ? <span className='direction'>
                  Direction :<br />
                  <span className='lightBold'>{line.routes[line.routes.length > 1 ? line.direction_id : 0].name}</span>
                </span> : <span className='direction rer'>Ligne de RER</span>}
                <span className='toolRemove' onClick={e => {
                  e.stopPropagation()
                  removeLine(this, line, true)
                }} />
              </div>
              {line.errorPath && <div className='error'>
                <img src='assets/images/error.svg' alt='Warning' />
                Impossible d'afficher le tracé de la ligne {line.name}
              </div>}
            </div>
          )}
        </div>}
        {Object.keys(this.state.groups).length > 0 ? <div id='groups' key='groups' className={REACT_APP_TYPE === 'tcl' ? 'tcl-elevation' : ''}>
          {(this.state.linesList && this.state.linesListStop) ? renderLinesByArea(this) : renderLinesGroup(this)}
        </div> : <div className='loading'>
          <img src='assets/images/loading.gif' alt='Loading' />
        </div>}
      </div>
    </Fragment>
  }
}

export default withRouter(Lines)
