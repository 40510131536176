import axios from 'axios'
import React, { Component, Fragment } from 'react'
import Autocomplete from 'react-autocomplete'
import { withRouter } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const { REACT_APP_MAP_TYPE } = process.env

const {
  around,
  displayTimeTable,
  getLine,
  getStop,
  goRouteCalculation,
  invertCoords,
  onChangeAutocompleteInput,
  onZoomChanged,
  removeLine,
  removeMapEvents,
  renderLine,
  renderLinesGroup,
  renderMarkers,
  renderPlaces,
  renderPlacesGroup,
  renderPolygon,
  renderTown,
  schedules,
  selectLine,
  updateMapEvents
} = require(`../../utils/${REACT_APP_MAP_TYPE}/map`)
const { addResizeEvent, checkCoords, getCloserStopIdFromStopsList, getLinesInTown, geolocInput, resize, substringCoords, updateURLState } = require(
  `../../utils/${REACT_APP_MAP_TYPE}/tools`)

const initAddresses = [
  {
    id: 'geoloc',
    name: 'Ma position',
    geolocation: true
  }
]

const { REACT_APP_TYPE } = process.env

class Around extends Component {
  state = {
    currentLine: null,
    groups: [],
    error: '',
    warning: '',
    inputAroundData: initAddresses,
    inputAroundValue: '',
    inputAroundPlace: null,
    inputAroundGoToRoute: false,
    stopsList: [],
    tab: 0,
    url: null
  }

  /**
   * Put marker needed on map
   * @param url
   * @returns {Promise<void>}
   */
  loadData = async url => {
    // TODO add stop in URL to fix nearest marker selected
    const { map, theme } = this.props

    if (map) {
      removeLine(this)
      if (map.state.polygons.length > 0) {
        map.setState({ polygons: [map.state.polygons.find(p => !p.key.includes('polygon-town'))] })
      }

      if (url.from) {
        try {
          await around(this, await checkCoords(url.from, 'inputAround', this))
          this.setState({ tab: 0, inputAroundGoToRoute: true })
        } catch (e) {
          console.warn('Around fail : ', e)
          return
        }
      }
    }

    if (url.insee) {
      const insee = url.insee.split(':')[2]
      axios.get(`/api/search?name=towns&value=${insee}`).then(response => {
        const town = response.data

        const polygons = [
          renderPolygon(invertCoords(town), {
            strokeWeight: 1.5,
            strokeColor: '#' + theme.colors['primary'],
            fillOpacity: 0
          }, 'polygon-town')
        ]

        map.setState({
          polygons,
          infoboxs: []
        }, async () => {
          await getLinesInTown(this, town)
          // TODO FIT BOUNDS SUR LE POLYGON
          // fitBounds(map, polygons)
        })
      })
    }

    if (url.line) {
      axios.get('/api/file?name=lines').then(response => {
        const currentLine = url.line
        const selectedLine = response.data.find(
          line => currentLine.substring(0, currentLine.lastIndexOf('_')) === line.id)

        if (currentLine.slice(-1) !== '0') {
          selectedLine.direction_id = 1
        }

        this.onLineSelected(selectedLine, null, true)
      })
    }

    if (!url.from && !url.line) {
      this.setState({
        currentLine: null,
        groups: [],
        inputAroundValue: '',
        inputAroundData: initAddresses
      })

      if (!url.from && map) {
        map.setState({
          circle: null,
          pin: null,
          status: null
        }, () => this.forceUpdate()) // TODO ??
      }
    }
  }

  /**
   * Handle line selected
   * @param line
   * @param marker
   * @param onLoad
   * @returns {Promise<void>}
   */
  onLineSelected = async (line, marker, onLoad = false) => {
    const { url, map } = this.props

    this.setState({ timetable: false })

    if (!onLoad) {
      const dir = line.direction_id ? line.direction_id : line.routes[0].direction_id

      let stopURL = ''

      if (this.props.map.state.pin && (!this.state.currentLine || line.code === this.state.currentLine.code)) {
        const lineData = await selectLine(this, getLine(this, line))
        const closerStopId = getCloserStopIdFromStopsList(map.state.pin.props.position, lineData.stopsList)
        stopURL = '&stop=' + closerStopId
      }

      if (marker) {
        stopURL = '&stop=' + marker.id
      }

      if (this.state.url.from) {
        this.props.history.push(
          url.pathname + '?from=' + this.state.url.from + '&line=' + line.id + '_' + dir + stopURL)
      } else if (this.state.url.insee) {
        this.props.history.push(
          url.pathname + '?insee=' + this.state.url.insee + '&line=' + line.id + '_' + dir + stopURL)
      } else {
        this.props.history.push(url.pathname + '?line=' + line.id + '_' + dir + stopURL)
      }
    } else {
      const data = await selectLine(this, getLine(this, line))

      // Avoid redraw if there is no data (error in selectLine() ?)
      if (data) {
        this.setState({ ...data }, () => {
          const currentURL = updateURLState(url)
          if (currentURL.stop) {
            const line = this.state.currentLine
            const stop = getStop(this, { id: currentURL.stop }, line)
            if (currentURL.date) {
              displayTimeTable(this, stop, line, currentURL.date)
            } else {
              schedules(this, stop, line)
            }
          }

          // Resize board when line is selected
          setTimeout(() => resize(map.props.isMobile))
        })
      }
    }
  }

  /**
   * Handle Tab changed
   * @param index
   */
  onTabSelected = index => {
    const { map } = this.props
    if (!index && index !== 0) { // If index is undefined, use the state.tab (used to refresh pois when the user drags the around pin)
      index = this.state.tab
    }

    this.setState({ tab: index }, () => {
      map.setState({
        markers: []
      }, () => {
        setTimeout(() => {
          if (index === 0) {
            renderMarkers(this)
          } else {
            renderPlaces(this)
          }
        })
      })
    })
  }

  /**
   * Remove the circle pin on pin drag start
   */
  removePinCircle = () => {
    const { map } = this.props

    this.setState({ inputAroundValue: '', error: '', warning: '' })
    map.setState({ circle: null })
  }

  /**
   * Initialize the component
   */
  componentDidMount () {
    const { map, history } = this.props

    // Add map events
    updateMapEvents(map, 'onClick', event => {
      if (!updateURLState(this.props.url).insee) {
        if (map.state.pin) {
          map.setState({
            selectedInfobox: null,
            infoboxs: []
          })
          return
        }

        const latlng = REACT_APP_MAP_TYPE === 'leaflet' ? event.latlng : event.latLng

        let newUrl = this.props.url.pathname
        newUrl += '?from=' + substringCoords(latlng)
        this.props.history.push(newUrl)
      }
    })

    updateMapEvents(map, REACT_APP_MAP_TYPE === 'leaflet' ? 'onZoomEnd' : 'onZoomChanged', () => {
      const path = history.location.pathname
      if (this.state.tab === 0) { // Blocks onzoomchanged when displaying places of interest
        onZoomChanged(this, path)
      }
    })

    this.loadData(this.state.url)

    if (map) {
      this.removeEventListener = addResizeEvent(map.props.isMobile)
    }
  }

  /**
   * Detect url changes
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    if (this.props.location.search.split('&stop=')[0] !== prevProps.location.search.split('&stop=')[0]) {
      const url = updateURLState(this.props.location)

      this.setState({ url }, () => {
        this.loadData(url)
      })
    } else if (prevProps.url.key !== this.props.url.key) {
      const url = updateURLState(this.props.location)

      if (url.stop) {
        const line = this.state.currentLine
        const stop = getStop(this, { id: url.stop }, line)

        if (url.date) {
          displayTimeTable(this, stop, line, url.date)
        } else {
          schedules(this, stop, line)
        }
      }
    }
  }

  componentWillMount () {
    this.setState({ url: updateURLState(this.props.url) })
  }

  /**
   * Clear the component, remove all state & events from the map
   */
  componentWillUnmount () {
    const { map } = this.props

    if (map) {
      map.setState({
        polygons: [],
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        selectedInfobox: null,
        pin: null,
        circle: null,
        terminus: false,
        infoboxsTerminus: []
      })

      removeMapEvents(map)
      this.removeEventListener()
    }
  }

  render () {
    const { map } = this.props

    return this.state.currentLine ? renderLine(this) : <Fragment>
      <div className='form'>
        <div className='inputs'>
          <Autocomplete
            inputProps={{ id: 'input-around' }}
            wrapperStyle={{
              display: 'flex',
              flex: 1,
              position: 'relative'
            }}
            value={this.state.inputAroundValue}
            items={this.state.inputAroundData}
            getItemValue={item => item.name}
            onSelect={async (valueInputSelect, dataInputSelect) => {
              const { url } = this.props
              let coord = null

              try {
                if (dataInputSelect.geolocation) {
                  coord = '?from=' + await geolocInput()
                } else if (dataInputSelect.favorite) {
                  coord = '?from=' + dataInputSelect.coords
                } else {
                  // TODO if town we need something specific...
                  const id = dataInputSelect.id
                  coord = id.includes('admin:fr:') ? '?insee=' + id : id.includes('line')
                    ? '?line=' + id + '_0'
                    : '?from=' + id
                }
              } catch (e) {
                console.warn(e)
              }

              if (coord) {
                let newUrl = url.pathname
                newUrl += coord
                this.props.history.push(newUrl)
              } else {
                this.setState({
                  groups: null,
                  error: 'Erreur lors de la géolocalisation',
                  inputAroundData: initAddresses,
                  inputAroundValue: '',
                  stopsList: [],
                  tab: 0
                })
              }
            }}
            onChange={event => onChangeAutocompleteInput(event, this, 'inputAround',
              REACT_APP_TYPE === 'tcl' ? 'inputSearch' : null)}
            renderMenu={children => <div className='autocomplete'>{children}</div>}
            renderInput={props => <Fragment>
              <input className='input' {...props} placeholder={'Adresse, lieu, arrêt'} />
              {this.state.inputAroundGoToRoute && <div
                className='goToRoute around'
                onClick={() => {
                  if (this.state.inputAroundPlace) {
                    goRouteCalculation(this, this.state.inputAroundPlace.id)
                  } else {
                    const position = map.state.pin.props.position
                    const coord = REACT_APP_MAP_TYPE === 'leaflet' ? position[1] + ';' + position[0] : position.lng() +
                      ';' + position.lat()
                    goRouteCalculation(this, coord)
                  }
                }}>
                <img src={'assets/images/menu/route-calculation.svg'} alt='itinéraire' />
              </div>}
            </Fragment>}
            renderItem={(item, isHighlighted) => (
              // TODO change style for lines !
              <div className={'item' + (isHighlighted ? ' itemHighlight' : '')} key={item.id}>
                {item.embedded_type
                  ? <img width={30} src={'assets/images/autocomplete/' + item.embedded_type + '.svg'}
                    alt={item.embedded_type} />
                  : item.id === 'geoloc'
                    ? <img width={30} src={'assets/images/autocomplete/position.svg'} alt={'Ma position'} />
                    : <div className='tclLine'>
                      <img src={'assets/images/lines/' + item.code + '.svg'} alt='bus icon' />
                    </div>
                }
                {item.name}
              </div>
            )}
          />
        </div>
        {this.state.warning.length > 0 && (<div className='warning'>
          <img src='assets/images/warning.svg' alt='Warning' />
          {this.state.warning}
        </div>)}
        {this.state.error.length > 0 && (<div className='error'>
          <img src='assets/images/error.svg' alt='Warning' />
          {this.state.error}
        </div>)}
        {map && !map.state.pin && REACT_APP_TYPE !== 'tcl' &&
        <span className='paddingTop'>ou cliquez sur la carte...</span>}
      </div>
      {this.state.town ? renderTown(this) : (map && map.state.pin && this.state.groups &&
        <Fragment>
          <Tabs
            selectedIndex={this.state.tab || 0}
            onSelect={index => this.onTabSelected(index)}
            selectedTabClassName='active'
            selectedTabPanelClassName='active scroll'>
            <TabList className='tabList'>
              <Tab className='tab'>Transport<br /><span>à 10 minutes</span></Tab>
              <Tab className='tab'>Lieux d'intérêt<br /><span>à 10 minutes</span></Tab>
            </TabList>
            <TabPanel className='tabPanel'>
              {renderLinesGroup(this)}
            </TabPanel>
            <TabPanel className='tabPanel'>
              {renderPlacesGroup(this)}
            </TabPanel>
          </Tabs>
        </Fragment>)
      }
    </Fragment>
  }
}

export default withRouter(Around)
