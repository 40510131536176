import axios from 'axios'
import 'node-snackbar/dist/snackbar.css'
import React, { Component } from 'react'
import { create } from 'reworm'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import '../scss/app.scss'
import Modal from './Modal'
import Board from './Board'
import Map from './Map'

const store = create({
  inputStartValue: '',
  inputStartData: [{
    id: 'geoloc',
    name: 'Ma position',
    geolocation: true
  }],
  inputEndValue: '',
  inputEndData: [{
    id: 'geoloc',
    name: 'Ma position',
    geolocation: true
  }]
})

class App extends Component {
  state = {
    events: {},
    isMobile: false,
    lines: [],
    map: undefined,
    mediaQueries: null,
    modules: [],
    stops: [],
    areas: [],
    theme: {
      colors: {},
      fonts: [],
      selectedFont: null
    },
    modal: false
  }

  handleMediaQueryChanged = event => {
    this.setState({ isMobile: event.matches })
  }

  async componentWillMount () {
    const mediaQueries = window.matchMedia('(max-width: 600px)')
    const colors = []
    const fonts = []

    const requestLines = axios('/api/file?name=lines')
    const requestStops = axios('/api/file?name=stops')
    const requestAreas = axios('/api/file?name=areas')
    const requestState = axios('/api/file?name=state')
    const requestStyles = axios('/api/file?name=styles')

    const results = await Promise.all([
      requestLines,
      requestStops,
      requestAreas,
      requestState,
      requestStyles
    ])
    const lines = results.shift().data
    const stops = results.shift().data
    const areas = results.shift().data
    const state = results.shift().data
    const styles = results.shift().data

    for (const color of Object.keys(state.styles.colors)) {
      colors[color] = state.styles.colors[color]
    }

    for (const font of state.styles.fonts) {
      const newFont = {
        fontFamily: font.split('-')[0],
        fontWeight: font.split('-')[1].slice(0, -4) === 'Bold'
          ? 'bold'
          : 'normal',
        fontStyle: font.split('-')[1].slice(0, -4) === 'Italic'
          ? 'italic'
          : 'normal',
        src: 'url(\'assets/fonts/' + font + '\') format(\'truetype\')'
      }

      fonts.push(newFont)
    }

    this.setState({
      mediaQueries,
      isMobile: mediaQueries.matches,
      modules: state.modules,
      lines,
      stops,
      areas,
      styles,
      theme: {
        colors,
        fonts,
        selectedFont: fonts[0]
      }
    }, () => {
      this.state.mediaQueries.addListener(this.handleMediaQueryChanged)
    })
  }

  componentDidMount () {
    const app = document.querySelector('#app')

    // Lower iOS flickering scroll impact
    app.addEventListener('scroll', () => {
      const scroll = app.scrollTop
      app.scrollTop = scroll
    })
  }

  componentWillUnmount () {
    this.state.mediaQueries && this.state.mediaQueries.removeListener(this.handleMediaQueryChanged)
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  render () {
    const { modules, map, lines, stops, areas, isMobile, theme, styles } = this.state

    // Board props
    const boardProps = {
      map,
      lines,
      stops,
      areas,
      isMobile,
      theme,
      modules
    }

    return <BrowserRouter>
      <div className='container'>
        <Switch>
          {modules.map((module, index) =>
            <Route key={module.id + '_' + index} path={'/' + module.id}
              component={() => <Board {...boardProps} module={module} store={store} toggleModal={this.toggleModal} />} />
          )}
          <Route component={() => <Board {...boardProps} />} />
        </Switch>
        <Map onRef={map => setTimeout(() => this.setState({ map }))} events={this.state.events}
          isMobile={this.state.isMobile} styles={styles} />
        {
          this.state.modal && store.get(s => <Modal modalState={s} store={store} toggleModal={this.toggleModal} />)
        }
      </div>
    </BrowserRouter>
  }
}

export default App
