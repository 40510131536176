import axios from 'axios'
import Snackbar from 'node-snackbar'
import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { detect } from 'detect-browser'
import Around from './Modules/Around'
import HikingRoutes from './Modules/HikingRoutes'
import Lines from './Modules/Lines'
import PlacesInterest from './Modules/PlacesInterest'
import RouteCalculation from './Modules/RouteCalculation'
import Towns from './Modules/Towns'

const { REACT_APP_TYPE, REACT_APP_MAP_TYPE } = process.env

const {
  displayTerritoryOutline,
  displayTCLHeavyLines,
  onZoomChanged,
  removeMapEvents,
  // renderInfobox,
  updateMapEvents
} = require(`../utils/${REACT_APP_MAP_TYPE}/map`)
const { substringCoords, updateURLState, whitify } = require(`../utils/${REACT_APP_MAP_TYPE}/tools`)

const noLink = {
  text: 'Fonctionnalité en cours de développement',
  actionText: 'Fermer',
  backgroundColor: '#e2001a',
  actionTextColor: '#333'
}

/* global google */
class Board extends Component {
  state = {}

  renderModule = () => {
    const { module, location: url, map, theme, lines, stops, areas, toggleModal, store } = this.props

    if (!module) {
      console.warn('An error has occured while loading module ...')
      return
    }

    const props = {
      lines,
      map,
      stops,
      areas,
      theme,
      url
    }

    switch (module.id) {
      case 'around':
        return <Around {...props} />
      case 'lines':
        return <Lines {...props} />
      case 'route-calculation':
        return store.get(modalState => <RouteCalculation {...props} toggleModal={toggleModal} modalState={modalState} store={store} />)
      case 'towns':
        return <Towns {...props} />
      case 'places-interest':
        return <PlacesInterest data={'places'} search={module.search} {...props} />
      case 'hiking-routes':
        return <HikingRoutes {...props} />
      default:
        if (module.id.includes('places-interest')) {
          return <PlacesInterest data={module.data} search={module.search} {...props} />
        }
    }
  }

  resizeBoardContent = () => {
    const browser = detect()
    const root = document.querySelector('.board')
    const app = document.querySelector('#app')
    const minHeight = 200 // Use this valeu to restrict the minimum board height

    browser.os === 'Mac OS' && (app.style.WebkitOverflowScrolling = 'touch') // Safari Mac OS

    if (this.props.isMobile) {
      app.style.flexDirection = 'column'
      root.style.transform = 'translateY(' + (window.innerHeight - minHeight) + 'px)'
      root.offsetHeight < minHeight && (root.style.minHeight = minHeight + 'px')

      setTimeout(() => {
        browser.os === 'iOS' && (app.style.WebkitOverflowScrolling = 'touch') // iOS
        app.scrollTop = window.innerHeight
      }, 250)

      const scroll = {
        y: 0,
        dir: 'bottom'
      }

      app.addEventListener('scroll', event => {
        scroll.dir = scroll.y > event.target.scrollTop ? 'bottom' : 'top'

        if (scroll.dir === 'bottom') {
          root.style.maxHeight = ''
        } else {
          root.style.maxHeight = window.innerHeight / 1.15 + 'px'
        }

        scroll.y = event.target.scrollTop
      })
    } else {
      root.style.transform = 'translateY(0px)'
    }
  }

  back = () => {
    const { location: url, history, store } = this.props
    const pathname = url.pathname
    const currentUrl = updateURLState(url)

    switch (pathname) {
      case '/lines':
        if (currentUrl.current) {
          if (currentUrl.date) {
            history.push(url.pathname + url.search.split('&date=')[0])
          } else {
            const urlStopArea = currentUrl.stop_area ? 'stop_area=' + currentUrl.stop_area + '&' : ''
            history.push(url.pathname + '?' + urlStopArea + 'selected=' + (currentUrl.selected ? currentUrl.selected : '') + currentUrl.current)
          }
        } else if (currentUrl.stop_area) {
          const urlSelectedLines = currentUrl.selected ? '?selected=' + currentUrl.selected : ''
          history.push(url.pathname + urlSelectedLines)
        } else {
          history.push('/')
        }
        break
      case '/around':
        if (currentUrl.line) {
          if (currentUrl.from) {
            if (currentUrl.date) {
              history.push(url.pathname + url.search.split('&date=')[0])
            } else {
              history.push(url.pathname + '?from=' + currentUrl.from)
            }
          } else {
            history.push(url.pathname)
          }
        } else if (currentUrl.from && !currentUrl.line) {
          history.push(url.pathname)
        } else {
          history.push('/')
        }
        break
      case '/towns':
        if (currentUrl.insee && currentUrl.line) {
          if (currentUrl.date) {
            history.push(url.pathname + url.search.split('&date=')[0])
          } else {
            history.push(url.pathname + '?insee=' + currentUrl.insee)
          }
        } else if (currentUrl.insee && !currentUrl.line) {
          history.push(url.pathname)
        } else {
          history.push('/')
        }
        break
      case '/hiking-routes':
        let searchArgs = url.search.split('&')

        if (searchArgs.length > 1) {
          const toRemove = searchArgs[searchArgs.length - 1]
          history.push(url.pathname + url.search.replace('&' + toRemove, ''))
        } else {
          if (url.search) {
            history.push(url.pathname)
          } else {
            history.push('/')
          }
        }
        break
      case '/route-calculation':
        if (currentUrl.to || currentUrl.from) {
          if (currentUrl.journey) {
            history.push(
              url.pathname + '?from=' + currentUrl.from + '&to=' + currentUrl.to + '&date=' + currentUrl.date +
              '&modes=' + currentUrl.modes)
          } else if (currentUrl.date) {
            history.push(url.pathname + '?from=' + currentUrl.from + '&to=' + currentUrl.to)
          } else {
            history.push('/')
          }
        } else {
          history.push('/')
        }
        break
      case '/mentions':
        history.push('/')
        this.setState({ mentions: false })
        break
      default:
        // Remove all items in the store
        store.set({})

        if (pathname.includes('places-interest')) {
          let searchArgs = url.search.split('&')

          // TODO FIX FOR PLACES-INTEREST TO THINK BETTER
          if (searchArgs.length > 1) {
            let toRemove = searchArgs[searchArgs.length - 1]
            history.push(url.pathname + url.search.replace('&' + toRemove, ''))
          } else {
            if (url.search) {
              history.push(url.pathname)
            } else {
              history.push('/')
            }
          }
        } else {
          console.warn('ERROR RETURN TO MENU !')
          history.push('/')
        }
        break
    }
  }

  getPage = page => {
    return <Fragment>
      <div className='header'>
        <div className='back' onClick={() => this.back()}>
          <img src='assets/images/back.svg' alt='back' />
        </div>
        <div className='boardTitle'>
          <div className='menuTitle title'>{page === 'mentions' ? 'mentions légales' : page}</div>
        </div>
      </div>
      <div className='content page' dangerouslySetInnerHTML={{ __html: this.state.content }} />
    </Fragment>
  }

  onLineSelected = async (line, marker) => {
    this.props.history.push(`/lines?current=${line.id}_${line.direction_id}&stop=${marker.id}`)
  }

  onClickInfobox (marker, url) {
    const { history, map } = this.props
    const path = `/${url}?from=${substringCoords(marker.coord)}`

    history.push(path)

    map.setState({ infoboxs: [] })
  }

  onClickItemPage = page => {
    this.props.history.push('/' + page)
    this.setState({ [page]: true })
  }

  componentDidMount () {
    const { map, history } = this.props

    axios.get('/api/file?folder=pages&ext=html&name=mentions').then(response => {
      this.setState({ content: response.data })
    })

    this.resizeBoardContent()

    // Fix iOS touchmove problem (map and board moving without touching theme
    document.addEventListener('touchmove', () => {})

    if (history.location.pathname === '/') {
      // TODO LEAFLET INFOBOX
      if (REACT_APP_TYPE !== 'tcl') {
        updateMapEvents(map, 'onClick', click => {
          map.setState({ infoboxs: [] })

          // renderInfobox(this, {
          //   id: 'Board',
          //   coord: {
          //     lat: REACT_APP_MAP_TYPE === 'leaflet' ? click.latlng.lat : click.latLng.lat(),
          //     lon: REACT_APP_MAP_TYPE === 'leaflet' ? click.latlng.lng : click.latLng.lng()
          //   }
          // }, null, true)
        })
      }

      updateMapEvents(map, REACT_APP_MAP_TYPE === 'leaflet' ? 'onZoomEnd' : 'onZoomChanged', () => {
        // TODO up in tools factory
        const path = REACT_APP_TYPE === 'ccve' ? history.location.pathname : null
        onZoomChanged(this, path)
      })

      map && REACT_APP_TYPE === 'tcl' && displayTCLHeavyLines(this)
    } else {
      if (history.location.pathname === '/mentions') {
        this.setState({ mentions: true })
      }
    }

    if (map) {
      // Detect if streetview
      if (REACT_APP_MAP_TYPE === 'google' && map.mapReference.current) {
        const streetView = map.mapReference.current.getStreetView()

        this.onStreetViewChanged = google.maps.event.addListener(streetView, 'visible_changed', () => {
          const board = document.querySelector('.board')

          if (streetView.visible) {
            board.style.zIndex = 0
          } else {
            board.style.zIndex = 1
          }
        })
      }

      map.setState({ markersRER: [] }, async () => {
        if (REACT_APP_TYPE !== 'tcl') {
          displayTerritoryOutline(this)
        }
      })
    }
  }

  componentWillUnmount () {
    const { map } = this.props

    if (map) {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        selectedInfobox: null,
        pin: null,
        circle: null,
        terminus: false,
        infoboxsTerminus: []
      })
      removeMapEvents(map)

      if (map.mapReference.current && this.onStreetViewChanged) {
        this.onStreetViewChanged.remove()
      }
    }
  }

  render () {
    const { modules, module } = this.props

    return <div className={'board' + (!module ? ' noModule' : '')}>
      {module && <div className='content noScroll'>
        <div className='header'>
          <div className='back' onClick={() => this.back()}>
            <img src='assets/images/back.svg' alt='retour' />
          </div>
          <div className='boardTitle'>
            {REACT_APP_TYPE !== 'tcl' && <img className='images' src={whitify(
              module.image)} alt={module.title} />}
            <div className={`menuTitle title${module.title.length >= 20 ? " longer" : ""}`}>{module.title}</div>
          </div>
        </div>
        {this.renderModule()}
      </div>}
      {!module && (
        this.state.mentions
          ? this.getPage('mentions')
          : <Fragment>
            {REACT_APP_TYPE !== 'tcl' && <div className='head'>
              <img src='assets/images/logo.svg' alt='logo' />
              <div>Carte interactive<br />de la mobilité</div>
            </div>}
            <div className='content main'>
              {modules.filter(module => module.id !== 'admin')
                .sort((a, b) => +(a.position > b.position) || +(a.position === b.position) - 1)
                .map(module =>
                  <Link key={module.id} className='itemMenu'
                    onClick={() => module.id === 'around' && REACT_APP_TYPE === 'tcl' && Snackbar.show(noLink)}
                    to={'/' + module.id}>
                    <img className='images' src={module.image} alt={module.title} />
                    <div className='itemContent'>
                      <div className='menuTitle'>{module.title}</div>
                      {!this.props.isMobile &&
                      <div className='description' dangerouslySetInnerHTML={{ __html: module.description }} />}
                    </div>
                    <div className='itemArrow' />
                  </Link>
                )}
              {REACT_APP_TYPE === 'tcl' && <div className='tclButtons'>
                {
                  [
                    {
                      text: 'Points<br/>de vente',
                      img: 'pt-vente'
                    },
                    {
                      text: 'Bornes<br/>e-tecely',
                      img: 'e-tecely'
                    },
                    {
                      text: 'GAB',
                      img: 'gab'
                    },
                    {
                      text: 'Parcs Relais',
                      img: 'P+R'
                    }
                  ].map((item, index) =>
                    <Link key={index} className='itemMenu' to={'/'} onClick={() => Snackbar.show(noLink)}>
                      <img className='images' src={'assets/images/menu/' + item.img + '.svg'} alt={'pmr'} />
                      <div className='menuTitle' dangerouslySetInnerHTML={{ __html: item.text }} />
                    </Link>)
                }
              </div>}
              {REACT_APP_TYPE !== 'tcl' && (!this.state.mentions) && <Fragment>
                <div onClick={() => this.onClickItemPage('mentions')} className='itemPage'>
                  Mentions légales
                </div>
              </Fragment>}
            </div>
            {REACT_APP_TYPE === 'tcl' && <div className='subContent main'>
              <Link key={'pdf'} className='itemMenu' onClick={() => Snackbar.show(noLink)} to={'/'}>
                <img className='images' src={'assets/images/menu/pdf.svg'} alt={'pdf'} />
                <div className='itemContent'>
                  <div className='menuTitle'>Consulter les plans PDF</div>
                </div>
                <div className='itemArrow' />
              </Link>
            </div>}
          </Fragment>
      )}
    </div>
  }
}

export default withRouter(Board)
