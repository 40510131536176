import 'react-app-polyfill/ie11'

import { detect } from 'detect-browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'reworm'
import App from './components/App'

/**
 * Handle older browser message on UI
 */
const browser = detect()

const config = [
  {
    name: 'ie',
    supported: false
  },
  {
    name: 'edge',
    supported: true,
    version: 17
  },
  {
    name: 'firefox',
    supported: true,
    version: 64
  },
  {
    name: 'chrome',
    supported: true,
    version: 71
  },
  {
    name: 'crios',
    supported: true,
    version: 71
  },
  {
    name: 'safari',
    supported: true,
    version: 12
  },
  {
    name: 'ios',
    supported: true,
    version: 12
  },
  {
    name: 'opera-mini',
    supported: true,
    version: -1
  },
  {
    name: 'android',
    supported: true,
    version: 5
  }
]

if (browser.name !== 'ie') {
  ReactDOM.render(
    <Provider>
      <App />
    </Provider>,
    document.getElementById('app')
  )
}

for (const data of config) {
  if (
    !browser ||
    (browser.name === data.name &&
      (!data.supported || (data.supported && parseInt(browser.version.split('.').shift(), 10) < data.version)))
  ) {
    let name = ''

    if (browser) {
      switch (browser.name) {
        case 'ie':
          name = 'Internet Explorer'
          break
        case 'firefox':
          name = 'Firefox'
          break
        case 'chrome':
        case 'crios':
          name = 'Google Chrome'
          break
        case 'safari':
        case 'ios':
          name = 'Safari'
          break
        case 'opera-mini':
          name = 'Opera Mini'
          break

        default:
          name = ''
      }
    }

    const element = document.createElement('div')
    element.classList.add('unsupported-warning')
    element.onclick = () => element.remove()
    element.innerHTML = `<div class="message">La carte interactive est incompatible avec votre navigateur${
      name ? ' ' + name + ' version ' + browser.version : ''
    }. Veuillez mettre à jour ou télécharger un navigateur compatible <a href="http://outdatedbrowser.com/fr" target="_blank">à cette adresse</a></div><div>&#10005</div></div>`

    const app = document.getElementById('app')
    app.insertBefore(element, app.firstChild)

    break
  }
}
