import axios from 'axios'
import React, { Component, Fragment } from 'react'
import Autocomplete from 'react-autocomplete'
import { withRouter } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Collapse } from 'react-collapse'

const { REACT_APP_MAP_TYPE } = process.env

const {
  around,
  displayTimeTable,
  fitBounds,
  getLine,
  getStop,
  goRouteCalculation,
  invertCoords,
  onChangeAutocompleteInput,
  onZoomChanged,
  removeLine,
  removeMapEvents,
  renderLine,
  renderLinesGroup,
  renderMarker,
  renderMarkers,
  renderPlaces,
  renderPlacesGroup,
  renderPolygon,
  schedules,
  selectLine,
  updateMapEvents,
  getCoordinates,
  renderPolyline
} = require(`../../utils/${REACT_APP_MAP_TYPE}/map`)
const { addResizeEvent, createCoords, getCloserStopIdFromStopsList, resize, updateURLState } = require(
  `../../utils/${REACT_APP_MAP_TYPE}/tools`)

class PlacesInterest extends Component {
  state = {
    groups: [],
    inputPlacesData: [],
    inputPlacesValue: '',
    placesInterest: [],
    selectedCat: null,
    selectedPlace: null,
    selectedRando: null,
    randoPlaces: [],
    randoMarkers: [],
    randoPolylines: [],
    selectedPlacesList: null,
    selectedsubcat: null,
    tab: null,
    onlyChanged: false,
    url: null
  }

  loadData = async () => {
    const { map, theme } = this.props
    const currentURL = this.state.url
    const keys = Object.keys(this.state.placesInterest).sort()
    let category = null

    this.removeMarkers()

    // TODO Add selected line on URL
    removeLine(this)

    if (currentURL.line) {
      axios.get('/api/file?name=lines').then(response => {
        const currentLine = currentURL.line
        const selectedLine = response.data.find(
          line => currentLine.substring(0, currentLine.lastIndexOf('_')) === line.id)

        if (currentLine.slice(-1) !== '0') {
          selectedLine.direction_id = 1
        }

        this.onLineSelected(selectedLine, null, true)
      })
    }

    let newState = {
      selectedCat: null,
      selectedPlace: null,
      selectedRando: null,
      randoPlaces: [],
      randoMarkers: [],
      randoPolylines: [],
      selectedPlacesList: null,
      selectedsubcat: null
    }

    // Place ?
    if (currentURL.place) {
      for (let category of Object.keys(this.state.placesInterest)) {
        for (let place of this.state.placesInterest[category]) {
          if (currentURL.place === place.id) {
            if (place.rando) {
              newState.selectedRando = place
              const markers = []
              const paths = []
              newState.randoPolylines = []
              const headersPlaces = [
                'route',
                'id',
                'x',
                'y',
                'description'
              ]

              // Get rando places
              const responsePlaces = await axios.get('/api/csvtojson?file=hiking-routes-places&headers=' + headersPlaces)
              newState.randoPlaces = responsePlaces.data.filter(item => item.route === place.rando)

              for (const place of newState.randoPlaces) {
                // Close collapse place by default
                place.opened = false

                const marker = {
                  id: 'hiking-places-' + place.id,
                  coord: {
                    lat: place.y,
                    lon: place.x
                  }
                }

                markers.push(
                  renderMarker(this, marker, {
                    icon: {
                      url: 'assets/images/hiking/places/' + place.id + '.svg',
                      size: {
                        width: 30,
                        height: 30
                      },
                      scaledSize: {
                        width: 30,
                        height: 30
                      },
                      anchor: {
                        x: 15,
                        y: 15
                      }
                    },
                    zIndex: 30
                  }, place))
              }
              newState.randoMarkers = markers

              // Get rando path
              const responseGeojson = await axios.get('/api/file?folder=hiking&ext=geojson&name=' + place.rando)
              for (const feature of responseGeojson.data.features) {
                paths.push(...getCoordinates(feature))
              }

              for (const path of paths) {
                const index = paths.indexOf(path)

                const cloneOptions = {
                  strokeColor: '#fff',
                  strokeOpacity: 1,
                  strokeWeight: 10,
                  zIndex: 6
                }

                const lineOptions = {
                  strokeColor: '#' + theme.colors['primary'],
                  strokeOpacity: 1,
                  strokeWeight: 6,
                  zIndex: 7
                }

                newState.randoPolylines.push(renderPolyline(path, cloneOptions, 'clone_' + index))
                newState.randoPolylines.push(renderPolyline(path, lineOptions, 'poly_' + index))
              }
            }
            newState.selectedPlace = place
            newState.tab = 0
            break
          }
        }
      }

      if (newState.selectedPlace.subcat !== 'empty-subcat') {
        const selectedPlacesList = []
        for (let category of Object.keys(this.state.placesInterest)) {
          selectedPlacesList.push(...this.state.placesInterest[category].filter(
            place => newState.selectedPlace.code === place.code))
        }

        newState.selectedPlacesList = selectedPlacesList
      } else {
        const selectedPlacesList = this.state.placesInterest[newState.selectedPlace.cat].filter(
          place => newState.selectedPlace.code === place.code)

        if (selectedPlacesList) {
          newState.selectedPlacesList = selectedPlacesList
        }
      }
    } else {
      // Just a cat
      if (currentURL.cat) {
        for (const cat of keys) {
          for (let place of this.state.placesInterest[cat]) {
            if (place.code.split('_')[0] === currentURL.cat) {
              category = place.cat
              break
            }
          }
        }

        newState.selectedCat = this.state.placesInterest[category]

        // If cat selected don't have subcat
        if (newState.selectedCat[0].subcat === 'empty-subcat') {
          newState.selectedPlacesList = newState.selectedCat
        }

        // If subcat
        if (currentURL.subcat && category !== 'empty-cat') {
          const placesList = []

          for (let place of newState.selectedCat) {
            if (place.code.split('_')[1] === currentURL.subcat) {
              placesList.push(place)
            }
          }

          newState.selectedPlacesList = placesList
        }
      } else if (this.state.placesInterest['empty-cat']) {
        newState.selectedPlacesList = this.state.placesInterest['empty-cat']
      }
    }

    this.setState(newState, () => {
      map.setState({ markers: [], polylines: this.state.randoPolylines || [], markersPlaces: [], clusters: null }, () => {
        if (this.state.selectedRando) {
          this.placesMarkersOnMap(this.state.randoMarkers)
        } else if (this.state.selectedPlace) {
          this.placesMarkersOnMap(this.state.selectedPlacesList)
          around(this, createCoords(this.state.selectedPlace.coord.lat, this.state.selectedPlace.coord.lon))
        } else if (this.state.selectedPlacesList && this.state.selectedPlacesList.length !== 0) {
          this.placesMarkersOnMap(this.state.selectedPlacesList)
        } else if (this.state.selectedCat) {
          this.placesMarkersOnMap(this.state.selectedCat)
        }
      })

      setTimeout(() => resize(map.props.isMobile))
    })
  }

  /**
   * Handle line selected
   * @param line
   * @param marker
   * @param onLoad
   * @returns {Promise<void>}
   */
  onLineSelected = async (line, marker, onLoad = false) => {
    const { url } = this.props
    this.setState({ timetable: false })
    const data = await selectLine(this, getLine(this, line))
    if (!onLoad) {
      const dir = line.direction_id ? line.direction_id : line.routes[0].direction_id
      let stopURL = ''
      if (this.state.selectedPlace) {
        const closerStopId = getCloserStopIdFromStopsList(this.state.selectedPlace.coord, data.stopsList, true)
        stopURL = '&stop=' + closerStopId
      }
      const search = url.search.split('&line=')[0].includes('stop=') ? url.search.split('&stop=')[0] : url.search.split('&line=')[0]
      this.props.history.push(
        url.pathname + search + '&line=' + line.id + '_' + dir + stopURL)
    }

    // Retrieve nearest stop for this line
    // const nearest = retrieveNearestStop(this, data.currentLine);

    // Avoid redraw if there is no data (error in selectLine() ?)
    if (data) {
      this.setState({ ...data }, () => {
        /* if (!marker && nearest) {
         marker = nearest.props.marker;
         } */
        // marker && schedules(this, marker, line);

        const currentURL = updateURLState(url)

        if (currentURL.stop) {
          const line = this.state.currentLine
          const stop = getStop(this, { id: currentURL.stop }, line)

          if (currentURL.date) {
            displayTimeTable(this, stop, line, currentURL.date)
          } else {
            schedules(this, stop, line)
          }
        }
      })
    }
  }

  onPlaceInteraction = (place, hover) => {
    const { map } = this.props

    const marker = map.state.markersPlaces.find(marker => marker.key === 'place-' + place.id)

    if (hover) {
      marker.props.onMouseOver()
    } else {
      marker.props.onMouseOut()
    }
  }

  onPlaceMarkerClick = place => {
    const { map, url } = this.props
    if (place.route) {
      this.onRoutePlaceSelected(place)
    } else {
      const cat = place.code.split('_')[0]
      let subcat = null

      if (place.subcat !== 'empty-subcat') {
        subcat = place.code.split('_')[1]
      }

      this.setState({ tab: 0, groups: [] }, () => {
        map.setState({ status: null }, () => {
          this.props.history.push(url.pathname + '?cat=' + cat + (subcat ? '&subcat=' + subcat : '') + '&place=' + place.id)
        })
      })
    }
  }

  onRoutePlaceSelected = place => {
    for (const statePlace of this.state.randoPlaces) {
      if (place.id === statePlace.id) {
        statePlace.opened = !statePlace.opened
      } else {
        statePlace.opened = false
      }
    }

    // Change the "see more" text
    const mores = document.querySelectorAll('.hikingDetailsMore')
    const more = mores[place.id - 1]
    // more.querySelector("span").innerHTML = place.opened ? "Voir moins" : "Voir plus";
    more.querySelector('img').style.transform = place.opened
      ? 'rotate(180deg)'
      : 'rotate(0deg)'

    this.setState({ randoPlaces: this.state.randoPlaces })
  }
  /**
   * select a place of interest
   * @param place
   */
  onPlaceSelected = place => {
    const { url } = this.props

    if (url.search) {
      this.props.history.push(url.pathname + url.search + '&place=' + place.id)
    } else {
      let newUrl = ''

      if (place.cat !== 'empty-cat') {
        newUrl += '?cat' + place.code.split('_')[0]

        if (place.subcat !== 'empty-subcat') {
          newUrl += '&subcat=' + place.code.split('_')[1]
        }
      } else {
        newUrl += '?place=' + place.id
      }

      this.props.history.push(newUrl)
    }

    this.setState({
      inputPlacesData: [],
      inputPlacesValue: ''
    })
  }

  onTabSelected = index => {
    const { map } = this.props
    this.setState({ tab: index }, () => {
      map.setState({ markers: [] }, () => {
        setTimeout(() => {
          if (index === 0) {
            renderMarkers(this)
          } else {
            renderPlaces(this)
          }
        })
      })
    })
  }

  placesMarkersOnMap = listPlaces => {
    const { map } = this.props
    const { randoMarkers, randoPolylines } = this.state
    const markers = []

    for (const place of listPlaces) {
      if (place.id) {
        const marker = {
          id: 'place-' + place.id,
          coord: place.coord,
          name: place.name,
          code: place.code.split('_')[0]
        }

        if (place.rando) {
          marker.rando = place.rando
        }

        const markerRendered = renderMarker(this, marker, {
          icon: {
            url: `assets/images/${marker.rando ? 'hiking' : this.props.data}/${marker.rando || marker.code}.svg`,
            size: {
              width: marker.rando ? 40 : 50,
              height: marker.rando ? 40 : 50
            },
            scaledSize: {
              width: marker.rando ? 40 : 50,
              height: marker.rando ? 40 : 50
            },
            anchor: {
              x: marker.rando ? 20 : 25,
              y: marker.rando ? 40 : 50
            }
          },
          marker,
          zIndex: 30
        }, place)

        markers.push(markerRendered)
      } else if (place.key.includes('hiking-places')) {
        markers.push(place)
      }
    }

    map.setState({ markersPlaces: [...markers], markers: [] }, () => {
      if (randoMarkers.length) {
        fitBounds(map, randoPolylines)
      } else {
        fitBounds(map, markers)
      }
    })
  }

  removeMarkers = () => {
    const { map } = this.props

    // TODO if places remove only bus markers
    if (map) {
      map.setState({
        markers: [],
        circle: null,
        infoboxs: []
      })
    }
  }

  removeZaePolygons = () => {
    const { map } = this.props
    const polygons = map.state.polygons.filter(polygon => !polygon.key.includes('polygon-zae'))

    map.setState({ polygons })
  }

  renderCat = () => {
    let displayHeadersubcat = false
    let name = null
    let picto = null
    let toDisplay = Object.keys(this.state.placesInterest)

    if (toDisplay[0] === 'empty-cat') {
      this.setState({
        selectedPlacesList: this.state.placesInterest[toDisplay[0]]
      })
    } else {
      displayHeadersubcat = true
      const subcategory = []

      if (this.state.selectedCat) {
        for (let place of this.state.selectedCat) {
          (subcategory[place.subcat] = subcategory[place.subcat] || []).push(place)
          name = place.cat
          picto = place.code.split('_')[0]
        }

        toDisplay = Object.keys(subcategory)

        if (toDisplay[0] === 'empty-subcat') {
          this.setState({ selectedPlacesList: this.state.selectedCat })
        }
      }
    }

    return <Fragment>
      {displayHeadersubcat && name && picto && this.state.selectedCat && (
        <div className='group selected'>
          <div className='groupName'>
            <div className='groupHead'>
              <img src={`assets/images/${this.props.data}/${picto}.svg`} alt='groupe' />
              <span>{name}</span>
            </div>
          </div>
        </div>
      )}
      <div className='scroll'>
        {toDisplay.sort(function (a, b) {
          return a.localeCompare(b)
        }).map(category =>
          <div
            key={category}
            className='group'
            onClick={this.state.selectedCat ? () => this.selectsubcat(category) : () => this.selectCat(category)}>
            <div className='groupName'>
              <div className='groupHead'>
                {!this.state.selectedCat && <img
                  src={this.state.selectedCat
                    ? `assets/images/${this.props.data}/${this.state.selectedCat[0].code.split('_')[0]}`
                    : `assets/images/${this.props.data}/${this.state.placesInterest[category][0].code.split('_')[0]}.svg`
                  } alt='groupe' />}
                <span>{category}</span>
              </div>
              <div className='arrowGroup'>
                <img className='closed' src='assets/images/v.svg' alt='arrow' />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  }

  renderInput = () => {
    const { data } = this.props

    return <Autocomplete
      inputProps={{ id: 'input-places-' + data }}
      wrapperStyle={{
        display: 'flex',
        flex: 1
      }}
      value={this.state.inputPlacesValue}
      items={this.state.inputPlacesData}
      getItemValue={item => item.name}
      onSelect={(valueInputSelect, dataInputSelect) => this.onPlaceSelected(dataInputSelect)}
      onChange={event => onChangeAutocompleteInput(event, this, 'inputPlaces')}
      renderMenu={children => <div className='autocomplete'>{children}</div>}
      renderInput={props => <input className='input' {...props} placeholder={'Nom d\'un point d\'intérêt...'} />}
      renderItem={(item, isHighlighted) => (
        <div className={'item' + (isHighlighted ? ' itemHighlight' : '')} key={item.id}>
          <img width={30} src={`assets/images/${this.props.data}/${item.code.split('_')[0]}.svg`} alt='categorie' />
          {item.name} ({item.city})
        </div>
      )}
    />
  }

  renderPlace = () => {
    const { map, theme, url } = this.props
    const { selectedPlace, selectedRando, randoPlaces } = this.state

    if (selectedPlace.zone.length > 1 && url.search.includes('place=')) {
      const polygons = map.state.polygons.filter(p => p.key === 'outline-polygon')

      axios.get('/api/file?folder=zae&ext=geojson&name=' + selectedPlace.zone)
        .then(response => {
          if (response.data) {
            const polygon = renderPolygon(invertCoords(response.data.features[0]), {
              strokeWeight: 1,
              strokeColor: '#' + theme.colors['primary'],
              fillOpacity: 0.08,
              fillColor: '#' + theme.colors['primary'],
              strokeOpacity: 1,
              clickable: false
            }, 'polygon-zae' + Math.floor(Math.random() * 100) + 1)
            polygons.push(polygon)

            map.setState({ polygons })
          }
        })
        .catch(error => {
          console.warn('Error', error.message)
        })
    }

    return selectedRando
      ? <Fragment>
        <div className='group selected'>
          <div className='groupName'>
            <div className='groupHead'>
              <img src={'assets/images/hiking/' + selectedRando.rando +
                '.svg'} style={{ height: 'auto' }} alt='groupe' />
              <div className='hikingPlace'>
                <span>{selectedPlace.name}</span>
                <div>
                  {selectedPlace.walk === '1' &&
                    <img src={'assets/images/hiking/walk.svg'} alt='walk' />}
                  {selectedPlace.bike === '1' &&
                    <img src={'assets/images/hiking/bike.svg'} alt='bike' />}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='scroll padding'>
          <div className='hikingDetails'>
            <img src={'assets/images/hiking/info.svg'} alt='groupe' />
            <div>
              <span>Départ / Arrivée :</span> {selectedPlace.direction}
              <br />
              <span>Distance :</span> {selectedPlace.length}
              <br />
              <span>Difficulté :</span> {selectedPlace.difficulty}
              <br />
              <span>Type : </span>
              {selectedPlace.walk === '1' ? (selectedPlace.bike === '1'
                ? 'A pied, à vélo'
                : 'A pied') : 'A vélo'}
              <br />
            </div>
          </div>
          {randoPlaces.map(place => (
            <div key={place.id}>
              <div className='hikingDetails'>
                <img src={'assets/images/hiking/places/' + place.id + '.svg'} alt='groupe' />
                <Collapse
                  className='hikingPlaceCollapse'
                  onClick={() => this.onRoutePlaceSelected(place)}
                  isOpened={place.opened}>
                  {place.description}
                </Collapse>
              </div>
              <div onClick={() => this.onRoutePlaceSelected(place)} className='hikingDetailsMore'>
                <div className='arrowDetails'>
                  <img className='closed' src='assets/images/v.svg' alt='arrow' />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
      : <Fragment>
        <div className='placeSelected'>
          <div className='groupName'>
            <div className='groupHead'>
              <img src={`assets/images/${this.props.data}/${selectedPlace.code.split('_')[0]}.svg`} alt='groupe' />
              <div>
                <span>{selectedPlace.name}</span>
                <br />
                {selectedPlace.address && <span className='address'>
                  <span>{selectedPlace.address}</span>
                  <br />
                  <span>
                    {selectedPlace.cp} {selectedPlace.city}
                  </span>
                </span>}
              </div>
            </div>
          </div>
        </div>
        {map && <Fragment>
          <div
            className='goToRoute'
            onClick={() => {
              const coord = selectedPlace.coord.lon + ';' + selectedPlace.coord.lat
              goRouteCalculation(this, coord)
            }}>
            <img src={'assets/images/menu/route-calculation.svg'} alt='itinéraire' />
              Calculer un itinéraire vers cette position
          </div>
          <Tabs
            selectedIndex={this.state.tab || 0}
            onSelect={index => this.onTabSelected(index)}
            selectedTabClassName='active'
            selectedTabPanelClassName='active scroll'>
            <TabList className='tabList'>
              <Tab className='tab'>Transport<br /><span>à 10 minutes</span></Tab>
              <Tab className='tab'>Lieux d'intérêt<br /><span>à 10 minutes</span></Tab>
            </TabList>
            <TabPanel className='tabPanel'>
              {renderLinesGroup(this)}
            </TabPanel>
            <TabPanel className='tabPanel'>
              {renderPlacesGroup(this)}
            </TabPanel>
          </Tabs>
        </Fragment>}
      </Fragment>
  }

  renderPlacesList = () => {
    const { url } = this.state

    if (url && url.cat === '10') {
      this.removeZaePolygons()
    }

    let name = this.state.selectedPlacesList[0].cat
    if (this.state.selectedPlacesList[0].subcat !== 'empty-subcat') {
      name = this.state.selectedPlacesList[0].subcat
    }

    return (
      <Fragment>
        {!this.state.selectedPlacesList[0].cat.includes('empty') && (
          <div className='group'>
            <div className='groupName'>
              <div className='groupHead'>
                <img
                  src={`assets/images/${this.props.data}/${this.state.selectedPlacesList[0].code.split('_')[0]}.svg`}
                  alt='groupe'
                />
                <span>{name}</span>
              </div>
            </div>
          </div>
        )}
        <div className='scroll'>
          {this.state.selectedPlacesList.map(place => {
            if (place.id) {
              return (
                <div
                  key={place.id}
                  className='group'
                  onMouseEnter={() => this.onPlaceInteraction(place, true)}
                  onMouseLeave={() => this.onPlaceInteraction(place)}
                  onClick={() => this.onPlaceSelected(place)}>
                  {place.rando
                    ? <div className='groupName'>
                      <div className='groupHead'>
                        <img src={`assets/images/hiking/${place.rando}.svg`} style={{ height: 'auto' }} alt='groupe' />
                        <div className='hikingPlace'>
                          <span>{place.name}</span>
                          <div>
                            {place.walk === '1' &&
                              <img src={'assets/images/hiking/walk.svg'} alt='walk' />}
                            {place.bike === '1' &&
                              <img src={'assets/images/hiking/bike.svg'} alt='bike' />}
                          </div>
                        </div>
                      </div>
                    </div>
                    : <div className='groupName'>
                      <div className='placesHead'>
                        <span>{place.name}</span>
                      </div>
                      <div className='arrowGroup'>
                        <img className='closed' src='assets/images/v.svg' alt='arrow' />
                      </div>
                    </div>
                  }
                </div>
              )
            }
            return false
          })}
        </div>
      </Fragment>
    )
  }

  /**
   * select a category
   * @param cat
   */
  selectCat = cat => {
    const { url } = this.props

    const codeCat = this.state.placesInterest[cat][0].code.split('_')[0]
    this.props.history.push(url.pathname + '?cat=' + codeCat)
  }

  /**
   * Select a subcategory
   * @param subcat
   */
  selectsubcat = subcat => {
    const { url } = this.props

    let codesubcat = 0
    for (let subcatList of this.state.selectedCat) {
      if (subcatList.subcat === subcat) {
        codesubcat = subcatList.code.split('_')[1]
        break
      }
    }

    this.props.history.push(
      url.pathname + url.search + '&subcat=' + codesubcat)
  }

  /**
   * Init our component
   */
  componentDidMount () {
    const { data, map, history } = this.props

    updateMapEvents(map, 'onClick', () => {
      map.setState({
        selectedInfobox: null,
        infoboxs: []
      })
    })

    updateMapEvents(map, 'onZoomChanged', () => {
      const path = history.location.pathname
      if (this.state.tab === 0 && Object.keys(this.state.groups).length > 0) { // Blocks onzoomchanged when displaying places of interest
        onZoomChanged(this, path)
      }
    })

    if (map) {
      // TODO : REACT_APP_TYPE === 'tcl' ? '/api/file?name=places' : '/api/places-data?data=places'
      axios.get('/api/places-data?data=' + data).then(response => {
        const keys = []
        let placesInterest = {}
        for (const place of response.data) {
          if (!keys.includes(place.cat)) {
            keys.push(place.cat)
          }
          (placesInterest[place.cat] = placesInterest[place.cat] || []).push(place)
        }

        // Order list
        placesInterest = Object.keys(placesInterest)
          .sort(function (a, b) {
            return a.localeCompare(b)
          })
          .reduce(function (sorted, key) {
            sorted[key] = placesInterest[key]
            return sorted
          }, {})
        this.setState({ placesInterest }, () => {
          this.loadData()
        })
      })
      this.removeEventListener = addResizeEvent(map.props.isMobile)
    }
  }

  /**
   * Detect url changes
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    if (this.props.location.search.split('&stop=')[0] !== prevProps.location.search.split('&stop=')[0]) {
      const url = updateURLState(this.props.location)

      this.setState({ url }, () => {
        this.loadData(url)
      })
    } else if (prevProps.url.key !== this.props.url.key) {
      const url = updateURLState(this.props.location)
      if (url.stop) {
        const line = this.state.currentLine
        const stop = getStop(this, { id: url.stop }, line)

        if (url.date) {
          displayTimeTable(this, stop, line, url.date)
        } else {
          schedules(this, stop, line)
        }
      }
    }
  }

  /**
   * Define url
   */
  componentWillMount () {
    this.setState({ url: updateURLState(this.props.url) })
  }

  /**
   * Clear the component, remove all state & events from the map
   */
  componentWillUnmount () {
    const { map } = this.props

    if (map) {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        selectedInfobox: null,
        circle: null,
        terminus: false,
        infoboxsTerminus: []
      })

      removeMapEvents(map)
      this.removeEventListener()
    }
  }

  render () {
    const { history } = this.props

    return this.state.currentLine ? (
      renderLine(this)
    ) : (
      <Fragment>
        {!history.location.pathname.includes('places-interest-') &&
        !this.state.selectedCat &&
        !this.state.selectedPlace && (
          <div className='form formNoBottom'>
            <div className='inputs'>{this.renderInput()}</div>
          </div>
        )}
        {this.state.selectedPlace
          ? this.renderPlace()
          : this.state.selectedPlacesList
            ? this.renderPlacesList()
            : this.renderCat()}
      </Fragment>
    )
  }
}

export default withRouter(PlacesInterest)
