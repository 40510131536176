import axios from 'axios'
import React, { Component } from 'react'
import Autocomplete from 'react-autocomplete'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const { REACT_APP_MAP_TYPE } = process.env

const {
  debounce
} = require(`../utils/${REACT_APP_MAP_TYPE}/map`)
const {
  geolocInput
} = require(`../utils/${REACT_APP_MAP_TYPE}/tools`)

class Modal extends Component {
  scrollingElement = null
  modal = null
  input = null

  componentDidMount () {
    this.scrollingElement = document.querySelector('#app')
    disableBodyScroll(this.scrollingElement)

    this.modal = document.querySelector('.modal')
    this.input = this.modal.querySelector('.input')
    this.input.focus()
  }

  componentWillUnmount () {
    enableBodyScroll(this.scrollingElement)
  }

  autocomplete = debounce(async (store, modalState, value) => {
    const response = await axios({
      url: '/api/autocomplete',
      params: {
        type: modalState.inputState,
        query: value,
        data: [{
          id: 'geoloc',
          name: 'Ma position',
          geolocation: true
        }]
      }
    })

    store.set(prev => ({
      ...prev,
      [modalState.inputState + 'Data']: response.data
    }))
  }, 500)

  /**
   * Update the store vith the current input value
   * @param e
   */
  onChange = async e => {
    const { store, modalState } = this.props
    const value = e.target.value

    this.autocomplete(store, modalState, value)

    store.set(prev => ({
      ...prev,
      [modalState.inputState + 'Value']: value
    }))
  }

  /**
   * Close the keyboard if enter key is pressed
   * @param e
   */
  onKeyDown = e => {
    console.log(e)
    if (e.keyCode === 13) {
      this.input.blur()
    }
  }

  render () {
    const { store, modalState } = this.props
    const { component } = modalState

    return (
      <div className='modal'>
        <div className='head'>
          <div className='close' onClick={this.props.toggleModal} />
          <Autocomplete
            getItemValue={item => item.name}
            wrapperStyle={{
              display: 'flex',
              flex: 1,
              position: 'relative'
            }}
            value={modalState[modalState.inputState + 'Value']}
            items={modalState[modalState.inputState + 'Data']}
            onSelect={async (value, data) => {
              const { map, url } = component.props
              let coord = null

              if (data.geolocation) {
                coord = await geolocInput()
              } else if (data.favorite) {
                coord = data.coords
              } else {
                coord = data.id
              }

              // Update the store with the current selected input value
              store.set(prev => ({
                ...prev,
                [modalState.inputState + 'Value']: value
              }))

              if (coord) {
                let newUrl = url.pathname

                // TODO must be a better way ...
                if (modalState.inputState === 'inputStart') {
                  if (!map.state.inputEndPin) {
                    newUrl += '?from=' + coord
                  } else if (map.state.inputEndPin) {
                    newUrl += '?from=' + coord + '&to=' + component.state.url.to
                  }
                } else {
                  if (!map.state.inputStartPin) {
                    newUrl += '?to=' + coord
                  } else if (map.state.inputStartPin) {
                    newUrl += '?from=' + component.state.url.from + '&to=' + coord
                  }
                }

                component.props.history.push(newUrl)
              } else {
                component.setState({
                  error: 'Erreur lors de la géolocalisation'
                })
              }

              this.props.toggleModal()
            }}
            onChange={this.onChange}
            renderMenu={children => <div className='autocomplete offsetRoute'>{children}</div>}
            renderInput={props => <input className='input' {...props} placeholder={modalState.inputState === 'inputStart' ? 'Départ' : 'Arrivée'} onKeyDown={this.onKeyDown} /* onBlur={this.props.toggleModal} */ />}
            renderItem={(item, isHighlighted) => <div className={'item' + (isHighlighted ? ' itemHighlight' : '')}
              key={item.id}>
              {item.embedded_type
                ? <img
                  width={30}
                  src={'assets/images/autocomplete/' + item.embedded_type + '.svg'}
                  alt={item.embedded_type}
                />
                : <img width={30} src={'assets/images/autocomplete/position.svg'} alt={'Ma position'} />}
              {item.name}
            </div>}
          />
        </div>
      </div>
    )
  }
}

export default Modal
