import axios from 'axios'
import { luminance } from 'luminance-js'
import moment from 'moment'
import Snackbar from 'node-snackbar'
import React, { Component, Fragment } from 'react'
import Autocomplete from 'react-autocomplete'
import { Collapse } from 'react-collapse'
import { DateCalendar, Time } from 'react-picker-date-and-time'
import { withRouter } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import SectionIcon from '../SectionIcon'
import L from 'leaflet'

const { REACT_APP_TYPE, REACT_APP_MAP_TYPE } = process.env

const {
  displayLineDecorator,
  displayLinePath,
  fitBounds,
  hidePinWhileAutocomplete,
  onChangeAutocompleteInput,
  onSelectAutocompleteInput,
  removeMapEvents,
  renderMarker,
  renderMarkerRouteCalculation,
  updateMapEvents
} = require(`../../utils/${REACT_APP_MAP_TYPE}/map`)
const {
  addResizeEvent,
  checkCoords,
  createCoords,
  dateToNavita,
  geocodPlace,
  geolocInput,
  resize,
  substringCoords,
  updateURLState
} = require(`../../utils/${REACT_APP_MAP_TYPE}/tools`)

// TODO put out ! use in around, route-calc, pois !
// ¿ put in App or board in will mount and passed in props to modules ?
const initAddresses = [
  {
    id: 'geoloc',
    name: 'Ma position',
    geolocation: true
  }
]

const noLink = {
  text: 'Fonctionnalité en cours de développement',
  actionText: 'Fermer',
  backgroundColor: '#e2001a',
  actionTextColor: '#333'
}

// needed to print
class Journey extends Component {
  renderImage = (staticMapURL) => <img className='onlyPrint staticMap' src={staticMapURL} alt='static map' />

  render () {
    const component = this.props.component

    if (!component.state.checkBoxMiniMap && !component.state.checkBoxRoadMap) {
      // nothing to print
    }

    return <div className={REACT_APP_TYPE === 'tcl' ? 'tcl-elevation' : ''}>
      <div className={component.state.checkBoxRoadMap ? '' : 'printHide'}>
        {REACT_APP_TYPE !== 'tcl' ? <div className='menuTitle routeHead'>
          FEUILLE DE ROUTE
          <div className='print printHide' onClick={() => component.setState({ print: true })} />
        </div> : <div className='share' onClick={() => Snackbar.show(noLink)} />}
        <div className='roadmap scroll'>
          {component.state.journey.sections.map((section, index) => component.renderSection(section, index))}
        </div>
      </div>
      {(component.state.staticMapURL && component.state.checkBoxMiniMap) &&
      this.renderImage(component.state.staticMapURL)}
      {(component.state.staticWalkMapURLS && component.state.checkBoxMiniMap) &&
      component.state.staticWalkMapURLS.map((staticWalkMapURL) => this.renderImage(staticWalkMapURL))}
    </div>
  };
}

/* global google */
class RouteCalculation extends Component {
  state = {
    checkBoxMiniMap: false,
    checkBoxRoadMap: true,
    dataSave: null,
    dateFormated: null,
    error: '',
    warning: '',
    inputEndData: initAddresses,
    inputEndValue: '',
    inputEndPlace: null,
    inputStartData: initAddresses,
    inputStartValue: '',
    inputStartPlace: null,
    journey: null,
    journeys: undefined,
    loading: false,
    modes: REACT_APP_TYPE === 'tcl' ? {
      represents: 'departure',
      metro: true,
      funiculaire: true,
      tramway: true,
      bus: true,
      bss: false,
      bike: false,
      car: false,
      pmr: false
    } : {
      represents: 'departure',
      pmr: false
    },
    now: 'maintenant',
    dates: false,
    prefs: false,
    print: false,
    represents: 'departure',
    selectedDate: null,
    selectedHours: null,
    selectedMinutes: null,
    softs: undefined,
    url: null
  }

  addActiveClass = index => {
    const nodes = document.querySelectorAll('.journeys .journey')
    const journeys = Array.from(nodes)

    for (const journey of journeys) {
      const classList = journey.classList

      if (classList.contains('journey_' + index)) {
        journey.classList.add('active')
      } else {
        journey.classList.remove('active')
      }
    }
  }

  /**
   * Launch an itineraries calcul
   * @returns {Promise<void>}
   */
  calcItineraries = () => {
    const { map, url } = this.props
    const currentUrl = updateURLState(url)
    const { inputStartPlace, inputEndPlace } = this.state

    if (!currentUrl.from) {
      this.setState({ warning: 'Veuillez sélectionner une adresse de départ' })
    }

    if (!currentUrl.to) {
      this.setState({ warning: 'Veuillez sélectionner une adresse d\'arrivée' })
    }

    if (!currentUrl.from && !currentUrl.to) {
      this.setState({ warning: 'Veuillez sélectionner des adresses' })
    }

    const modesParams = this.paramsToString(this.state.modes)
    const validModes = REACT_APP_TYPE === 'tcl' ? ['metro', 'funiculaire', 'tramway', 'bus', 'bss', 'bike', 'car'].some(
      mode => modesParams.includes(mode)) : true
    if (currentUrl.from && currentUrl.to && validModes) {
      this.setState({ loading: true, error: '', warning: '' }, () => {
        // BLOCK MARKERS
        const currentUrl = updateURLState(url)
        if (inputStartPlace) {
          this.createMarker(createCoords(inputStartPlace.coord.lat, inputStartPlace.coord.lon), 'inputStart')
        } else {
          this.createMarker(createCoords(currentUrl.from.split(';')[1], currentUrl.from.split(';')[0]), 'inputStart')
        }

        if (inputEndPlace) {
          this.createMarker(createCoords(inputEndPlace.coord.lat, inputEndPlace.coord.lon), 'inputEnd')
        } else {
          this.createMarker(createCoords(currentUrl.to.split(';')[1], currentUrl.to.split(';')[0]), 'inputEnd')
        }

        const time = this.state.dateFormated ||
          dateToNavita(this.state.selectedDate, this.state.selectedHours, this.state.selectedMinutes)

        let journey = null
        axios.get('/api/itinerary', {
          params: {
            datetime: time,
            from: currentUrl.from,
            to: currentUrl.to,
            params: modesParams
          }
        }).then(async response => {
          if (this.state.url.journey) {
            if (this.state.url.journey === 'bike' || this.state.url.journey === 'bss' || this.state.url.journey ===
              'walk') {
              journey = response.data.softs[this.state.url.journey][0]
            } else {
              journey = response.data.journeys.filter(j => j.sections.length !== 1 && j.sections[0].mode === 'walking')[this.state.url.journey]
            }
          }

          // Sort journeys
          const tc = []
          const combined = []
          let journeys = response.data.journeys.filter(j => j.sections.length !== 1 && j.sections[0].mode === 'walking')

          if (journeys) {
            journeys.map((journey, index) => {
              // Only possible on new Navitia 'cause we based it on "distances" key of a journey
              const distances = journey.distances

              // Save the current index in the main array of the journey
              journey.mainIndex = index

              if (distances && (distances.bike > 0 || distances.car > 0)) {
                combined.push(journey)
              } else {
                tc.push(journey)
              }

              return null
            })

            // TODO Find a better way to store real index of the journey...
            const realOrder = [...tc, ...combined]
            realOrder.map((journey, index) => {
              journeys[index].realIndex = journeys.indexOf(journey)

              return null
            })
          }

          this.setState({
            dataSave: response.data,
            error: '',
            journey,
            tc,
            combined,
            journeys,
            loading: false,
            softs: response.data.softs
          }, () => {
            if (!this.state.dateFormated) {
              const date = this.state.selectedDate.format('YYYYMMDD') + 'T' + this.state.selectedHours +
                this.state.selectedMinutes + '00'
              this.props.history.push(url.pathname + url.search + '&date=' + date + '&modes=' + modesParams)
            }

            this.displayJourneys(this.state.journeys, map)
          })
        }).catch(e => {
          console.warn('Error : ', e || e.response.data.id)

          this.setState({
            error: e.response && e.response.data.id === 'date_out_of_bounds'
              ? 'Impossible de calculer un itinéraire à cette date'
              : 'Impossible de calculer un itinéraire',
            loading: false
          })
        }).finally(() => {
          // UNBLOCK MARKERS
          if (inputStartPlace) {
            this.createMarker(createCoords(inputStartPlace.coord.lat, inputStartPlace.coord.lon), 'inputStart')
          } else {
            this.createMarker(createCoords(currentUrl.from.split(';')[1], currentUrl.from.split(';')[0]), 'inputStart')
          }

          if (inputEndPlace) {
            this.createMarker(createCoords(inputEndPlace.coord.lat, inputEndPlace.coord.lon), 'inputEnd')
          } else {
            this.createMarker(createCoords(currentUrl.to.split(';')[1], currentUrl.to.split(';')[0]), 'inputEnd')
          }
        })
      })
    } else if (!validModes) {
      this.setState({ error: '', warning: 'Veuillez sélectionner au moins un mode de transport' })
    }
  }

  /**
   * Create marker
   * @param position
   * @param selectedMarker
   * @returns {{markers: Array}}
   */
  createMarker = (position, selectedMarker) => {
    const { map } = this.props
    const markers = map.state.markers
    const inputStartPin = renderMarkerRouteCalculation('inputStart-pin', this, position)
    const inputEndPin = renderMarkerRouteCalculation('inputEnd-pin', this, position)

    const state = {}

    if (selectedMarker === 'inputStart') {
      const startMarker = markers.find(marker => marker.key === 'inputStart-pin')

      if (startMarker) {
        markers.splice(markers.indexOf(startMarker), 1)
      }

      markers.push(inputStartPin)
      state.inputStartPin = inputStartPin
    } else if (selectedMarker === 'inputEnd') {
      const endMarker = markers.find(marker => marker.key === 'inputEnd-pin')

      if (endMarker) {
        markers.splice(markers.indexOf(endMarker), 1)
      }

      markers.push(inputEndPin)
      state.inputEndPin = inputEndPin
    }

    // Wtf leaflet
    state.markers = [...markers]

    map.setState({
      ...state
    }, async () => {
      if (map.state.markers.length === 2) {
        fitBounds(map, map.state.markers)
      }

      const data = this.state[selectedMarker + 'Place'] || await geocodPlace(position)
      if (data.id !== 'no_places') {
        onSelectAutocompleteInput(data, this, selectedMarker)
      } else {
        const newState = {}
        newState.warning = 'Aucune adresse trouvée pour '
        if (selectedMarker === 'inputStart') {
          newState.inputStartData = initAddresses
          newState.inputStartValue = ''
          newState.warning += ' le départ'
        } else {
          newState.inputEndData = initAddresses
          newState.inputEndValue = ''
          newState.warning += ' l\'arrivée'
        }
        this.setState(newState)
      }
    })
  }

  direction = direction => {
    if (direction === 0) {
      return <span className='walkDirection'>
        <img src='assets/images/directions/straight.svg' alt='Tout droit' />
        <span>Continuer</span>
      </span>
    } else if (direction < 0) {
      return <span className='walkDirection'>
        <img src='assets/images/directions/left.svg' alt='Tourner à gauche' />
        <span>Tourner à gauche</span>
      </span>
    } else if (direction > 0) {
      return <span className='walkDirection'>
        <img src='assets/images/directions/right.svg' alt='Tourner à droite' />
        <span>Tourner à droite</span>
      </span>
    }
  }

  displayJourney = (journey, index, shouldBeMain, polylines, markers, decorators) => {
    const main = index === shouldBeMain

    journey.sections.map(async section => {
      // Fix BSS no geojson
      if (!section.geojson) {
        return
      }

      const data = await displayLinePath(section, this.props.map, true, main, index, shouldBeMain, this)

      if (main) {
        // decorators
        REACT_APP_MAP_TYPE === 'leaflet' && decorators.push(displayLineDecorator(this.props.map, data[0], section))
      }

      if (section.type === 'public_transport' && main) {
        for (let i = 0; i < section.stop_date_times.length; i++) {
          const stop = section.stop_date_times[i]

          markers.push(
            renderMarker(this, stop.stop_point, {
              key: section.id + '_' + stop.stop_point.id,
              icon: REACT_APP_MAP_TYPE === 'leaflet' ? new L.DivIcon({
                className: 'leaflet-bus-icon',
                iconSize: [6, 6],
                iconAnchor: [3, 3],
                html: `<span style="
                  border: 2px solid #${(section.display_informations.color !== '000000'
                  ? section.display_informations.color
                  : '576369')}" />`
              }) : {
                path: 'M8.5,12a3.5,3.5 0 1,0 7,0a3.5,3.5 0 1,0 -7,0',
                strokeColor: '#' +
                  (section.display_informations.color !== '000000' ? section.display_informations.color : '576369'),
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: 'white',
                fillOpacity: 1,
                scale: 1.2,
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(12, 12),
                zIndex: 10
              }
            }))
        }
      } else if (section.mode !== 'walking' && main) {
        const icon = REACT_APP_MAP_TYPE === 'leaflet' ? new L.DivIcon({
          className: 'leaflet-bus-icon',
          iconSize: [6, 6],
          iconAnchor: [3, 3],
          html: `<span style="border: 2px solid #576369" />`
        }) : {
          path: 'M8.5,12a3.5,3.5 0 1,0 7,0a3.5,3.5 0 1,0 -7,0',
          strokeColor: '#576369',
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: 'white',
          fillOpacity: 1,
          scale: 1.2,
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(12, 12),
          zIndex: 10
        }

        // Start
        markers.push(
          renderMarker(this, section.from[section.from.embedded_type], {
            key: section.id + '_' + section.from.id,
            icon
          }))

        // End
        markers.push(
          renderMarker(this, section.to[section.to.embedded_type], {
            key: section.id + '_' + section.to.id,
            icon
          }))
      }

      if (data) {
        polylines.push(...data)
      }
    })
  }

  displayJourneys = async (journeys, map, shouldBeMain) => {
    const polylines = []
    const decorators = []
    const markers = this.props.map.state.markers.filter(marker => marker.key.includes('-pin'))

    if (!this.state.journey && this.state.journeys) {
      // Add active class
      if (!shouldBeMain && shouldBeMain !== 0) {
        shouldBeMain = this.state.journeys.length ? this.state.journeys[0].realIndex : 0
      }
      for (const journey of journeys) {
        const index = journeys.indexOf(journey)
        // await this.displayJourney(journey, index === shouldBeMain, polylines, markers, decorators)
        await this.displayJourney(journey, index, shouldBeMain, polylines, markers, decorators)
      }
    } else {
      await this.displayJourney(this.state.journey, 0, 0, polylines, markers, decorators)
    }

    // Highlight the hovered choice
    this.addActiveClass(shouldBeMain)

    // TODO trouver d'où vient la merde qui fait que ça ne dessine pas sur la carto
    setTimeout(() => {
      map.setState({
        polylines,
        polylineDecorators: REACT_APP_MAP_TYPE === 'leaflet' ? [...decorators] : [],
        markers
      }, () => {
        setTimeout(() => {
          // this.state.journeys.length && fitBounds(map, polylines)
          resize(map.props.isMobile)
        })
      })
    })
  }

  duration = value => {
    const seconds = parseInt(value, 10)
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.ceil((seconds % 3600) / 60)

    return {
      hours,
      minutes
    }
  }

  focusInput = (e, inputState) => {
    const { map, store, modalState } = this.props
    const { isMobile } = map.props
    const input = e.target

    if (isMobile) {
      input.blur()
      store.set({ ...modalState, inputState, component: this })
      this.props.toggleModal()
    }
  }

  // TODO ...
  generateStaticMap = () => {
    const beginURL = 'https://maps.googleapis.com/maps/api/staticmap?size=650x450&'
    const keyURL = '&key=AIzaSyAAksPgzY3wwhirgCyzdpF4pECkjK4PJDE'
    const flagStartURL = 'https://ccve.dev.latitude-cartagene.com/assets/images/flag-start.png'
    const flagEndURL = 'https://ccve.dev.latitude-cartagene.com/assets/images/flag-end.png'
    const anchorFlagStart = 'anchor:24,24%7C'
    const anchorFlagEnd = ''
    let markersURL = ''
    let pathsURL = ''
    let walkURLS = []

    for (let marker of this.props.map.state.markers) { // Add begin and end markers
      if (marker.key.includes('input')) { // Check if marker is a start or end marker
        const flagURL = marker.key.includes('Start') ? flagStartURL : flagEndURL
        let markerURL = marker.key.includes('Start')
          ? '&markers=' + anchorFlagStart + 'icon:' + flagURL + '%7C'
          : '&markers=' + anchorFlagEnd + 'icon:' + flagURL + '%7C' // 1 &markers= for each marker (needed to have multiple marker styles)
        const lat = marker.props.position.lat()
        const lng = marker.props.position.lng()
        markerURL += lat + ',' + lng + '%7C'
        markersURL += markerURL
      }
    }

    for (let polyline of this.props.map.state.polylines) {
      let path = []
      const walk = polyline.props.options.strokeColor === '#333'

      for (let pathitem of polyline.props.path) {
        path.push(pathitem)
      }

      let color = polyline.props.options.strokeColor
      color = color.substring(1, color.length) // REMOVE #
      let newcolor = ''

      if (color.length === 3) { // then double all char
        for (let char of color) {
          newcolor += char + char
        }
      } else { // DO NOTHING
        newcolor = color
      }

      color = '0x' + newcolor + 'FF' // FF = 100% OPACITY
      const pathencoded = google.maps.geometry.encoding.encodePath(path)
      let pathURL = '&path='
      pathURL += 'color:' + color + '%7C' // PARAM : COLOR

      if (walk) {
        pathURL += 'weight:2%7C' // PARAM : WEIGHT  (thinner walk path)
      }

      pathURL += 'enc:' + pathencoded // PARAM : PATH
      if (walk) { // create URLs for walk polylines
        const markerStartPosition = path[0]
        const markerEndPosition = path[path.length - 1]
        const markerStart = 'markers=' + anchorFlagStart + 'icon:' + flagStartURL + '%7C' + markerStartPosition.lat() +
          ',' + markerStartPosition.lng()
        const markerEnd = '&markers=' + anchorFlagEnd + 'icon:' + flagEndURL + '%7C' + markerEndPosition.lat() + ',' +
          markerEndPosition.lng()
        const walkURL = markerStart + markerEnd + pathURL
        walkURLS.push(beginURL + walkURL + keyURL)
      }

      pathsURL += pathURL
    }

    const url = beginURL + markersURL + pathsURL + keyURL // Final URL for the entire map

    this.setState({
      staticMapURL: url,
      staticWalkMapURLS: walkURLS
    })
  }

  getSelectedDate = date => {
    this.setState({ selectedDate: date }, () => this.updateOptionsText())
  }

  getSelectedHours = hours => {
    this.setState({ selectedHours: hours }, () => this.updateOptionsText())
  }

  getSelectedMinutes = minutes => {
    this.setState({ selectedMinutes: minutes }, () => this.updateOptionsText())
  }

  handleModeChanged = mode => {
    this.setState({
      modes: {
        ...this.state.modes,
        [mode.id]: !this.state.modes[mode.id]
      }
    })
  }

  handlePMRChanged = event => {
    this.setState({
      modes: {
        ...this.state.modes,
        pmr: event.target.checked
      }
    })
  }

  handleRepresentsChanged = event => {
    this.setState({
      modes: {
        ...this.state.modes,
        represents: event.target.value
      }
    })
  }

  /**
   * Invert from and to
   */
  invertEntries = () => {
    const inputStartValue = this.state.inputEndValue
    const inputEndValue = this.state.inputStartValue
    const inputStartData = this.state.inputEndData
    const inputEndData = this.state.inputStartData

    this.setState({
      inputStartValue,
      inputEndValue,
      inputStartData,
      inputEndData
    }, async () => {
      let newUrl = this.props.url.pathname

      if (this.state.url.to) {
        newUrl += '?from=' + this.state.url.to
      } else {
        hidePinWhileAutocomplete(this, 'inputStart')
      }

      if (this.state.url.from) {
        newUrl.includes('from') ? (newUrl += '&to=') : (newUrl += '?to=')
        newUrl += this.state.url.from
      } else {
        hidePinWhileAutocomplete(this, 'inputEnd')
      }

      this.props.history.push(newUrl)
    })
  }

  loadData = async url => {
    const { map } = this.props

    const newState = {
      journey: null,
      journeys: undefined
    }

    map.setState({
      // polylines: [],
      polylineDecorators: [],
      markers: [],
      infoboxs: []
    })

    if (url.from || url.to) {
      if (url.from && map) {
        await checkCoords(url.from, 'inputStart', this)
      }

      if (url.to && map) {
        await checkCoords(url.to, 'inputEnd', this)
      }
    }

    if (url.date) {
      if (!this.state.dataSave) {
        const updateState = {
          dateFormated: url.date
        }
        if (url.modes) {
          this.paramsToObject(updateState, url.modes)
        }
        this.setState(updateState, () => {
          this.calcItineraries()
        })
      } else {
        if (url.journey) {
          if (url.journey === 'bike' || url.journey === 'bss' || url.journey === 'walk') {
            newState.journey = this.state.softs[url.journey][0]
          } else {
            newState.journey = this.state.journeys[url.journey]
          }
          newState.journeys = this.state.journeys
        } else {
          newState.journeys = this.state.dataSave.journeys.filter(j => j.sections.length !== 1 && j.sections[0].mode === 'walking')
          newState.softs = this.state.dataSave.softs
        }
      }
    }

    this.setState(newState, () => {
      if (this.state.journey || this.state.journeys) {
        this.displayJourneys(this.state.journeys, map)
      }

      // Zoom on selected journey
      this.state.journey && setTimeout(() => fitBounds(map, map.state.polylines))
    })
  }

  navitiaToDate = date => {
    const time = date.split('T')[1]
    return time.substring(0, 2) + 'h' + time.substring(2, 4)
  }

  onBackToParams = () => {
    if (this.state.journeys !== undefined) {
      this.setState({ journeys: null }, () => {
        this.props.map.setState({
          polylines: [],
          markers: this.props.map.state.markers.filter(
            marker => marker.key.includes('-pin'))
        })
      })
    }
  }

  onSectionSelected = section => {
    const { map } = this.props

    if (section.geojson) {
      let coords = section.geojson.coordinates

      if (REACT_APP_MAP_TYPE === 'leaflet') {
        coords = coords.map(coord => [coord[1], coord[0]])
      }

      fitBounds(map, coords)
    }
  }

  paramsToString = (modes) => {
    return Object.keys(modes).reduce((acc, key) => {
      const value = modes[key]

      acc += ','

      switch (key) {
        case 'represents':
          acc = acc + (value !== 'arrival' ? 'departure' : 'arrival')
          break

        default:
          acc = acc + (value ? key : '')
          break
      }

      return acc.replace(/(^,)|(,$)/g, '')
    }, '')
  }

  paramsToObject = (state, string) => {
    const modes = {}
    const params = string.split(',')

    for (let i = 0; i < params.length; i++) {
      const param = params[i]

      if (i === 0 && (param === 'deparutre' || param === 'arrival')) {
        modes['represents'] = param !== 'arrival' ? 'departure' : 'arrival'
      } else {
        modes['represents'] = 'departure'
      }
      if (Object.keys(this.state.modes).includes(param) && param !== 'represents') {
        modes[param] = true
      }
    }

    state['modes'] = modes
    return state
  }

  renderInput = position => {
    const inputState = 'input' + position[0].toUpperCase() + position.slice(1)

    return <div className='inputCalculation'>
      <img src={'assets/images/flag-' + position + '.svg'} alt={position} />
      <Autocomplete
        inputProps={{ id: 'input-' + position }}
        wrapperStyle={{
          display: 'flex',
          flex: 1,
          position: 'relative'
        }}
        value={this.state[inputState + 'Value']}
        items={this.state[inputState + 'Data']}
        getItemValue={item => item.name}
        onSelect={async (valueInputSelect, dataInputSelect) => {
          const { map, url } = this.props
          let coord = null

          if (dataInputSelect.geolocation) {
            coord = await geolocInput()
          } else if (dataInputSelect.favorite) {
            coord = dataInputSelect.coords
          } else {
            // coord = dataInputSelect[dataInputSelect.embedded_type].coord
            coord = dataInputSelect.id
          }

          if (coord) {
            let newUrl = url.pathname

            // TODO must be a better way ...
            if (position === 'start') {
              if (!map.state.inputEndPin) {
                newUrl += '?from=' + coord
              } else if (map.state.inputEndPin) {
                newUrl += '?from=' + coord + '&to=' + this.state.url.to
              }
            } else {
              if (!map.state.inputStartPin) {
                newUrl += '?to=' + coord
              } else if (map.state.inputStartPin) {
                newUrl += '?from=' + this.state.url.from + '&to=' + coord
              }
            }

            this.props.history.push(newUrl)
          } else {
            this.setState({
              error: 'Erreur lors de la géolocalisation'
            })
          }
        }}
        onChange={event => onChangeAutocompleteInput(event, this, inputState)}
        renderMenu={children => <div className='autocomplete offsetRoute'>{children}</div>}
        renderInput={props => <input className='input' {...props} onFocus={(e) => this.focusInput(e, inputState)}
          placeholder={position === 'start' ? 'Départ' : 'Arrivée'} />}
        renderItem={(item, isHighlighted) => <div className={'item' + (isHighlighted ? ' itemHighlight' : '')}
          key={item.id}>
          {item.embedded_type
            ? <img
              width={30}
              src={'assets/images/autocomplete/' + item.embedded_type + '.svg'}
              alt={item.embedded_type}
            />
            : <img width={30} src={'assets/images/autocomplete/position.svg'} alt={'Ma position'} />}
          {item.name}
        </div>}
      />
    </div>
  }

  renderJourneys = journeys => {
    const { map } = this.props

    return journeys.map((journey, index) =>
      <div className={'journey' + (index === 0 ? ' active' : '') + ' journey_' + journey.mainIndex} key={journey.type + '_' + index}
        onClick={() => this.props.history.push(
          this.props.url.pathname + this.props.url.search + '&journey=' + journey.mainIndex)}
        onMouseEnter={() => this.displayJourneys(this.state.journeys, map, journey.mainIndex)}>
        <div className='journeyHead'>
          <div className='time'>
            Arrivée à {' '}
            <span className='lightBold'>{this.navitiaToDate(
              journey.sections[journey.sections.length - 1].arrival_date_time)}</span>
          </div>
          <div className='walk' title='Temps de marche à pied'>
            <SectionIcon image='modes/walk' className='walk' type='walk'
              options={{ height: 12, padding: '0 10px 0 0' }} />
            {Math.round(journey.sections.reduce((acc, section) => {
              if (section.type === 'street_network' || section.type === 'transfer') {
                return acc + section.duration
              } else {
                return acc
              }
            }, 0) / 60)} min
          </div>
          <div className='co2' title="Émissions de CO2 dues à l'énergie consommée sur ce trajet">
            <SectionIcon image='leaf' className='leaf' type='leaf'
              options={{ height: 12, padding: '0 10px 0 0' }} />
            {Math.round(journey.co2_emission.value)}g
          </div>
        </div>
        <div className='journeyContent'>
          <div className='journeyTime'>
            <span style={{ display: 'block', fontSize: '1.2em', fontWeight: 600 }}>
              {this.duration(journey.duration).hours > 0
                ? '> 1'
                : this.duration(journey.duration).minutes}
            </span>
            {this.duration(journey.duration).hours > 0
              ? 'heure'
              : 'minutes'}
          </div>
          <div className='journeySections'>
            {journey.sections.filter(section => [ // Filter all sections and retrieve only few types
              'public_transport',
              'bss_rent',
              'park',
              'street_network'
            ].includes(section.type)).map((section, index, sections) => {
              if ((section.type === 'street_network' && section.mode !== 'car' && section.mode !== 'bike' && section.duration < 300) || (section.type === 'park' || section.type === 'bss_rent')) {
                return false
              }

              // Retrieve the next section to know if we have to add a delimiter...
              const next = index < sections.length - 1 && sections[index + 1]

              return <Fragment key={section.id}>
                <div className='section'>
                  {this.switchSectionType(section, null)}
                </div>
                {next && (index < sections.length - 2 || next.duration > 300) ? <span className='delimiter'>&#8212;</span> : ''}
              </Fragment>
            })}
            <div className='journeyOffset' />
          </div>
          <div className='journeySectionsScroll'>
            <div className='itemArrow' />
          </div>
        </div>
      </div>
    )
  }

  getAvailability = previous => {
    // TODO plural
    return <div>
      {previous.type === 'bss_rent' && (previous.realtime ? previous.realtime.available_bikes : 'X') + (previous.realtime ? (previous.realtime.available_bikes > 1 ? ' vélos disponibles' : ' vélo disponible') : ' vélos disponibles')}
      {previous.type === 'bss_put_back' && (previous.realtime ? previous.realtime.available_places : 'X') + (previous.realtime ? (previous.realtime.available_places > 1 ? ' places restantes' : ' place restante') : ' places restantes')}
      {previous.type === 'park' && 'XX places restantes'}
    </div>
  }

  renderSection = (section, index) => {
    const { map } = this.props
    const { isMobile } = map.props

    // Used only for waiting sections margin top offset
    const previous = index > 0 && this.state.journey.sections[index - 1]
    const next = index < this.state.journey.sections.length - 2 && this.state.journey.sections[index + 1]

    switch (section.type) {
      case 'street_network':
        return <div onClick={() => this.onSectionSelected(section)} key={section.id}>
          {index === 0 && <div className='segmentTop'>
            <div className='segmentTime'>{this.navitiaToDate(section.departure_date_time)}</div>
            <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)}>
              {/* <div className='segmentBull' /> */}
              <img src='assets/images/flag-start.svg' alt='flag-start' style={{
                width: 30,
                height: 30,
                padding: isMobile ? '0 0 0 5px' : '0 5px',
                marginTop: -7
              }} />
            </div>
            <strong>{section.from.name}</strong>
          </div>}
          <div className='segmentContent'>
            <div className='segmentSectionType'>{this.switchSectionType(section, null)}</div>
            <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)} />
            <div className='segmentDetails'>
              <span className='little'>
                {this.getAvailability(previous)}
              </span>
              <div
                className='details'
                onClick={e => {
                  section.opened = !section.opened

                  // Stop propagation
                  e.stopPropagation()

                  this.setState({ journey: this.state.journey })
                }}>
                <span className='detailsCollapse'>{
                  this.duration(section.duration).minutes + (
                    previous.type === 'park' || previous.type === 'bss_put_back'
                      ? this.duration(previous.duration).minutes
                      : next.type === 'bss_rent' ? this.duration(next.duration).minutes : 0)
                } min {
                  section.mode === 'bike'
                    ? 'à vélo'
                    : section.mode === 'car' ? 'en voiture' : 'à pied'}</span>
                <span className='printHide'><img src='assets/images/v.svg' alt='arrow' /></span>
              </div>
              <Collapse isOpened={!!section.opened}>
                {section.path.map((path, index) =>
                  <div key={index} className='path'>
                    {this.direction(path.direction)}
                    {path.name && <span> sur {path.name}</span>} ({path.length}m)
                  </div>
                )}
              </Collapse>
            </div>
          </div>
          {((index > 0 && previous.type !== 'park' && next.type !== 'bss_rent' && previous.type !== 'bss_put_back') || index === this.state.journey.sections.length - 1) &&
          <div className='segmentBottom'>
            <div className='segmentTime'>
              {this.navitiaToDate(section.arrival_date_time)}
            </div>
            <div className='segmentBorder' style={next.type === 'bss_put_back' ? this.retrieveBorderStyleFromType(next) : { border: 'none' }}>
              {index === this.state.journey.sections.length - 1 ? <img src='assets/images/flag-end.svg' alt='flag-start' style={{
                width: 30,
                height: 30,
                padding: isMobile ? '0 0 0 5px' : '0 5px',
                marginTop: -7
              }} /> : <div className='segmentBull' />}
            </div>
            <div className='segmentTo'>
              {next.type === 'bss_put_back' ? 'Posez votre vélo à la station ' : ''} <strong>{section.to.name}</strong>
            </div>
          </div>}
        </div>
      case 'public_transport':
        return <div onClick={() => this.onSectionSelected(section)} key={section.id}>
          <div className='segmentTop'>
            <div className='segmentTime'>
              {this.navitiaToDate(section.departure_date_time)}
            </div>
            <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)}>
              <div className='segmentBull'
                style={section.display_informations && {
                  borderColor: '#' +
                    (section.display_informations.color !== '000000' ? section.display_informations.color : '576369')
                }}
              />
            </div>
            <div className='segmentFrom'>{section.from && <strong>{section.from.name}</strong>}</div>
          </div>
          <div className='segmentContent'>
            <div className='segmentSectionType'>{this.switchSectionType(section, null)}</div>
            <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)} />
            <div className='segmentDetails'>
              <span>
                Prendre le <strong>
                  {section.display_informations.physical_mode.toLowerCase()} {section.display_informations.code}
                </strong> à l'arrêt <strong>{section.from.stop_point.name}</strong> en direction de <strong>{section.display_informations.direction}</strong><br />
                Descendre à l'arrêt <strong>{section.to.stop_point.name}</strong><br /><br />
                <div className='details' onClick={e => {
                  section.opened = !section.opened

                  // Stop propagation
                  e.stopPropagation()

                  this.setState({
                    journey: this.state.journey
                  })
                }}>
                  <span className='detailsCollapse'>
                    {this.duration(section.duration).minutes} min ({section.stop_date_times.length} arrêts)
                  </span>
                  <span className='printHide'><img src='assets/images/v.svg' alt='arrow' /></span>
                </div>
                <Collapse isOpened={!!section.opened} className='collapse'>
                  {section.stop_date_times.slice(0, -1).map(stop => (
                    <div key={stop.stop_point.id} className='innerStops'>
                      <div className='innerStopBull'
                        style={section.display_informations && {
                          borderColor: '#' + (section.display_informations.color !== '000000'
                            ? section.display_informations.color
                            : '576369')
                        }}
                      />
                      {stop.stop_point.name}
                    </div>
                  ))}
                </Collapse>
              </span>
            </div>
          </div>
          <div className='segmentBottom'>
            <div className='segmentTime'>{this.navitiaToDate(section.arrival_date_time)}</div>
            <div className='segmentBorder'
              style={{
                borderWidth: 0,
                backgroundImage: 'linear-gradient(to bottom, #333 50%, rgba(255, 255, 255, 0) 0%)',
                backgroundPosition: 'center',
                backgroundSize: '2px 8px',
                backgroundRepeat: 'repeat-y'
              }}>
              <div className='segmentBull'
                style={section.display_informations && {
                  borderColor: '#' +
                    (section.display_informations.color !== '000000' ? section.display_informations.color : '576369')
                }}
              />
            </div>
            <div className='segmentTo'>
              <strong>{section.to.name}</strong>
            </div>
          </div>
        </div>
      case 'transfer':
        return <div onClick={() => this.onSectionSelected(section)} key={section.id} className='segmentContent'>
          <div className='segmentSectionType'>{this.switchSectionType(section, null)}</div>
          <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)} />
          <div className='segmentDetails'>{<div className='details'>{this.duration(section.duration).minutes} min à
            pied</div>}</div>
        </div>
      case 'waiting':
        return <div key={section.id} className={'segmentContent ' + section.type}
          style={previous && previous.type === 'transfer' ? { marginTop: -40 } : {}}>
          <div className='segmentSectionType'>{this.switchSectionType(section, null)}</div>
          <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)} />
          <div className='segmentDetails'>{
            <div className='details' style={{
              color: '#333',
              fontWeight: 'initial',
              marginTop: 5
            }}>{this.duration(section.duration).minutes} min d'attente</div>}</div>
        </div>
      case 'park':
        return <div onClick={() => this.onSectionSelected(section)} key={section.id}>
          <div className='segmentTop'>
            <div className='segmentTime'>
              {this.navitiaToDate(section.departure_date_time)}
            </div>
            <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)}>
              <div className='segmentBull'
                style={section.display_informations && {
                  borderColor: '#' +
                    (section.display_informations.color !== '000000' ? section.display_informations.color : '576369')
                }}
              />
            </div>
            <div className='segmentFrom'>
              Garez votre voiture au <strong>{section.from.name}</strong>
            </div>
          </div>
        </div>
      case 'bss_rent':
        return <div onClick={() => this.onSectionSelected(section)} key={section.id}>
          <div className='segmentBottom'>
            <div className='segmentTime'>
              {this.navitiaToDate(section.arrival_date_time)}
            </div>
            <div className='segmentBorder' style={this.retrieveBorderStyleFromType(section)}>
              <div className='segmentBull' />
            </div>
            <div className='segmentTo'>
              Prendre un vélo à la station <strong>{section.to.name}</strong>
            </div>
          </div>
        </div>
      default:
        console.warn('Section of type ' + section.type + ' is not handle yet')
        break
    }
  }

  retrieveBorderStyleFromType = section => {
    switch (section.type) {
      case 'street_network':
      case 'waiting':
      case 'transfer':
      case 'park':
      case 'bss_rent':
      case 'bss_put_back':
        return {
          borderWidth: 0,
          backgroundImage: 'linear-gradient(to bottom, #333 50%, rgba(255, 255, 255, 0) 0%)',
          backgroundPosition: 'center',
          backgroundSize: '2px 5px',
          backgroundRepeat: 'repeat-y'

          /* //borderLeft: "6px dotted",
          width: 0,
          borderImageSource: "url(assets/images/dots2.svg)",
          borderImageSlice: "2",
          borderImageRepeat: "round",
          marginLeft: 18 */
        }
      case 'public_transport':
        return {
          borderWidth: 0,
          backgroundImage: 'linear-gradient(to bottom, #' +
            (section.display_informations.color !== '000000' ? section.display_informations.color : '576369') +
            ' 100%, rgba(255, 255, 255, 0) 0%)',
          backgroundPosition: 'center',
          backgroundSize: '4px 8px',
          backgroundRepeat: 'repeat-y'
        }
      default:
        return {}
    }
  }

  switchSectionType = section => {
    const infos = section.display_informations
    const mode = infos && infos.commercial_mode.toLowerCase()
    const bss = (section.from && section.from.embedded_type === 'poi' && section.from['poi'].properties.amenity === 'bicycle_rental')
    const car = section.mode === 'car'

    switch (section.type) {
      case 'public_transport':
        return REACT_APP_TYPE === 'tcl' ? <div className='tclLine'>
          <img src={'assets/images/lines/' + infos.code + '.svg'} alt='bus icon' />
        </div> : <div className='lineLabel' style={mode === 'rer' ? { width: 30 } : {}}>
          <span className={'lineCode' + (infos.code.length > 5 ? ' longLineCode' : '')} style={mode === 'rer' ? {
            backgroundColor: 'white',
            color: infos.color !== '000000' ? '#' + infos.color : '#576369',
            minWidth: 12,
            border: '2px solid #' + (infos.color !== '000000' ? infos.color : '576369'),
            borderRadius: '50%',
            fontWeight: 700,
            flex: 0,
            margin: '0 10px',
            padding: '3px 5px'
          } : {
            background: '#' + (infos.color !== '000000' ? infos.color : '576369'),
            color: luminance(infos.color) > 0.5 ? '#333' : '#fff'
          }}>{infos.code}</span>
        </div>
      case 'street_network':
        return <SectionIcon image={'modes/' + (bss ? 'bss' : car ? 'car' : section.mode === 'bike' ? 'bike' : 'walk')}
          className={'icon ' + (bss ? 'bss' : car ? 'car' : section.mode === 'bike' ? 'bike' : 'walk')}
          type={mode === 'bike' ? 'bike' : 'walk'} />
      case 'transfer':
        return <SectionIcon image='modes/walk' className='walk' type='walk' />
      case 'waiting':
        return /* <SectionIcon type="wait" /> */
      case 'stay_in':
        return 'stay_in'
      case 'crow_fly':
        return 'crow_fly'
      /* case "on_demand_transport":
       return "on_demand_transport";
       case "bss_rent":
       return "bss_rent";
       case "bss_put_back":
       return "bss_put_back";
       case "boarding":
       return "boarding";
       case "landing":
       return "landing";
       case "alighting":
       return "alighting";
       case "park":
       return "park";
       case "ridesharing":
       return "ridesharing"; */
      default:
        console.log('Section of type ' + section.type + ' is not handle yet')
        break
    }
  }

  toggleChangeCheckBoxRoadMap = () => {
    this.setState({ checkBoxRoadMap: !this.state.checkBoxRoadMap })
  }

  toggleChangeCheckBoxMiniMap = () => {
    this.setState({ checkBoxMiniMap: !this.state.checkBoxMiniMap })

    if (!this.state.staticMapURL) {
      this.generateStaticMap()
    }
  }

  updateOptionsText = () => {
    const date = this.state.selectedDate.format('DD/MM/YYYY')
    const now = moment().format('DD/MM/YYYY HH:mm')

    this.setState({
      now: date + ' ' + this.state.selectedHours + ':' + this.state.selectedMinutes === now ? 'maintenant' : (date +
        (' à ' + this.state.selectedHours + ':' + this.state.selectedMinutes))
    })
  }

  componentDidMount () {
    const { map, modalState } = this.props

    if (map.props.isMobile) {
      this.setState(state => ({
        ...state,
        ...modalState
      }))
    }

    // Add map events
    updateMapEvents(map, 'onClick', event => {
      let newUrl = this.props.url.pathname
      const latlng = REACT_APP_MAP_TYPE === 'leaflet' ? event.latlng : event.latLng

      if (!map.state.inputStartPin && !map.state.inputEndPin) {
        newUrl += '?from=' + substringCoords(latlng)
      } else if (!map.state.inputStartPin && map.state.inputEndPin) {
        newUrl += '?from=' + substringCoords(latlng) + '&to=' + this.state.url.to
      } else if (map.state.inputStartPin && !map.state.inputEndPin) {
        newUrl += '?from=' + this.state.url.from + '&to=' + substringCoords(latlng)
      }

      if (newUrl !== this.props.url.pathname && !this.state.loading) {
        this.props.history.push(newUrl)
      }
    })

    if (map) {
      this.loadData(this.state.url)
      this.removeEventListener = addResizeEvent(map.props.isMobile)
    }

    resize(map.props.isMobile)
  }

  componentDidUpdate (prevProps) {
    const { map } = this.props

    if (this.props.location.search !== prevProps.location.search) {
      const url = updateURLState(this.props.location)

      if (!this.props.location.search.includes('date=')) {
        map.setState({
          markers: [],
          polylines: []
        })
      }

      this.setState({ url }, () => {
        this.loadData(url)
      })
    }
  }

  componentWillMount () {
    this.setState({ url: updateURLState(this.props.url) })
  }

  componentWillUnmount () {
    const { map } = this.props

    if (map) {
      map.setState({
        clusters: null,
        infoboxs: [],
        inputEndData: [],
        inputEndPin: null,
        inputStartData: [],
        inputStartPin: null,
        markers: [],
        markersPlaces: [],
        polylines: [],
        selectedInfobox: null,
        status: null,
        url: null
      })

      removeMapEvents(map)
      this.removeEventListener()
    }
  }

  render () {
    const { map } = this.props
    const { isMobile } = map.props

    // TODO TCL Modes
    const modes = [
      {
        id: 'metro',
        name: 'métro'
      },
      {
        id: 'funiculaire',
        name: 'funiculaire'
      },
      {
        id: 'tramway',
        name: 'tram'
      },
      {
        id: 'bus',
        name: 'bus'
      },
      {
        id: 'bss',
        name: 'vélo\'V'
      },
      {
        id: 'bike',
        name: 'vélo'
      },
      {
        id: 'car',
        name: 'voiture'
      },
      {
        id: 'pmr',
        name: 'accessibilité'
      }
    ]

    const pickerStyle = {
      calendar: {
        bottom: map && map.props.isMobile ? '28%' : '',
        left: map && map.props.isMobile ? '0' : '',
        right: map && map.props.isMobile ? '0' : '',
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)'
      },
      colon: {
        padding: '0 5px 0 0 !important'
      },
      control: {
        boxShadow: 'none',
        cursor: 'pointer'
      },
      first: '#005e86',
      menu: {
        marginLeft: -5,
        position: 'fixed',
        bottom: map && map.props.isMobile ? '25%' : '',
        top: ''
      },
      weekDays: {
        padding: '5px 0'
      },
      monthSelected: {
        fontWeight: 600
      },
      calendarButtonStyle: {
        fontSize: '1em'
      },
      inputsHours: {
        fontSize: '1em'
      },
      today: {
        background: '#f4f4f4',
        color: '#333',
        fontWeight: '500'
      }
    }

    if (REACT_APP_TYPE === 'tcl') {
      pickerStyle.first = '#e2001a'
      pickerStyle.calendarButtonStyle = {
        ...pickerStyle.calendarButtonStyle,
        padding: '5px',
        marginRight: 10
      }
      pickerStyle.calendar = {
        ...pickerStyle.calendar,
        padding: 15,
        background: '#f1f5f5'
      }
      pickerStyle.week = {
        ...pickerStyle.week,
        background: '#fff'
      }
    }

    const dates = <Fragment>
      <div className='represents'>
        <div className='radio'>
          <label>
            <input type='radio' value='departure' checked={this.state.modes.represents === 'departure'}
              onChange={this.handleRepresentsChanged} />
            Départ
          </label>
        </div>
        <div className='radio'>
          <label>
            <input type='radio' value='arrival' checked={this.state.modes.represents === 'arrival'}
              onChange={this.handleRepresentsChanged} />
            Arrivée
          </label>
        </div>
      </div>
      <div className='pickers'>
        <DateCalendar
          lang={'fr'}
          systemUS={false}
          style={pickerStyle}
          todayTxt="Aujourd'hui"
          image={'assets/images/calendar.svg'}
          getSelectedDate={this.getSelectedDate}
        />
        <Time
          systemUS={false}
          style={pickerStyle}
          isMobile={map && map.props.isMobile}
          image={REACT_APP_TYPE === 'tcl' ? 'assets/images/menu/lines.svg' : 'assets/images/time.svg'}
          getSelectedHours={this.getSelectedHours}
          getSelectedMinutes={this.getSelectedMinutes}
        />
      </div>
    </Fragment>

    const prefs = <Fragment>
      <div className='modes'>
        {REACT_APP_TYPE === 'tcl'
          ? modes.map(mode =>
            <div key={mode.id} className={'mode' + (this.state.modes[mode.id] ? ' active' : '')}
              onClick={() => this.handleModeChanged(mode)}>
              <img src={'assets/images/modes/' + mode.id + '.svg'} alt='mode' />{mode.name}
            </div>
          ) : <label>
            <input type='checkbox'
              checked={this.state.modes.pmr}
              onChange={this.handlePMRChanged} />
            Me proposer uniquement des trajets adaptés aux voyageurs en fauteuil roulant
          </label>}
      </div>
    </Fragment>

    return !this.state.journeys ? <div className='form scroll'>
      <div className='inputs'>
        <div className='inputsText'>
          {this.renderInput('start')}
          <div className='divider' />
          {this.renderInput('end')}
        </div>
        <div className='swap' onClick={this.invertEntries} />
      </div>
      <div className='prefs'>
        <div className='buttons'>
          <div className='collapse date' onClick={() => this.setState({ dates: !this.state.dates, prefs: false })}>
            {this.state.modes.represents === 'departure' ? 'Départ' : 'Arrivée'} <span>{this.state.now}</span>
            <div className={'itemArrow' + (this.state.dates ? ' active' : '')} />
          </div>
          {isMobile && <Collapse isOpened={this.state.dates}>
            {dates}
          </Collapse>}
          <div className='collapse' onClick={() => this.setState({ prefs: !this.state.prefs, dates: false })}>
            {REACT_APP_TYPE === 'tcl' ? 'Modes de transport' : 'Préférences'}
            <div className={'itemArrow' + (this.state.prefs ? ' active' : '')} />
          </div>
          {isMobile && <Collapse isOpened={this.state.prefs}>
            {prefs}
          </Collapse>}
        </div>

        {!isMobile && <Fragment>
          <Collapse isOpened={this.state.dates}>
            {dates}
          </Collapse>
          <Collapse isOpened={this.state.prefs}>
            {prefs}
          </Collapse>
        </Fragment>}
      </div>

      {this.state.warning.length > 0 && (<div className='warning'>
        <img src='assets/images/warning.svg' alt='Warning' />
        {this.state.warning}
      </div>)}
      {this.state.error.length > 0 && (
        <div className='error'>
          <img src='assets/images/error.svg' alt='Warning' />
          {this.state.error}
        </div>
      )}
      {this.state.loading ? <div className='loading'>
        <img src='assets/images/loading.gif' width={25} alt='Loading' />
          Calcul en cours ...
      </div> : <div className='button' onClick={this.calcItineraries}>
          Go !
      </div>}
    </div> : this.state.journey ? <Fragment>
      {this.state.print && <div className='printLink'>
        <div>
          <input type='checkbox' id='RoadMap' name='Feuille de route' checked={this.state.checkBoxRoadMap}
            onChange={() => this.toggleChangeCheckBoxRoadMap()} />
          <label htmlFor='RoadMap'>Feuille de route</label>
        </div>
        <div>
          <input type='checkbox' id='Minimap' name='Minimap' checked={this.state.checkBoxMiniMap}
            onChange={() => this.toggleChangeCheckBoxMiniMap()} />
          <label htmlFor='Minimap'>Carte</label>
        </div>
        <ReactToPrint
          pageStyle={
            '@page { size: auto;  margin: 10mm 15mm; } @media print { body { -webkit-print-color-adjust: exact; } }'
          }
          onBeforePrint={() => this.setState({
            printing: true
          })}
          closeAfterPrint
          trigger={() => (this.state.checkBoxRoadMap || this.state.checkBoxMiniMap)
            ? <div className='button'>Imprimer</div> : <div className='hide' />}
          content={() => this.componentRef}
        /></div>}
      <Journey ref={el => (this.componentRef = el)} component={this} />
    </Fragment> : <Fragment>
      {REACT_APP_TYPE !== 'tcl' && <div className='menuTitle routeHead'>ITINÉRAIRES PROPOSÉS</div>}
      <div className='journeys scroll'>
        {Object.keys(this.state.softs).length > 0 && <div className='soft'>
          <div className='softItems'>
            {Object.keys(this.state.softs).map(mode => (
              <div className='softItem' key={mode}
                onClick={() => this.props.history.push(
                  this.props.url.pathname + this.props.url.search + '&journey=' + mode)}>
                <SectionIcon image={'modes/' + mode} className={mode} type={mode} options={{ height: 18 }} />
                <div className='softInfos'>
                  <div className='time'>
                    {this.duration(this.state.softs[mode][0].duration).minutes + ' min'}
                  </div>
                  {REACT_APP_TYPE === 'tcl' &&
                    <div>{mode === 'walk' ? 'marche' : mode === 'bike' ? 'vélo' : 'Vélo\'V'}</div>}
                </div>
              </div>
            ))}
          </div>
        </div>}
        {this.state.journeys.length > 0 ? <Fragment>
          {this.state.tc && this.state.tc.length > 0 && <div className='transport'>
            <div className='mode'>En transport en commun</div>
            { this.renderJourneys(this.state.tc) }
          </div>}
          {this.state.combined && this.state.combined.length > 0 && <div className='transport'>
            <div className='mode'>En combiné</div>
            {this.renderJourneys(this.state.combined)}
          </div>}
        </Fragment> : <div style={{ paddingBottom: 15 }}>Aucune solution trouvée en transport en commun</div>
        }
      </div>
    </Fragment>
  }
}

export default withRouter(RouteCalculation)
