import axios from 'axios'
import React, { Component, Fragment } from 'react'
import Autocomplete from 'react-autocomplete'
import { withRouter } from 'react-router-dom'

const { REACT_APP_MAP_TYPE } = process.env

const {
  displayTimeTable,
  fitBounds,
  getLine,
  getStop,
  invertCoords,
  onChangeAutocompleteInput,
  onZoomChanged,
  removeLine,
  removeMapEvents,
  renderLine,
  renderMarkers,
  renderPlaces,
  renderPolygon,
  renderTown,
  schedules,
  selectLine,
  updateMapEvents
} = require(`../../utils/${REACT_APP_MAP_TYPE}/map`)
const {
  addResizeEvent,
  getLinesInTown,
  resize,
  sortAlphabetic,
  updateURLState
} = require(`../../utils/${REACT_APP_MAP_TYPE}/tools`)

class Towns extends Component {
  state = {
    currentLine: null,
    towns: [],
    groups: null,
    town: null,
    outline: null,
    tooltipObject: null,
    tooltipCoords: null,
    tooltipOffset: {
      x: 20,
      y: -20
    },
    inputTownsValue: [],
    inputTownsData: [],
    url: null
  }

  createTooltip = (event, data) => {
    const { map, url, theme } = this.props

    if (event && !this.state.tooltipObject) {
      const newOutline = this.state.outline.filter(town => town.key !== 'polygon-' + data.insee)
      const polygon = renderPolygon(invertCoords(data), {
        strokeWeight: 1,
        strokeColor: '#' + theme.colors['primary'],
        fillOpacity: 0.5,
        fillColor: '#' + theme.colors['primary'],
        strokeOpacity: 1,
        clickable: true
      }, 'polygon-' + data.insee + '-hover', {
        onClick: () => this.props.history.push(url.pathname + '?insee=' + data.insee),
        onMouseMove: e => this.moveTooltip(e),
        onMouseOut: () => this.deleteTooltip()
      })

      newOutline.push(polygon)
      map.setState({ polygons: newOutline })

      const tooltipObject = document.createElement('div')

      tooltipObject.className = 'infobox tooltip'
      tooltipObject.innerHTML = '<div><img src="assets/images/menu/towns.svg" alt="Les communes">' + data.name +
        '</div>'

      if (!this.state.tooltipCoords) {
        for (const i in Object.keys(event)) {
          const name = Object.keys(event)[i]

          if (event[name] instanceof window.MouseEvent) {
            this.setState({ tooltipCoords: name })
            break
          }
        }
      }

      if (this.state.tooltipCoords) {
        tooltipObject.style.position = 'fixed'
        tooltipObject.style.top = event[this.state.tooltipCoords].clientY + window.scrollY +
          this.state.tooltipOffset.y + 'px'
        tooltipObject.style.left = event[this.state.tooltipCoords].clientX + window.scrollX +
          this.state.tooltipOffset.x + 'px'
        document.body.appendChild(tooltipObject)
      }

      this.setState({ tooltipObject })
    }
  }

  deleteTooltip = () => {
    const { map } = this.props

    if (this.state.tooltipObject) {
      document.body.removeChild(this.state.tooltipObject)

      map.setState({ polygons: this.state.outline })
      this.setState({
        tooltipObject: null,
        tooltipCoords: null
      })
    }
  }

  loadData = async () => {
    const currentURL = this.state.url
    const { map, theme } = this.props

    if (currentURL.insee) {
      const town = this.state.towns.find(town => town.insee === currentURL.insee)

      if (town) {
        const polygons = [
          renderPolygon(invertCoords(town), {
            strokeWeight: 1.5,
            strokeColor: '#' + theme.colors['primary'],
            fillOpacity: 0
          }, 'polygon')
        ]

        // Delete the tooltip from the map
        this.deleteTooltip()

        map.setState({
          polygons,
          infoboxs: []
        }, async () => {
          await getLinesInTown(this, town)
          fitBounds(map, polygons)
        })
      }
    } else {
      map.setState({
        polygons: this.state.outline,
        infoboxs: []
      }, () => {
        fitBounds(map, this.state.outline)
      })
    }

    if (currentURL.line) {
      axios.get('/api/file?name=lines').then(response => {
        const currentLine = currentURL.line
        const selectedLine = response.data.find(
          line => currentLine.substring(0, currentLine.lastIndexOf('_')) === line.id)

        if (currentLine.slice(-1) !== '0') {
          selectedLine.direction_id = 1
        }

        this.onLineSelected(selectedLine, null, true)
      })
    } else {
      removeLine(this)
    }
  }

  moveTooltip = event => {
    if (event && this.state.tooltipObject && this.state.tooltipCoords) {
      const tooltipObject = this.state.tooltipObject
      tooltipObject.style.top = event[this.state.tooltipCoords].clientY + window.scrollY + this.state.tooltipOffset.y +
        'px'
      tooltipObject.style.left = event[this.state.tooltipCoords].clientX + window.scrollX + this.state.tooltipOffset.x +
        'px'

      this.setState({ tooltipObject })
    }
  }

  onLineSelected = async (line, marker, onLoad = false) => {
    const { url } = this.props

    this.setState({ timetable: false })

    if (!onLoad) {
      const dir = line.direction_id ? line.direction_id : line.routes[0].direction_id
      this.props.history.push(url.pathname + '?insee=' + this.state.town.insee + '&line=' + line.id + '_' + dir)
    }

    const data = await selectLine(this, getLine(this, line))

    // Avoid redraw if there is no data (error in selectLine() ?)
    if (data) {
      this.setState({ ...data }, () => {
        if (marker) {
          schedules(this, marker, line)
        }

        const currentURL = updateURLState(url)

        if (currentURL.stop) {
          const line = this.state.currentLine
          const stop = getStop(this, { id: currentURL.stop }, line)

          if (currentURL.date) {
            displayTimeTable(this, stop, line, currentURL.date)
          } else {
            schedules(this, stop, line)
          }
        }
      })
    }
  }

  onTabSelected = tab => {
    const { map } = this.props

    this.setState({ tab }, () => {
      map.setState({ markers: [] }, () => {
        setTimeout(() => {
          if (tab === 0) {
            renderMarkers(this)
          } else {
            renderPlaces(this)
          }
        })
      })
    })
  }

  onTownInteraction = (town, over) => {
    const { map, theme } = this.props

    if (over) {
      const newOutline = this.state.outline.filter(town => town.key !== 'polygon-' + town.insee)
      const polygon = renderPolygon(invertCoords(town), {
        strokeWeight: 1,
        strokeColor: '#' + theme.colors['primary'],
        fillOpacity: 0.5,
        fillColor: '#' + theme.colors['primary'],
        strokeOpacity: 1,
        clickable: true
      }, 'polygon-' + town.insee + '-hover', {
        onMouseMove: e => this.moveTooltip(e),
        onMouseOut: () => this.deleteTooltip()
      })

      newOutline.push(polygon)
      map.setState({ polygons: newOutline })
    } else {
      const polygons = map.state.polygons.filter(polygon => polygon.key !== 'polygon-' + town.insee + '-hover')

      map.setState({
        polygons
      })
    }
  }

  onTownSelected = town => {
    const { url } = this.props

    this.props.history.push(url.pathname + '?insee=' + town.insee)
  }

  componentDidMount () {
    const { map, history, url, theme } = this.props

    updateMapEvents(map, 'onClick', () => {
      map.setState({
        selectedInfobox: null,
        infoboxs: []
      })
    })

    updateMapEvents(map, 'onZoomChanged', () => {
      const path = history.location.pathname

      onZoomChanged(this, path)
    })

    if (map) {
      axios.get('/api/file?name=towns').then(response => {
        sortAlphabetic(response.data, 'name')

        const outline = []
        for (let town of response.data) {
          const polygon = renderPolygon(invertCoords(town), {
            strokeWeight: 1,
            strokeColor: '#' + theme.colors['primary'],
            strokeOpacity: 1,
            fillOpacity: 0,
            clickable: true
          }, 'polygon-' + town.insee, {
            onClick: () => history.push(url.pathname + '?insee=' + town.insee),
            onMouseOver: e => this.createTooltip(e, town),
            onMouseMove: e => this.moveTooltip(e),
            onMouseOut: () => this.deleteTooltip()
          })

          outline.push(polygon)
        }

        this.setState({
          towns: response.data,
          outline
        }, () => {
          this.loadData()
          resize(map.props.isMobile)
        })
      })

      this.removeEventListener = addResizeEvent(map.props.isMobile)
    }
  }

  /**
   * Detect url changes
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { map } = this.props

    if (prevProps.url.search !== this.props.url.search) {
      const url = updateURLState(this.props.location)
      const newState = { url }

      if (prevProps.url.search.includes('line=') && !url.line) {
        newState.currentLine = null
      } else if (prevProps.url.search.includes('insee=') && !url.insee) {
        newState.town = null

        map.setState({
          markers: [],
          markersPlaces: [],
          clusters: null,
          status: null
        })
      }

      this.setState(newState, () => {
        this.loadData()
        resize(map.props.isMobile)
      })
    }
  }

  /**
   * Define url
   */
  componentWillMount () {
    this.setState({ url: updateURLState(this.props.url) })
  }

  componentWillUnmount () {
    const { map } = this.props

    this.deleteTooltip()

    if (map) {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        polygons: [],
        circle: null,
        selectedInfobox: null,
        terminus: false,
        infoboxsTerminus: []
      })

      removeMapEvents(map)
      this.removeEventListener()
    }
  }

  render () {
    const { towns, town } = this.state

    return this.state.currentLine ? renderLine(this) : town ? renderTown(this) : <Fragment>
      <div className='form formNoBottom'>
        <div className='inputs'>
          <Autocomplete
            inputProps={{ id: 'input-places' }}
            wrapperStyle={{
              display: 'flex',
              flex: 1
            }}
            value={this.state.inputTownsValue}
            items={this.state.inputTownsData}
            getItemValue={item => item.name}
            onSelect={(valueInputSelect, dataInputSelect) => this.onTownSelected(dataInputSelect)}
            onChange={event => onChangeAutocompleteInput(event, this, 'inputTowns')}
            renderInput={props => <input className='input' {...props} placeholder={'Nom de commune...'} />}
            renderMenu={children => <div className='autocomplete'>{children}</div>}
            renderItem={(item, isHighlighted) => <div className={'item' + (isHighlighted ? ' itemHighlight' : '')}
              key={item.insee}>
              <img width={30} src={'assets/images/menu/towns.svg'} alt='commune' />
              {item.name}
            </div>}
          />
        </div>
      </div>
      <div className='scroll'>
        {towns.length > 0 && towns.map(town =>
          <div key={town.insee} className='group' onMouseEnter={() => this.onTownInteraction(town, true)}
            onMouseLeave={() => this.onTownInteraction(town)} onClick={() => this.onTownSelected(town)}>
            <div className='groupName'>
              <div className='groupHead'>
                <img src={'assets/images/menu/towns.svg'} style={{
                  width: 25,
                  height: 'auto',
                  padding: '0 10px 0 5px'
                }} alt='groupe' />
                <span>{town.name}</span>
              </div>
              <div className='arrowGroup'>
                <img className='closed' src='assets/images/v.svg' alt='arrow' />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  }
}

export default withRouter(Towns)
