import React, { Component } from 'react'
import {
  Map,
  TileLayer,
  ZoomControl,
  ScaleControl
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

const { REACT_APP_START_LAT, REACT_APP_START_LNG } = process.env

export class BaseMap extends Component {
  mapReference = React.createRef()

  state = {
    bounds: null,
    boundsOptions: null,
    center: {
      lat: +REACT_APP_START_LAT,
      lng: +REACT_APP_START_LNG
    },
    circle: null,
    clusters: null,
    events: {},
    infoboxs: [],
    infoboxsTerminus: [],
    markers: [],
    markersMap: [],
    markersPlaces: [],
    markersRER: [],
    pin: null,
    polygons: [],
    polylines: [],
    polylineDecorators: [],
    terminus: false,
    zoom: 13
  }

  componentWillMount () {
    this.props.onRef(this)
  }

  componentWillUnmount () {
    this.props.onRef(undefined)
  }

  // Uncomment this to bring font up to overlay
  /* componentDidMount () {
    setTimeout(() => {
      const tilePane = document.querySelector('.leaflet-tile-pane')
      const markerPane = document.querySelector('.leaflet-marker-pane')
      const fontLayer = tilePane.children[1]

      fontLayer.remove()
      markerPane.parentNode.insertBefore(fontLayer, markerPane)
      fontLayer.style.zIndex = 450
    })
  } */

  render () {
    const { events, bounds, boundsOptions, zoom, center, polygons, polylines, polylineDecorators, circle, pin, markers, markersMap, markersPlaces, markersRER } = this.state

    return <Map ref={this.mapReference} className='mapContainer' bounds={bounds}
      boundsOptions={boundsOptions} zoomControl={false}
      center={center} minZoom={9} maxZoom={18} zoom={zoom} {...events}>
      <TileLayer
        attribution='&amp;copy <a href="https://latitude-cartagene.com">Latitude-Cartagène</a>'
        url='https://basemap.dev.latitude-cartagene.com/tcl-base/{z}/{x}/{y}.png'
      />
      <TileLayer
        url='https://basemap.dev.latitude-cartagene.com/tcl-typo/{z}/{x}/{y}.png'
      />
      { !this.props.isMobile && <ScaleControl position={'bottomright'} imperial={false} />}
      { !this.props.isMobile && <ZoomControl position={'bottomright'} />}

      {polygons} {polylines} {polylineDecorators}
      {circle} {pin}
      {markersMap}
      {markers} {markersPlaces} {markersRER}
    </Map>
  }
}
