import { MapLayer, withLeaflet } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet-polylinedecorator'
import utils from 'leaflet-geometryutil'

// TODO Must be a better way to handle those fuckin plugins ...
class PolylineDecorator extends MapLayer {
  componentWillUnmount () {
    const { map } = this.props.leaflet

    map.removeLayer(this.leafletElement)
  }

  createLeafletElement ({ path, section, leaflet }) {
    const code = section.display_informations ? section.display_informations.code : section.mode
    const length = utils.length(path.map(p => new L.LatLng(p[0], p[1])))
    const bss = section.from && section.from.embedded_type === 'poi' && section.from['poi'].properties.amenity === 'bicycle_rental'
    const mode = section.display_informations ? 'bus' : section.mode === 'car' ? 'car' : section.mode === 'bike' ? bss ? 'bss' : 'bike' : 'walk'

    return L.polylineDecorator(path, {
      patterns: [
        {
          offset: '50%',
          repeat: 100 - (length / 400) + '%',
          symbol: L.Symbol.marker({
            rotate: false,
            markerOptions: {
              icon: L.icon({
                className: 'decorator',
                iconUrl: mode === 'bus' ? 'assets/images/lines/' + code + '.svg' : 'assets/images/modes/' + mode + '.svg',
                iconSize: mode === 'bus' ? [40, 15] : section.mode === 'bike' || mode === 'car' ? [35, 15] : [20, 15],
                iconAnchor: mode === 'bus' ? [20, 8] : (section.mode === 'bike' || mode === 'car') ? [17, 8] : [10, 8]
              })
            }
          })
        }
      ]
    })
  }

  updateLeafletElement (fromProps, { path, code, leaflet }) {
    this.leafletElement.setPaths(path)
  }
}

export default withLeaflet(PolylineDecorator)
