import axios from 'axios'
import React, { Component, Fragment } from 'react'
import { Collapse } from 'react-collapse'
import { withRouter } from 'react-router-dom'

const { REACT_APP_MAP_TYPE } = process.env

const {
  fitBounds,
  getCoordinates,
  onZoomChanged,
  removeMapEvents,
  renderMarker,
  renderPolyline,
  updateMapEvents
} = require(`../../utils/${REACT_APP_MAP_TYPE}/map`)
const { addResizeEvent, resize, updateURLState } = require(`../../utils/${REACT_APP_MAP_TYPE}/tools`)

class HikingRoutes extends Component {
  state = {
    places: [],
    route: null,
    routes: [],
    url: null
  }

  loadData = async () => {
    const { map, theme } = this.props
    const { url } = this.state

    const headersRoutes = [
      'id',
      'name',
      'walk',
      'bike',
      'direction',
      'x',
      'y',
      'length',
      'difficulty',
      'places'
    ]
    const response = await axios.get('/api/csvtojson?file=hiking-routes&headers=' + headersRoutes)
    const routes = response.data

    if (url.route) {
      const route = routes.find(route => route.id === url.route)
      const responseGeojson = await axios.get('/api/file?folder=hiking&ext=geojson&name=' + route.id)
      const headersPlaces = [
        'route',
        'id',
        'x',
        'y',
        'description'
      ]
      const responsePlaces = await axios.get('/api/csvtojson?file=hiking-routes-places&headers=' + headersPlaces)
      const polylines = []
      const markers = []
      const places = responsePlaces.data.filter(item => item.route === route.id)

      for (const place of places) {
        // Close collapse place by default
        place.opened = false

        const marker = {
          id: 'hiking-places-' + place.id,
          coord: {
            lat: place.y,
            lon: place.x
          }
        }

        markers.push(
          renderMarker(this, marker, {
            icon: {
              url: 'assets/images/hiking/places/' + place.id + '.svg',
              size: {
                width: 30,
                height: 30
              },
              scaledSize: {
                width: 30,
                height: 30
              },
              anchor: {
                x: 15,
                y: 15
              }
            },
            zIndex: 30
          }, place))
      }

      const paths = []
      for (const feature of responseGeojson.data.features) {
        paths.push(...getCoordinates(feature))
      }

      for (const path of paths) {
        const index = paths.indexOf(path)

        const cloneOptions = {
          strokeColor: '#fff',
          strokeOpacity: 1,
          strokeWeight: 10,
          zIndex: 6
        }

        const lineOptions = {
          strokeColor: '#' + theme.colors['primary'],
          strokeOpacity: 1,
          strokeWeight: 6,
          zIndex: 7
        }

        polylines.push(renderPolyline(path, cloneOptions, 'clone_' + index))
        polylines.push(renderPolyline(path, lineOptions, 'poly_' + index))
      }

      this.setState({
        route,
        places
      }, () => {
        map.setState({
          markers,
          polylines,
          infoboxs: []
        }, () => {
          fitBounds(map, polylines)
          resize(map.props.isMobile)
        })
      })
    } else {
      this.setState({
        route: null,
        routes,
        places: []
      }, () => {
        const markers = []

        for (const route of routes) {
          const marker = {
            id: 'hiking-' + route.id,
            coord: {
              lat: route.y,
              lon: route.x
            },
            name: route.name,
            offset: {
              x: 10,
              y: -30
            }
          }

          markers.push(
            renderMarker(this, marker, {
              icon: {
                url: 'assets/images/hiking/' + route.id + '.svg',
                size: {
                  width: 40,
                  height: 40
                },
                scaledSize: {
                  width: 40,
                  height: 40
                },
                anchor: {
                  x: 20,
                  y: 40
                }
              },
              zIndex: 30
            }, route))
        }

        map.setState({
          markers,
          polylines: [],
          infoboxs: []
        }, () => {
          fitBounds(map, markers)
          resize(map.props.isMobile)
        })
      })
    }
  }

  onRouteInteraction = (route, over) => {
    const { map } = this.props

    const marker = map.state.markers.find(marker => marker.key === 'hiking-' + route.id)

    if (over) {
      marker.props.onMouseOver()
    } else {
      marker.props.onMouseOut()
    }
  }

  onRouteSelected = route => {
    const { url, history } = this.props

    history.push(url.pathname + '?route=' + route.id)
  }

  onRoutePlaceSelected = place => {
    for (const statePlace of this.state.places) {
      if (place.id === statePlace.id) {
        statePlace.opened = !statePlace.opened
      } else {
        statePlace.opened = false
      }
    }

    // Change the "see more" text
    const mores = document.querySelectorAll('.hikingDetailsMore')
    const more = mores[place.id - 1]
    // more.querySelector("span").innerHTML = place.opened ? "Voir moins" : "Voir plus";
    more.querySelector('img').style.transform = place.opened
      ? 'rotate(180deg)'
      : 'rotate(0deg)'

    this.setState({ places: this.state.places })
  }

  componentDidMount () {
    const { map } = this.props

    updateMapEvents(map, 'onZoomChanged', () => {
      onZoomChanged(this)
    })

    updateMapEvents(map, 'onClick', event => {
      map.setState({
        selectedInfobox: null,
        infoboxs: []
      })
    })

    if (map) {
      this.loadData(this.state.url)
      this.removeEventListener = addResizeEvent(map.props.isMobile)
    }
  }

  componentWillUnmount () {
    const { map } = this.props

    if (map) {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        infoboxs: [],
        clusters: null,
        status: null
      })

      removeMapEvents(map)
      this.removeEventListener()
    }
  }

  componentWillMount () {
    this.setState({ url: updateURLState(this.props.url) })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.url.search !== this.props.url.search) {
      const url = updateURLState(this.props.location)

      this.setState({ url }, () => {
        this.loadData()
      })
    }
  }

  render () {
    const { routes, route, places } = this.state

    return route ? <Fragment>
      <div className='group selected'>
        <div className='groupName'>
          <div className='groupHead'>
            <img src={'assets/images/hiking/' + route.id +
            '.svg'} style={{ height: 'auto' }} alt='groupe' />
            <div className='hikingPlace'>
              <span>{route.name}</span>
              <div>
                {route.walk === '1' &&
                <img src={'assets/images/hiking/walk.svg'} alt='walk' />}
                {route.bike === '1' &&
                <img src={'assets/images/hiking/bike.svg'} alt='bike' />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='scroll padding'>
        <div className='hikingDetails'>
          <img src={'assets/images/hiking/info.svg'} alt='groupe' />
          <div>
            <span>Départ / Arrivée :</span> {route.direction}
            <br />
            <span>Distance :</span> {route.length}
            <br />
            <span>Difficulté :</span> {route.difficulty}
            <br />
            <span>Type : </span>
            {route.walk === '1' ? (route.bike === '1'
              ? 'A pied, à vélo'
              : 'A pied') : 'A vélo'}
            <br />
          </div>
        </div>
        {places.map(place => (
          <div key={place.id}>
            <div className='hikingDetails'>
              <img src={'assets/images/hiking/places/' + place.id + '.svg'} alt='groupe' />
              <Collapse
                className='hikingPlaceCollapse'
                onClick={() => this.onRoutePlaceSelected(place)}
                isOpened={place.opened}>
                {place.description}
              </Collapse>
            </div>
            <div onClick={() => this.onRoutePlaceSelected(place)} className='hikingDetailsMore'>
              <div className='arrowDetails'>
                <img className='closed' src='assets/images/v.svg' alt='arrow' />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Fragment> : <div className='scroll'>
      {routes.map(route => (
        <div
          key={route.id}
          className='group'
          onMouseEnter={() => this.onRouteInteraction(route, true)}
          onMouseLeave={() => this.onRouteInteraction(route)}
          onClick={() => this.onRouteSelected(route)}>
          <div className='groupName'>
            <div className='groupHead'>
              <img src={'assets/images/hiking/' + route.id + '.svg'} style={{ height: 'auto' }} alt='groupe' />
              <div className='hikingPlace'>
                <span>{route.name}</span>
                <div>
                  {route.walk === '1' && <img src={'assets/images/hiking/walk.svg'} alt='walk' />}
                  {route.bike === '1' && <img src={'assets/images/hiking/bike.svg'} alt='bike' />}
                </div>
              </div>
            </div>
            <div className='arrowGroup'>
              <img className='closed' src='assets/images/v.svg' alt='arrow' />
            </div>
          </div>
        </div>
      ))}
    </div>
  }
}

export default withRouter(HikingRoutes)
